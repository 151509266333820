import React from 'react'
import GreyBoxForReports from './GreyBoxForReports'
import { MMDDYYYY } from '../../JS/Common';
import AlertsComp from '../../Components/AlertsComp';
import { ApiGetCall } from '../../JS/Connector';

export default function AllHelpdeskTickets({ setLoading, FilterData, setalerts, downloadAllChecked }) {
    async function GetAllHelpdeskData() {
        var downloadall = 0;
        if (downloadAllChecked) {
            downloadall = 1;
        }
        setLoading(true);
        await ApiGetCall("/AllHelpdeskTicket/" + MMDDYYYY(FilterData.StartDate) + '&' + MMDDYYYY(FilterData.EndDate) + '&' + FilterData.Location + '&' + FilterData.School + '&' + downloadall).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.length > 0) {
                    DownloadCsv(responseRs);
                } else {
                    DownloadCsv([]);
                    setalerts(<AlertsComp show={true} variant="danger" msg={"No Data Found"} />);
                    setTimeout(() => {
                        setalerts(<AlertsComp show={false} />);
                    }, 2000);
                }
                setLoading(false);
            }
        });
    }
    const DownloadCsv = (CsvData) => {
        const csvContent = [];
        const PurchaseMap = [];
        CsvData?.forEach((item) => {
            const rowData = {
                'School Name': item.schoolName || '-',
                'Technology Tickets': item.totalTechTicket || '-',
                'Maintenance Tickets': item.totalMainTicket || '-',
                'Other Tickets': item.totalOtherTicket || '-',
                'Total Tickets': item.total || '-',
            };
            PurchaseMap.push(rowData);
        });
        if (PurchaseMap.length > 0) {
            const headers = ['School Name', 'Technology Tickets', 'Maintenance Tickets', 'Other Tickets', 'Total Tickets'];
            csvContent.push(headers);
            PurchaseMap.forEach((rowData) => {
                const values = Object.values(rowData).map(value => {
                    if (typeof value == 'string' && value.includes(',')) {
                        return `"${value}"`;
                    }
                    return value;
                });
                csvContent.push(values);
            });
            csvContent.push([]);
            const csvString = csvContent.map((row) => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'HelpdeskBySchool.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.log('No data available for download.');
        }
    };
    return (
        <GreyBoxForReports icon="faRobot" name={"All Schools Help Desk Report Download"} onclickfunc={GetAllHelpdeskData} />
    )
}
