import React, { useState, useEffect, useRef, useContext } from "react";
import { ApiGetCall } from "../../JS/Connector";
import { LogInUserID, formatDateToMMDDYYYY, customDates, MMDDYYYY } from "../../JS/Common";
import ReactDatePicker from "react-datepicker";
import TechnicianTable from "./AdminDashboardTables/TechnicianTable";
import SchoolTable from "./AdminDashboardTables/SchoolTable";
import IncomingDeviceTable from "./AdminDashboardTables/IncomingDeviceTable";
import OutgoingDeviceTable from "./AdminDashboardTables/OutgoingDeviceTable";
import MonthlyTicketComparison from "./AdminDashboardTables/MonthlyTicketComparison";
import { UserContext } from "../../App";
import TicketsPerRepair from "./AdminDashboardTables/TicketsPerRepair";
import { TicketByIssueGraph } from "./AdminDashboardTables/TicketByIssueGraph";
import TechniciansPerformance from "./AdminDashboardTables/TechniciansPerformance";
import DashboardBox from "./AdminDashboardTables/DashboardBox";
export function Admindashboard() {
    const FilterRef = useRef(null);
    const DateRef = useRef(null);
    const { UserData } = useContext(UserContext);
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);
    const [startDate, setStartDate] = useState(oneMonthAgo);
    const [endDate, setEndDate] = useState(today);
    const [Locations, setLocations] = useState([]);
    const [SchoolFilterData, setSchoolFilterData] = useState([]);
    const [AllSchool, setAllSchool] = useState([]);
    const [AllData, setAllData] = useState({
        KPIData: { data: [], loader: true },
        MonthlyTicketComparison: { data: [], loader: true },
        TicketsPerDevice: { data: [], loader: true },
        TicketByIssue: { data: [], loader: true },
        TechnicianPerformance: { data: [], loader: true },
        incomming: { data: [], loader: true },
        outgoing: { data: [], loader: true },
        technician: { data: [], loader: true },
        schoolinvoice: { data: [], loader: true },
    });
    const [LocationValue, setLocationValue] = useState(0);
    const [SchoolValue, setSchoolValue] = useState(0);
    const [ViewData, setViewData] = useState({
        actualData: { GraphData: [], TableData: [] },
        KPIView: {
            data: [],
            tab: "active"
        },
        TableView: {
            data: [],
            tab: ""
        },
        filterView: {
            DateName: "",
            DateView: false,
            sortView: false
        },
    });
    const [qflag, setQflag] = useState("1")

    useEffect(() => {
        var pageContentElement = document.querySelector('.page-content');
        pageContentElement.style.padding = '0rem';
        ApiGetCall("/GetAllSchoolsByLocation").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs?.allLocation?.length != 0) {
                    setLocations(responseRs?.allLocation)
                    setSchoolFilterData(responseRs?.allSchool)
                    setAllSchool(responseRs?.allSchool)
                }
            }
        });
    }, []);
    const handleLocationValue = (val) => {
        setLocationValue(val);
        if (val == "0") {
            setSchoolFilterData(AllSchool)
        } else {
            var filterSchoolByLocation = Locations?.filter((data) => data?.id == parseInt(val))
            setSchoolFilterData(filterSchoolByLocation[0]?.school)
        }
    }

    function resetallfilter() {
        setStartDate(oneMonthAgo);
        setEndDate(today);
        setLocationValue(0);
        setSchoolValue(0);
        setQflag("1");
        setSchoolFilterData(AllSchool)
    }

    useEffect(() => {
        function handleClickOutside(ref, viewProperty) {
            return function (event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setViewData((prevState) => ({
                        ...prevState,
                        filterView: {
                            ...prevState?.filterView,
                            [viewProperty]: false
                        }
                    }));
                }
            }
        }

        const handleFilterClick = handleClickOutside(FilterRef, 'sortView');
        const handleDateClick = handleClickOutside(DateRef, 'DateView');

        document.addEventListener('mousedown', handleFilterClick);
        document.addEventListener('mousedown', handleDateClick);

        return () => {
            document.removeEventListener('mousedown', handleFilterClick);
            document.removeEventListener('mousedown', handleDateClick);
        };
    }, [FilterRef, DateRef, setViewData]);
    const [tabSwitch, setTabSwitch] = useState({ loadKPIData: 0, loadTableData: 0 })
    useEffect(() => {
        DateFilterHandel(qflag, startDate, endDate, LocationValue, SchoolValue)
    }, [ViewData?.KPIView?.tab])

    const DateFilterHandel = async (qflag, sdate, edate, locationid, schoolvalue, applyFilter = false) => {
        var loadKPIDataValue = tabSwitch?.loadKPIData
        var loadTableDataValue = tabSwitch?.loadTableData
        if (applyFilter) {
            loadKPIDataValue = 0
            loadTableDataValue = 0
        }
        if (locationid == 0) {
            locationid = null;
        }
        if (schoolvalue == 0) {
            schoolvalue = null;
        }
        if (loadKPIDataValue == 0 && ViewData?.KPIView?.tab == 'active') {
            var customStartDates = sdate;
            var customEndDates = edate;
            if (qflag != "1") {
                const { startDate, endDate } = customDates(parseInt(qflag));
                customStartDates = startDate;
                customEndDates = endDate;
            }
            setAllData((prevState) => ({
                ...prevState,
                KPIData: { ...prevState?.KPIData, loader: true },
                MonthlyTicketComparison: { ...prevState?.MonthlyTicketComparison, loader: true },
                TicketsPerDevice: { ...prevState?.TicketsPerDevice, loader: true },
                TicketByIssue: { ...prevState?.TicketByIssue, loader: true },
                TechnicianPerformance: { ...prevState?.TechnicianPerformance, loader: true },
            }));
            try {
                await fetchAdminData(`/getbasicDetailsforAdmindashboard/${formatDateToMMDDYYYY(customStartDates)}&${formatDateToMMDDYYYY(customEndDates)}&${LogInUserID}&${schoolvalue}&${locationid}`, 'KPIData')
                await fetchAdminData(`/MonthlyTicketComparison/${formatDateToMMDDYYYY(customStartDates)}&${formatDateToMMDDYYYY(customEndDates)}&${schoolvalue}&${locationid}`, 'MonthlyTicketComparison')
                await fetchAdminData(`/TicketByIssue/${formatDateToMMDDYYYY(customStartDates)}&${formatDateToMMDDYYYY(customEndDates)}&${schoolvalue}&${locationid}`, 'TicketByIssue')
                await fetchAdminData(`/TicketsPerDevice/${formatDateToMMDDYYYY(customStartDates)}&${formatDateToMMDDYYYY(customEndDates)}&${schoolvalue}&${locationid}`, 'TicketsPerDevice')
                await fetchAdminData(`/TechnicianPerformanceGraph/${formatDateToMMDDYYYY(customStartDates)}&${formatDateToMMDDYYYY(customEndDates)}&${locationid}&${schoolvalue}`, 'TechnicianPerformance')
            } catch (error) {
                console.error('An error occurred:', error);
            }
            loadKPIDataValue = 1
        }
        if (loadTableDataValue == 0 && ViewData?.TableView?.tab == 'active') {
            setAllData((prevState) => ({
                ...prevState,
                incomming: { ...prevState?.incomming, loader: true },
                outgoing: { ...prevState?.outgoing, loader: true },
                technician: { ...prevState?.technician, loader: true },
                schoolinvoice: { ...prevState?.schoolinvoice, loader: true },
            }));
            try {
                await fetchAdminData(`/getIncomingDevices/${MMDDYYYY(sdate)}&${MMDDYYYY(edate)}&${locationid}&${schoolvalue}&1`, 'incomming')
                await fetchAdminData(`/getOutgoingDevices/${MMDDYYYY(sdate)}&${MMDDYYYY(edate)}&${locationid}&${schoolvalue}&1`, 'outgoing')
                await fetchAdminData(`/getTechnicianPerformance/${MMDDYYYY(sdate)}&${MMDDYYYY(edate)}&${locationid}&${schoolvalue}&1`, 'technician')
                await fetchAdminData(`/getSchoolInvoices/${MMDDYYYY(sdate)}&${MMDDYYYY(edate)}&${locationid}&${schoolvalue}&1`, 'schoolinvoice')
            } catch (error) {
                console.error('An error occurred:', error);
            }
            loadTableDataValue = 1
        }

        setTabSwitch({ loadKPIData: loadKPIDataValue, loadTableData: loadTableDataValue })

    };
    const fetchAdminData = async (endpoint, dataKey) => {
        try {
            const result = await ApiGetCall(endpoint);

            if (result == undefined || result === "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setAllData((prevState) => ({
                    ...prevState, // Ensure prevState is not undefined
                    [dataKey]: { ...prevState?.[dataKey], data: responseRs[dataKey], loader: false },
                }));
            }
        } catch (error) {
            setAllData((prevState) => ({
                ...prevState,
                [dataKey]: { ...prevState?.[dataKey], data: [], loader: false },
            }));
        }
    };
    return (
        <>
            <div className="" style={{ padding: "0 1.2rem 1.2rem 1.2rem" }}>
                <div className={` schoolDashboard pt-3`} >
                    <div className="row GridBox p-2 mx-0">
                        <div className="col-md-5 col-12 align-self-center pb-lg-0 pb-2"><h1 className="PageHeading mb-0">Dashboard</h1></div>
                        <div className="col-md-7 col-12">
                            <div className="row">
                                <div className="col-md-3 col-6 pb-lg-0 pb-2 mobile"></div>
                                <div className="col-md-3 col-6 pb-lg-0 pb-2">
                                    <div className="tabView p-2 cursor-pointer display-center" onClick={() => { setViewData((prevState) => ({ ...prevState, KPIView: { ...prevState?.KPIView, tab: "active" }, TableView: { ...prevState?.TableView, tab: "" } })) }}>
                                        <img className="img-fluid pe-2" src={`/images/${ViewData?.KPIView?.tab == "active" ? "chartG" : "chart"}.svg`} alt="" />
                                        <span style={{ color: ViewData?.KPIView?.tab == "active" ? "#3CBBA5" : "#000", fontSize: "16px", fontWeight: "550" }}>Statistics</span>
                                    </div>
                                </div>
                                <div className="col-md-3 col-6 pb-lg-0 pb-2">
                                    <div className="tabView p-2 cursor-pointer display-center" onClick={() => { setViewData((prevState) => ({ ...prevState, KPIView: { ...prevState?.KPIView, tab: "" }, TableView: { ...prevState?.TableView, tab: "active" } })) }}>
                                        <img className="img-fluid pe-2" src={`/images/${ViewData?.TableView?.tab ? "tableG" : "table"}.svg`} alt="" />
                                        <span style={{ color: ViewData?.TableView?.tab == "active" ? "#3CBBA5" : "#000", fontSize: "16px", fontWeight: "550" }}>Tables</span>
                                    </div>
                                </div>
                                <><div className="col-md-3 col-6 pb-lg-0 pb-2 filter-dropdown " ref={FilterRef}>
                                    <div className="tabView p-2 cursor-pointer display-center " onClick={() => { setViewData((prevState) => ({ ...prevState, filterView: { ...prevState?.filterView, sortView: !prevState?.filterView?.sortView, } })) }}>
                                        <img className="img-fluid pe-2" src={`/images/${ViewData?.filterView?.sortView ? "sortG" : "sort"}.svg`} alt="" />
                                        <span style={{ color: ViewData?.filterView?.sortView ? "#3CBBA5" : "#000", fontSize: "16px", fontWeight: "550" }}>Filter</span>
                                    </div>
                                    <div className={`admin-filter-dropdown-content greyBoxShadow p-2 ${ViewData?.filterView?.sortView ? "" : "d-none"} ${ViewData?.KPIView?.tab == 'active' && qflag == "1" ? "admin-KPI-active" : "admin-table-active"}`}>
                                        <div className="row">
                                            {ViewData?.KPIView?.tab == 'active' && <>
                                                <div className={`${qflag == "1" ? "col-md-2" : "col-md-3"} py-1`}>
                                                    <select value={qflag} onChange={(e) => { setQflag(e.target.value) }}>
                                                        <option value="1">Custom Date</option>
                                                        <option value="2">Current Month</option>
                                                        <option value="3">Current Quarter</option>
                                                        <option value="4">Current Year</option>
                                                        <option value="5">Last Month</option>
                                                        <option value="6">Last Quarter</option>
                                                        <option value="7">Last Year</option>
                                                    </select>
                                                </div>
                                            </>}

                                            {((ViewData?.KPIView?.tab == 'active' && qflag == "1") ||
                                                (ViewData?.TableView?.tab == 'active' && "1" == "1")) &&
                                                <>
                                                    <div className={`${ViewData?.KPIView?.tab == 'active' ? qflag == "1" ? "col-md-4" : "col-md-3" : "col-md-5"}`}>
                                                        <div className="row">
                                                            <div className="col-sm-5 pe-md-0 py-1">
                                                                <ReactDatePicker
                                                                    className="form-control text-center"
                                                                    selected={startDate}
                                                                    onChange={(e) => { setStartDate(e) }}
                                                                    dateFormat="MM/dd/yyyy"
                                                                />
                                                            </div>
                                                            <div className="col-sm-2 display-center px-0">
                                                                <label className="px-3">To</label>
                                                            </div>
                                                            <div className="col-sm-5 ps-md-0  py-1 ">

                                                                <ReactDatePicker
                                                                    className="form-control text-center"
                                                                    selected={endDate}
                                                                    onChange={(e) => { setEndDate(e) }}
                                                                    dateFormat="MM/dd/yyyy"
                                                                    maxDate={today}
                                                                    popperProps={{ strategy: 'fixed' }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}


                                            <div className={`${ViewData?.KPIView?.tab == 'active' ? qflag == "1" ? "col-md-4" : "col-md-6" : "col-md-5"}`}>
                                                <div className="row">
                                                    <div className="col-sm-6 py-1 pe-md-0">
                                                        <select value={LocationValue} onChange={(e) => { handleLocationValue(e.target.value) }}>
                                                            <option value="0">Filter by Location</option>
                                                            {Locations.map((locationItem, j) => (
                                                                <option value={locationItem.id} key={j}>
                                                                    {locationItem.locationName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-6 py-1 pe-md-0">
                                                        <select value={SchoolValue} onChange={(e) => { setSchoolValue(e.target.value) }}>
                                                            <option value="0">Filter by School</option>
                                                            {SchoolFilterData?.length == 0 && <optgroup label="No school found"></optgroup>}
                                                            {SchoolFilterData?.map((item, j) => (
                                                                <option value={item.id} key={j}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`${ViewData?.KPIView?.tab == 'active' ? qflag == "1" ? "col-md-2" : "col-md-3" : "col-md-2"} d-flex py-1 pe-md-4 align-items-center`}>
                                                <button className="btn btn-secondary w-100 filter-apply" onClick={() => {

                                                    setViewData(({ ...ViewData, filterView: { ...ViewData?.filterView, sortView: false } }));
                                                    DateFilterHandel(qflag, startDate, endDate, LocationValue, SchoolValue, true);
                                                }}>Apply</button>
                                                <div className="col-md-4 col-2 ps-2" title="Clear filter"><img className="img-fluid cursor-pointer" src="/images/clear-filter.svg" alt="" onClick={resetallfilter} /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div></>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='GridBox px-3 mt-4' style={{ opacity: ViewData?.filterView?.DateView || ViewData?.filterView?.sortView ? "0.4" : "1", boxShadow: "none" }}>
                    {ViewData?.KPIView?.tab == "active" ? <>
                        <div className="row pt-4 pb-2 px-2 admin-dashboard-box-fonts">
                            <DashboardBox UserData={UserData} img={"admin-dashboard-icon-two.svg"} css={'admin-dashboard-box-two'} textcss={"boxone"} headers={"Total schools registered"} AllData={AllData?.KPIData?.data?.SchoolAccount} loader={AllData?.KPIData?.loader} />
                            <DashboardBox UserData={UserData} img={"admin-dashboard-icon-one.svg"} css={'admin-dashboard-box-one'} textcss={"boxtwo"} headers={"Total devices registered"} AllData={AllData?.KPIData?.data?.RegisterDevices} loader={AllData?.KPIData?.loader} />
                            <DashboardBox UserData={UserData} img={"admin-dashboard-icon-four.svg"} css={'admin-dashboard-box-four'} textcss={"boxthree"} headers={"Total tickets raised"} AllData={AllData?.KPIData?.data?.TicketCount} loader={AllData?.KPIData?.loader} />
                            <DashboardBox UserData={UserData} img={"admin-dashboard-icon-five.svg"} css={'admin-dashboard-box-five'} textcss={"boxfive"} headers={"Devices 7+ Days"} AllData={AllData?.KPIData?.data?.AtvendorCount} loader={AllData?.KPIData?.loader} flag="1" CsvData={AllData?.KPIData?.data?.AtvendorData} />
                            {UserData?.MenuAccessFlag === 6 &&
                                <DashboardBox UserData={UserData} img={"goal.svg"} css={'admin-dashboard-box-three'} textcss={"boxfour"} headers={"Your points"} AllData={AllData?.KPIData?.data?.TechnicianRevenue} />
                            }
                        </div>
                        <div className="row">
                            <MonthlyTicketComparison MonthlyTicketComparison={AllData?.MonthlyTicketComparison} />
                            <TicketsPerRepair TicketsPerDevice={AllData?.TicketsPerDevice} />
                            <TicketByIssueGraph TicketByIssue={AllData?.TicketByIssue} />
                            <TechniciansPerformance TechnicianPerformance={AllData?.TechnicianPerformance} />
                        </div>
                    </> : <>
                        <div className="row py-3">
                            <IncomingDeviceTable startDate={startDate} endDate={endDate} LocationValue={LocationValue} SchoolValue={SchoolValue} incommingData={AllData?.incomming} />
                            <OutgoingDeviceTable startDate={startDate} endDate={endDate} LocationValue={LocationValue} SchoolValue={SchoolValue} outgoingData={AllData?.outgoing} />
                        </div>
                        <div className="row">
                            <TechnicianTable startDate={startDate} endDate={endDate} LocationValue={LocationValue} SchoolValue={SchoolValue} technicianData={AllData?.technician} />
                            <SchoolTable startDate={startDate} endDate={endDate} LocationValue={LocationValue} SchoolValue={SchoolValue} schoolinvoiceData={AllData?.schoolinvoice} />
                        </div>
                    </>}
                </div >
            </div>
        </>
    )
}
