import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../App';
import { ApiGetCall, ApiPostCall } from '../../JS/Connector';
import { LogInSchoolID, convertToBase64, getpostalcodes } from '../../JS/Common';
import { CheckValidation } from '../../Components/Validations';
import AlertsComp from '../../Components/AlertsComp';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
export default function SchoolDetails({ setLoading, setNewAlerts }) {
    const { UserData, setUserData } = useContext(UserContext);
    const [postImage, setPostImage] = useState({ myFile: "" });
    const [previewLogoDiv, setPreviewLogoDiv] = useState('d-none');
    const [InvalidSchoonm, setInvalidSchoonm] = useState("");
    const [image, setImage] = useState(null);
    const [crop, setCrop] = useState({
        unit: "%",
        width: 30,
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        aspectRatio: 1 / 1,
        minWidth: 0,
        minHeight: 0,
        maxWidth: Infinity,
        maxHeight: Infinity,
    });
    const [result, setResult] = useState(null);
    const [BatchData, setBatchData] = useState({
        batchname: "",
        Phoneno: "",
        Street1: "",
        Street2: "",
        city: "",
        state: 0,
        postalcode: "",
        addupdateFlag: 0,
        SchoolNm: '',
        timezone: '',
        fedex_company_name: ''
    })
    const [AllState, setallstate] = useState([]);
    const [SchoolLogo, setSchoolLogo] = useState('')
    const [allTimeZone, setAllTimeZone] = useState([])
    useEffect(() => {
        getpostalcodes(setallstate);
        GetSchoolData();
        GetAllTimeZone();
    }, []);

    async function GetSchoolData() {
        await ApiGetCall("/SchoolAddressbyID/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                var shippingaddress = responseRs.schoolAddress || {};
                setBatchData({
                    ...BatchData,
                    Street1: shippingaddress.street_line || '',
                    Street2: shippingaddress.street_line2 || '',
                    city: shippingaddress.city || '',
                    state: shippingaddress.state_or_province_code || '',
                    postalcode: shippingaddress.postal_code || '',
                    Phoneno: shippingaddress.phone_num || '',
                    addupdateFlag: shippingaddress != "null" ? 1 : 0,
                    SchoolNm: responseRs.schoolName || '',
                    timezone: shippingaddress.timezone_id || 0,
                    fedex_company_name: shippingaddress.fedex_company_name || responseRs.schoolName
                });
                setSchoolLogo(responseRs.logo || '');
            }
        });

    }
    async function GetAllTimeZone() {
        await ApiGetCall("/GetTimeZones").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setAllTimeZone(responseRs);
            }
        });

    }
    async function handleFileUpload(e) {
        setPostImage({ ...postImage, myFile: '' });
        const file = e.target.files[0];
        const base64 = await convertToBase64(file);
        if (base64) {
            setPreviewLogoDiv('');
            setSchoolLogo('');
        } else {
            setPreviewLogoDiv('d-none')
        }
        setPostImage({ myFile: base64 });
    };
    async function getCroppedImg() {
        try {
            const canvas = document.createElement("canvas");
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d", { alpha: true });
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height
            );
            setResult(canvas.toDataURL("image/png"));
        } catch (e) {
            alert("Something went wrong");
        }
    }
    async function UpdateSetting() {
        var isFormValid = CheckValidation({ formID: 'CreateBatchDiv' });
        if (!isFormValid) return;
        setLoading(true);
        const uploadedlogo = result || postImage.myFile;
        var raw = JSON.stringify({
            SchoolId: parseInt(LogInSchoolID),
            StreetLine1: BatchData.Street1,
            StreetLine2: BatchData.Street2 ? BatchData.Street2 : null,
            City: BatchData.city,
            State: BatchData.state,
            PostalCode: BatchData.postalcode,
            CountryCode: 'US',
            AddUpdateId: BatchData.addupdateFlag,
            PhoneNum: BatchData.Phoneno,
            UploadLogo: uploadedlogo || null,
            SchoolName: BatchData.SchoolNm,
            TimeZone: BatchData.timezone,
            FedexCompanyName: BatchData.fedex_company_name
        });
        await ApiPostCall("/additionalSetting", raw).then((response) => {
            if (response == undefined || response == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(response);
                if (responseRs.status == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={'School Details Updated Successfully'} />);
                    setUserData({ ...UserData, schoollogo: uploadedlogo })
                    setTimeout(function () {
                        setNewAlerts(<AlertsComp show={false} />)
                        GetSchoolData();
                    }, 1500);
                } else if (responseRs.status == "error") {
                    setInvalidSchoonm(responseRs.msg)
                }
                else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }
    function handleInputChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        setBatchData({
            ...BatchData,
            [name]: value
        })
    }
    return (
        <>
            <div className="greyBox h-100">
                <div className='row d-flex align-items-center p-1'>
                    <span className='GridTitle pb-2'> Update School Details</span>
                </div>
                <hr className='my-0' />
                <div className="row pe-0 parent" id="CreateBatchDiv">
                    <div className="col-xl-4 col-12 pt-3 ">
                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Rocket Account Name*</div>
                        <input type="text" autoComplete='off' name='SchoolNm' className="form-control" required
                            value={BatchData.SchoolNm} onChange={handleInputChange} />
                        <span className="form-text invalid-feedback" >
                            *required
                        </span>
                    </div>
                    <div className='col-12 pt-4'>
                        <b><u>Shipping Details</u></b>
                    </div>
                    <div className="col-xl-8 col-12 pt-3 ">
                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Fedex Shipping Company name*</div>
                        <input type="text" autoComplete='off' name='fedex_company_name' className="form-control" required
                            value={BatchData.fedex_company_name} onChange={handleInputChange} />
                        <span className="form-text invalid-feedback" >
                            *required
                        </span>
                    </div>
                    <div className="col-xl-4 col-12 pt-3">
                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Phone No.*</div>
                        <input type="text" autoComplete='off' name='Phoneno' className="form-control" required
                            value={BatchData.Phoneno} onChange={handleInputChange}
                            onKeyPress={(e) => {
                                if (!/^\d$/.test(e.key)) {
                                    e.preventDefault();
                                }
                            }}
                        />
                        <span className="form-text invalid-feedback" >
                            *required
                        </span>
                    </div>
                    <div className="col-xl-4 col-12 pt-3">
                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Street Line 1*</div>
                        <input type="text" autoComplete='off' name='Street1' className="form-control" required
                            value={BatchData.Street1} onChange={handleInputChange} />
                        <span className="form-text invalid-feedback" >
                            *required
                        </span>
                    </div>
                    <div className="col-xl-4 col-12 pt-3">
                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Street Line 2</div>
                        <input type="text" autoComplete='off' name='Street2' className="form-control"
                            value={BatchData.Street2} onChange={handleInputChange} />
                    </div>
                    <div className="col-xl-4 col-12 pt-3">
                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>City*</div>
                        <input type="text" autoComplete='off' name='city' className="form-control" required
                            value={BatchData.city} onChange={handleInputChange} />
                        <span className="form-text invalid-feedback" >
                            *required
                        </span>
                    </div>
                    <div className="col-xl-4 col-12 pt-3">
                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>State*</div>
                        <select required name='state' onChange={handleInputChange}>
                            <option value="0">Select</option>
                            {AllState.map((item, i) => {
                                return <option key={i} value={item.state_code} selected={BatchData.state == item.state_code}>{item.state_code}</option>
                            })}
                        </select>
                        <span className="form-text invalid-feedback" >
                            *required
                        </span>
                    </div>
                    <div className="col-xl-4 col-12 pt-3">
                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Postal Code*</div>
                        <input type="number" required autoComplete='off' name='postalcode' className="form-control"
                            value={BatchData.postalcode} onChange={handleInputChange} />
                        <span className="form-text invalid-feedback" >
                            *required
                        </span>
                    </div>
                    <div className="col-xl-4 col-12 pt-3">
                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Country</div>
                        <input type="text" autoComplete='off' value='US' disabled name='City' className="form-control" />
                    </div>

                </div>
                <div className='row mt-5'>
                    <div className="col-xl-4 col-12 pt-3">
                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Set Time Zone*</div>
                        <select required name='timezone' onChange={handleInputChange}>
                            <option value="0">Select</option>
                            {allTimeZone.map((item, i) => {
                                return <option key={i} value={item.id} selected={BatchData.timezone == item.id}>{item?.timezone} - {item?.description}</option>
                            })}
                        </select>
                        <span className="form-text invalid-feedback" >
                            *required
                        </span>
                    </div>
                    <div className="col-xl-4 pt-3">
                        <div className='col-12 text-start px-1 shipdevice-font-style'>Upload Logo</div>
                        <form className="col-12">
                            <input type="file" label="Image" name="myFile" accept=".jpg,.png,.svg,.jpeg" id="UploadFileId" onChange={(e) => handleFileUpload(e)} />
                            <label className="ImportInventoryBtn col-md-9 col-9" htmlFor="UploadFileId">Upload logo</label>
                            <label className="ImportInventoryBtn1 col-md-3 col-3 text-center" htmlFor="UploadFileId" style={{ color: "white" }}>
                                Browse
                            </label>
                        </form>
                    </div>
                    <div className="p-3 ps-1 row">
                        {SchoolLogo ? (
                            <div className="col-12 text-center">
                                <img src={SchoolLogo} alt="school logo" className='img-fluid' style={{ height: "100px", width: "100px" }} />
                            </div>
                        ) : (
                            <div className={`col-md-12 ${previewLogoDiv}`}>
                                <div className="py-3 uploadLogoDiv">
                                    {postImage.myFile && (
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ReactCrop
                                                    className="cursor-pointer"
                                                    style={{ width: "100%", height: "100%" }}
                                                    src={postImage.myFile}
                                                    onImageLoaded={setImage}
                                                    crop={crop}
                                                    onChange={setCrop}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                {result && <img src={result} alt="cropped image" />}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <input
                                    type="submit"
                                    value="Preview"
                                    className="SaveBtn upload-save px-3"
                                    onClick={getCroppedImg}
                                    style={{ float: "right" }}
                                />
                            </div>
                        )}
                        <div className='Emailinvalid text-center'>{InvalidSchoonm}</div>
                        <div className="col-12 text-center pt-4">
                            <button className="SaveBtn" onClick={UpdateSetting}> Update Setting   </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
