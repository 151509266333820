import React, { useContext, useState } from 'react'
import Cookies from 'js-cookie';
import { HideLoder, LogInSchoolID, LogInUserID, ShowLoder, formatDateToMMDDYYYY } from '../JS/Common';
import AlertsComp from './AlertsComp';
import { ApiPostCall } from '../JS/Connector';
import { useNavigate } from 'react-router-dom';
import Popup from './Popup';
import { UserContext } from '../App';
function CloseInvoicePopup(props) {
    const { UserData } = useContext(UserContext);
    const navigate = useNavigate();
    const today = new Date().toISOString().substring(0, 10);
    const raw = props.data
    const BucketS3Link = process.env.REACT_APP_CDN_KEY
    const [Alerts, setAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    const [NoTickets, setNoTickets] = useState(false);
    var ciphertext = Cookies.get('ciphertext');
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", ciphertext);

    async function GenerateInvoice(flag) {
        var rawData = JSON.stringify({
            schoolid: LogInSchoolID,
            userid: LogInUserID,
            ticketid: raw.TicketId,
            StudentId: raw.StudentId == null ? 0 : raw.StudentId,
            StudentNum: raw.StudentNumber,
            FirstName: raw.FirstName,
            MiddleName: raw.MiddleName,
            LastName: raw.LastName,
            Grade: raw.Grade,
            ParentEmail: raw.ParentEmail,
            ParentName: raw.ParentName,
            ParentPhoneNumber: raw.ParentPhoneNumber,
            ParentCheck: raw.ParentCheck == 1 ? 1 : 0,
            TotalCost: raw.TotalCost,
            StudentType: raw.StudentType,
            Issues: raw.Issues,
            Notes: raw.Notes,
            PartsUsed: raw.PartsUsed,
            Department: raw.Department,
            ParentEmailFlag: raw.ParentEmailFlag,
            IsStudent: raw.IsStudent,
            StudentEmail: raw.useremail
        });
        setLoading(true);
        await ApiPostCall("/createInvoiceForParent", rawData).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    if (flag == 1) {
                        window.open(BucketS3Link + responseRs.msg, '_blank');
                        navigate('/school-invoice')
                    } else if (flag == 2) {
                        props.closeInvoiceModal();
                    }
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                }
            }
        });
    }
    const modalbody = (
        <div className='col-12 invoce-modal-style' id='invoicediv'>
            <div className='row pt-3 align-items-center px-3'>
                <div className='col-6'>
                    <h2 className='fw-600 mb-0' style={{ color: "gray" }}>INVOICE</h2>
                </div>
                <div className='col-6 text-end'>
                    Date : {formatDateToMMDDYYYY(today)}
                </div>
            </div>
            <div className='row pt-1 px-3'>
                <div className='col-6'>
                    <label className='fw-600 font-16'>{raw?.SchoolnameOnly}</label>
                    {raw?.SchoolAddress != 'null' && (
                        <div className='col-12'>
                            {raw?.SchoolAddress?.street_line},{(raw?.SchoolAddress?.street_line2 !== '' && raw?.SchoolAddress?.street_line2 !== 'null' && raw?.SchoolAddress?.street_line2 !== null) ? raw?.SchoolAddress?.street_line2 + ',' : ''}<br />
                            {raw?.SchoolAddress?.city}, {raw?.SchoolAddress?.state_or_province_code}, {raw?.SchoolAddress?.country_code} - {raw?.SchoolAddress?.postal_code}<br />
                            Contact : {raw?.SchoolAddress?.phone_num}
                        </div>
                    )}
                </div>
                <div className='col-6 text-end'>
                    <img src={raw?.SchoolLogoOnly} id="LeftMenuLogo" className="logo-icon" height='45px' />
                </div>
            </div>
            <div className='row px-3 py-5'>
                <div className='col-6'>
                    <div className='font-18 fw-600'>Ticket Details<hr className='my-1 col-md-5' /></div>
                    <div className='row pb-3'>
                        <div className='col-12 py-1'>
                            <div>Ticket # :  {raw.Ticket || '-'}</div>
                        </div>
                        <div className='col-12 py-1'>
                            <div>Serial # : {raw.Serial || '-'} </div>
                        </div>
                        <div className='col-12 py-1'>
                            <div>Created Date :  {raw.CreatedDate || '-'}</div>
                        </div>
                        <div className='col-12 py-1'>
                            <div>Building :  {raw.Building || '-'}</div>
                        </div>
                        <div className='col-12 py-1'>
                            <div>Issues  : {raw.Issues || '-'} </div>
                        </div>
                        <div className='col-12 py-1'>
                            <div>Parts Used  : {raw.PartsUsed || '-'} </div>
                        </div>
                    </div>
                </div>
                <div className='col-6'>
                    <div className='font-18 fw-600'>User Details<hr className='my-1 col-md-5' /></div>
                    <div className='row pb-3'>
                        <div className='col-12 py-1'>
                            <div>User Name : {(raw.FirstName || '') + ' ' + (raw.MiddleName || '') + ' ' + (raw.LastName || '')} </div>
                        </div>
                        <div className='col-12 py-1'>
                            <div>User ID : {raw.StudentNumber || '-'} </div>
                        </div>
                        <div className='col-12 py-1'>
                            <div>User Email : {raw.useremail || '-'} </div>
                        </div>
                        <div className='col-12 py-1'>
                            <div>Department : {raw.Department || '-'} </div>
                        </div>
                        <div className='col-12 py-1'>
                            <div>Grade : {raw.Grade || '-'} </div>
                        </div>

                        <div className='col-12 py-1'>
                            <div>Parent / Guardian Email : {raw.ParentEmail || '-'} </div>
                        </div>
                        <div className='col-12 py-1'>
                            <div>Parent / Guardian Name : {raw.ParentName || '-'} </div>
                        </div>
                        <div className='col-12 py-1'>
                            <div>Parent / Guardian Contact : {raw.ParentPhoneNumber || '-'} </div>
                        </div>
                        <div className='col-12 py-1'>
                            <div>Parental Coverage : {raw.ParentCheck == 1 ? 'Yes' : 'No'}</div>
                        </div >
                    </div >
                </div >
            </div >
            <div className='row ps-3'>
                <div className='font-18 py-2'>Total Cost :<b> ${raw.TotalCost || '0'}</b></div>
                {UserData.PaymentLinkFlag == 1 && (
                    <div className='py-2'>Payment Link :<b> {UserData.PaymentLink}</b></div>
                )}
                <div className='py-2'>Notes  : {raw.Notes || '-'} </div>
                <div className='py-2'>If you have any questions concerning this invoice, contact <label className='fw-600'>{raw?.SchoolnameOnly}.</label> </div>
            </div>
            <div className='text-center py-5'><b>THANK YOU!</b></div>
        </div >
    )
    const handleSaveAndNext = () => {
        if (props.AllTickets && props.currentTicketIndex < props.AllTickets.length - 1) {
            GenerateInvoice(2);
            props.setCurrentTicketIndex(props.currentTicketIndex + 1);
        } else {
            GenerateInvoice(3);
            setNoTickets(true);
        }
    };
    const modalfooter = (
        <>
            {NoTickets && (
                <div className='col-12 d-flex justify-content-center zoom-in-out-box mb-3'>
                    <label className='signuplink fs-5' >No more tickets to process. <u className='cursor-pointer' onClick={(e) => downloadAllInvoice()}>Download Invoice</u></label>
                </div>
            )}
            <label className="ms-2 cursor-pointer" onClick={(e) => props.closeInvoiceModal()}><u>Cancel</u></label>
            {props?.AllTickets?.length > 0 && (
                <button className={`SaveBtn ms-2`} onClick={(e) => handleSaveAndNext()}>Generate Invoice & Next</button>
            )}
            <button className={`SaveBtn ms-2`} onClick={(e) => props.closeInvoiceModal()}>Edit Invoice</button>
            <button className={`SaveBtn ms-2`} onClick={(e) => GenerateInvoice(1)}>Generate Invoice</button>
        </>

    )
    async function downloadAllInvoice() {
        const BaseUrl = process.env.REACT_APP_Base_URL;
        var ciphertext = Cookies.get('ciphertext');
        var myHeaders = new Headers();
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Authorization", ciphertext);
        setLoading(true);
        var FinalArray = props.AllTickets.map(ticket => String(ticket.ID));
        const formdata = new FormData();
        formdata.append('schoolid', LogInSchoolID);
        formdata.append('id', JSON.stringify(FinalArray));
        formdata.append('flag', 2);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };
        fetch(`${BaseUrl}/createInvoiceForMultipleTicket`, requestOptions).then(response => response.text())
            .then(result => {
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    const CDNkey = process.env.REACT_APP_CDN_KEY;
                    const pdfUrl = CDNkey + responseRs.path;
                    window.open(pdfUrl, '_blank');
                    navigate('/school-invoice')
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={"Failed to download invoice."} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                }
                setLoading(false);
            })
            .catch(error => console.log('error', error));
    }
    return (
        <>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <Popup isshow={props.isShow} size={"lg"} title={"Generate invoice for Parent/Student"}
                closePopup={(e) => { props.closeInvoiceModal() }} modalBody={modalbody} modalfooter={modalfooter} />
        </>
    )
}

export default CloseInvoicePopup
