import React from 'react'
import { ApiGetCall } from '../../JS/Connector';
import AlertsComp from '../../Components/AlertsComp';
import { MMDDYYYY } from '../../JS/Common';
import GreyBoxForReports from './GreyBoxForReports';

export default function MassIncomingBatch({ setLoading, FilterData, setalerts, downloadAllChecked }) {
    async function GetBatchData() {
        var downloadall = 0;
        if (downloadAllChecked) {
            downloadall = 1;
        }
        setLoading(true);
        await ApiGetCall("/AllIncomingBatchDetailsReport/" + MMDDYYYY(FilterData.StartDate) + '&' + MMDDYYYY(FilterData.EndDate) + '&' + FilterData.Location + '&' + FilterData.School + '&' + downloadall).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.length > 0) {
                    DownloadCsv(responseRs);
                } else {
                    DownloadCsv([]);
                    setalerts(<AlertsComp show={true} variant="danger" msg={"No Data Found"} />);
                    setTimeout(() => {
                        setalerts(<AlertsComp show={false} />);
                    }, 2000);
                }
                setLoading(false);
            }
        });
    }
    const DownloadCsv = (CsvData) => {
        const csvContent = [];
        const PurchaseMap = [];
        CsvData?.forEach((item) => {
            const rowData = {
                'School Name': item.SchoolName,
                'Batch Name': item.BatchName,
                'Create Date': MMDDYYYY(item.CreatedAt),
                'Receive Date': item.ReceivedDate ? MMDDYYYY(item.ReceivedDate) : '-',
                'Received By': item.BatchReceivedBy,
                'Device Count': item.DeviceCount,
                'School Type': item.SchoolType == 1 ? 'Local' : 'Mail In'
            };
            PurchaseMap.push(rowData);
        });

        if (PurchaseMap.length > 0) {
            const headers = ['School Name', 'Batch Name', 'Create Date', 'Receive Date', 'Received By', 'Device Count', 'School Type'];
            csvContent.push(headers);
            PurchaseMap.forEach((rowData) => {
                const values = Object.values(rowData).map(value => {
                    if (typeof value == 'string' && value.includes(',')) {
                        return `"${value}"`;
                    }
                    return value;
                });
                csvContent.push(values);
            });
            csvContent.push([]);
            const csvString = csvContent.map((row) => row.join(',')).join('\n');
            const blob = new Blob([csvString], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'BulkIncomingBatches.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.log('No data available for download.');
        }
    };
    return (
        <GreyBoxForReports icon="faTruckFast" name={"Bulk Incoming Batches Report Download"} onclickfunc={GetBatchData} />
    )
}
