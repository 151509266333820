import React from 'react'
import { ApiGetCall } from '../../JS/Connector';
import { LogInUserID, MMDDYYYY } from '../../JS/Common';
import AlertsComp from '../../Components/AlertsComp';
import GreyBoxForReports from './GreyBoxForReports';

export default function MassTickets({ setLoading, FilterData, setalerts, downloadAllChecked }) {
    async function GetTicketData() {
        var downloadall = 0;
        if (downloadAllChecked) {
            downloadall = 1;
        }
        setLoading(true);
        setalerts(<AlertsComp show={true} variant="success" msg={`You will receive an email notification once the data is ready.`} />);

        setTimeout(() => {
            setalerts(<AlertsComp show={false} />);
            setLoading(false);
        }, 8000);

        setTimeout(() => {
            ApiGetCall("/AllTicketDetailsReport/" + MMDDYYYY(FilterData.StartDate) + '&' + MMDDYYYY(FilterData.EndDate) + '&' + FilterData.Location + '&' + FilterData.School + '&' + LogInUserID + '&' + downloadall);
        }, 0);
    }
    return (
        <GreyBoxForReports icon="faTicket" name={"Bulk Tickets Report Download"} onclickfunc={GetTicketData} />
    )
}
