import React, { useEffect, useState, Fragment } from "react";
import { ApiGetCall, ApiGetHeaders, ApiPostCall } from "../../JS/Connector";
import { LogInSchoolID, typewatch, Camelcase, getAllBuildings } from "../../JS/Common";
import Pagination from "react-js-pagination";
import Deletepopup from "../../Components/Deletepopup";
import AlertsComp from "../../Components/AlertsComp";
import { CheckValidation } from "../../Components/Validations";
import CustomizationPopup from "../../Components/CustomizationPopup";
import PageHead from "../../Components/PageHead";
import Tab from "../../Components/Tab";
import Popup from "../../Components/Popup";

export function ManageParts() {
  const [partsTabs, setPartsTabs] = useState({
    SchoolPartsTab: "active",
    MasterPartsTab: "",
  });
  const [SearchForSchool, setSearchForSchool] = useState("");
  const [SearchForMaster, setSearchForMaster] = useState("");
  const [tabflag, settabflag] = useState(1);
  const [GridData, setGridData] = useState([]);
  const [addpartsPopup, setAddpartsPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [formData, setFormdata] = useState({
    partName: "",
    brandName: "",
    variantPrice: "",
    reorderQuantity: "",
    Handel: "",
    totalQuantity: "",
  });
  const [FlagForAddUpdateDelete, setFlagForAddUpdateDelete] = useState({
    Flag: "",
    IdForDelete: "",
  });
  const [NewAlerts, setNewAlerts] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [LimitForPagination, setLimitForPagination] = useState(25);
  const [pageRangeCount, setpageRangeCount] = useState(0);
  const showPagination = GridData.length > 0;
  const [CustomizationModal, setCustomizationModal] = useState(false)
  const [columnNames, setColumnNames] = useState([]);
  const [Buildings, setBuildings] = useState([]);
  const [selectedBuildings, setSelectedBuildings] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [isMassEditActive, setIsMassEditActive] = useState(false);
  const [editedBuildings, setEditedBuildings] = useState({});

  useEffect(() => {
    ApiGetHeaders(13, setColumnNames)
    getAllBuildings(setBuildings, LogInSchoolID);
  }, []);

  useEffect(() => {
    if (partsTabs.MasterPartsTab == "active") {
      GetPartData(2, SearchForMaster ? SearchForMaster : null);
    } else {
      GetPartData(1, SearchForSchool ? SearchForSchool : null);
    }
  }, [currentPage, LimitForPagination]);

  async function GetPartData(flag, SearchString) {
    if (!SearchString) SearchString = null;
    var joinedString = null;

    if (columnNames.length > 0) {
      var joinedString = columnNames.join('~');
    }
    typewatch(async function () {
      setLoading(true);
      await ApiGetCall("/getPartsList/" + LogInSchoolID + "&" + SearchString + "&" + flag + '&' + currentPage + '&' + LimitForPagination + "?data=" + joinedString).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          setLoading(false);
          const responseRs = JSON.parse(result);
          setpageRangeCount(responseRs.msg.total)
          setGridData(responseRs.msg.data.length ? responseRs.msg.data : [])
        }
      });
    }, 500);
  }
  async function SavePart() {
    var BuildingData = [];
    if (FlagForAddUpdateDelete.Flag != 3) {
      var isFormValid = CheckValidation({ formID: "AddPartDiv" });
      if (!isFormValid) return;
      BuildingData = Object.entries(selectedBuildings)
        .map(([buildingId, { Quantity, UpdateFlag }]) => {
          if (FlagForAddUpdateDelete.Flag === 2) {
            if (UpdateFlag === 1) {
              return {
                building_id: buildingId,
                quantity: parseInt(Quantity) || 0, // Allow Quantity to be 0
              };
            }
            return null;
          } else {
            if (!Quantity || parseInt(Quantity) <= 0) return null;
            return {
              building_id: buildingId,
              quantity: parseInt(Quantity),
            };
          }
        })
        .filter(item => item != null);
    }

    setLoading(true);
    var raw = JSON.stringify({
      SchoolId: LogInSchoolID,
      Title: formData.partName,
      BrandName: formData.brandName ? formData.brandName : null,
      Price: formData.variantPrice,
      ReminderQuantity: formData.reorderQuantity,
      ID: FlagForAddUpdateDelete.IdForDelete,
      Handel: formData.Handel,
      Flag: FlagForAddUpdateDelete.Flag,
      BuildingPartQuantity: BuildingData,
      SchoolQuantity: formData.totalQuantity,
      TotalPartQuantity: parseInt(totalQuantity)
    });
    await ApiPostCall("/addUpdateDeleteParts", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        setLoading(false);
        if (result == "success") {
          if (FlagForAddUpdateDelete.Flag == 1) {
            var alertmsg = "Part Added Successfully";
          } else if (FlagForAddUpdateDelete.Flag == 2) {
            var alertmsg = "Part Updated Successfully";
          } else if (FlagForAddUpdateDelete.Flag == 3) {
            var alertmsg = "Part Delete Succesfully";
          } else if (FlagForAddUpdateDelete.Flag == 4) {
            var alertmsg = "Part Added Successfully";
          }
          setNewAlerts(
            <AlertsComp show={true} variant="success" msg={alertmsg} />
          );
          setTimeout(() => {
            setNewAlerts(<AlertsComp show={false} />);
            setAddpartsPopup(false);
            setDeletePopup(false);
            setTotalQuantity(0)
            setFormdata({ ...formData, reorderQuantity: '', brandName: '' })
            if (FlagForAddUpdateDelete.Flag == 4) {
              setCurrentPage(1)
              setPartsTabs({ SchoolPartsTab: "active", MasterPartsTab: "" });
              GetPartData(1, null);
            } else {
              if (partsTabs.MasterPartsTab == "active") {
                GetPartData(2, null);
              } else {
                GetPartData(1, null);
              }
            }
          }, 2000);
        } else {
          setNewAlerts(
            <AlertsComp show={true} variant="danger" msg={result} />
          );
          setTimeout(() => {
            setNewAlerts(<AlertsComp show={false} />);
          }, 2000);
        }
      }
    });
  }
  async function GetDataById(id) {
    setLoading(true);
    await ApiGetCall("/getPartsById/" + id).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        setLoading(false);
        setFormdata({
          ...formData,
          partName: responseRs.title,
          brandName: responseRs.brand_name,
          variantPrice: responseRs.variant_price,
          reorderQuantity: responseRs.reminder_quantity,
          Handel: responseRs.handle,
          totalQuantity: responseRs.school_quantity
        });
        setTotalQuantity(responseRs.quantity || 0);
        const updatedSelectedBuildings = Buildings.reduce((acc, building) => {
          const matchingPart = responseRs.building_part.find(
            (part) => part.building_id === building.id
          );
          acc[building.id] = {
            Quantity: matchingPart ? matchingPart.building_quantity : 0,
          };
          return acc;
        }, {});

        setSelectedBuildings(updatedSelectedBuildings);
      }
    });
  }
  function renderContentForColumn(columnName, item) {
    const contentMap = {
      "Title": item.title,
      "Brand_Name": item.brand_name || '-',
      "Variant_Price": `$${item?.variant_price?.toFixed(2)}` || '0',
      "Quantity": item.quantity || '0',
      "Reminder_Quantity": item.reminder_quantity || '0',
      "Handle": item.handle || '0',
    };
    return contentMap[columnName] || null;
  }
  function handleSearchChange(e) {
    tabflag == 1 ? setSearchForSchool(e.target.value) : setSearchForMaster(e.target.value);
    setCurrentPage(1);
    GetPartData(tabflag == 1 ? 1 : 2, e.target.value);
  }
  function handleTabClick(tabType) {
    if (tabType == "school") {
      GetPartData(1, null);
      setPartsTabs({ ...partsTabs, SchoolPartsTab: "active", MasterPartsTab: "" });
      setCurrentPage(1);
      settabflag(1);
      setSearchForMaster("");
      setSearchForSchool("");
    } else if (tabType == "master") {
      GetPartData(2, null);
      setPartsTabs({ ...partsTabs, SchoolPartsTab: "", MasterPartsTab: "active", });
      setCurrentPage(1);
      settabflag(2);
      setSearchForMaster("");
      setSearchForSchool("");
    }
  }
  function handleQuantityChange(e, buildingId) {
    let inputValue = e.target.value;
    if (inputValue == "0") {
      inputValue = "";
    } else {
      inputValue = inputValue.replace(/^0+/, '');
    }

    setSelectedBuildings((prevSelectedBuildings) => {
      const updatedSelectedBuildings = {
        ...prevSelectedBuildings,
        [buildingId]: {
          ...prevSelectedBuildings[buildingId],
          Quantity: inputValue,
          UpdateFlag: 1
        },
      };

      const buildingTotal = Object.values(updatedSelectedBuildings).reduce((sum, building) => {
        return sum + (parseInt(building.Quantity) || 0);
      }, 0);

      const total = buildingTotal + (parseInt(formData.totalQuantity) || 0);
      setTotalQuantity(total);
      return updatedSelectedBuildings;
    });
  }
  const handleSaveMassQtyChanges = async () => {
    const updatedParts = GridData.map((item) => {
      const updatedBuildingQuantities = item.building_part
        .map(building => {
          const editedBuildingQty = editedBuildings[item.id]?.[building.building_id]; // Ensure it's specific to the part
          return editedBuildingQty !== undefined
            ? { ID: building.building_id, Quantity: parseInt(editedBuildingQty) }
            : null;
        })
        .filter(Boolean);
      if (updatedBuildingQuantities.length === 0) {
        return null;
      }
      return {
        partId: item.id,
        SchoolQuantity: item.school_quantity,
        BuildingQuantity: updatedBuildingQuantities.length > 0 ? updatedBuildingQuantities : undefined
      };
    }).filter(Boolean);

    var raw = JSON.stringify({
      'SchoolID': LogInSchoolID,
      'IDArray': updatedParts,
    });

    setLoading(true);
    await ApiPostCall("/massUpdateForParts", raw).then((result) => {
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        setLoading(false);
        const responseRs = JSON.parse(result);
        if (responseRs.status == "success") {
          setNewAlerts(
            <AlertsComp show={true} variant="success" msg={"Quantity Updated Successfully."} />
          );
          setTimeout(() => {
            setNewAlerts(<AlertsComp show={false} />);
            setIsMassEditActive(false);
            GetPartData(1, null);
          }, 2000);
        } else {
          setNewAlerts(
            <AlertsComp show={true} variant="danger" msg={responseRs.status} />
          );
          setTimeout(() => {
            setNewAlerts(<AlertsComp show={false} />);
          }, 2000);
        }
      }
    });
  };
  const modalBody = (
    <div id="AddPartDiv" className="parent">
      <div className="row">
        <div className="col-md-6">
          <div className="col-12">
            <div className='FormLabel'>Part Name<label className='redText'>*</label></div>
            <div className="ps-0">
              <input type="text" autoComplete="off" name="partName" className="form-control partNamee" required
                value={formData.partName} onChange={(e) => setFormdata({ ...formData, partName: e.target.value })}
                disabled={FlagForAddUpdateDelete.Flag == 4} />
              <span className="form-text invalid-feedback" > *required </span>
            </div>
          </div>
          <div className="col-12 py-3 ">
            <div className='FormLabel'>Price<label className='redText'>*</label></div>
            <div className=" pe-0">
              <div className="input-group">
                <span className="input-group-text">$</span>
                <input type="number" autoComplete="off" className="form-control" name="variantPrice" min={0}
                  placeholder="00" value={formData.variantPrice} required onChange={(e) => setFormdata({ ...formData, variantPrice: e.target.value, })}
                  disabled={FlagForAddUpdateDelete.Flag == 4} />
                <span className="form-text invalid-feedback">*required </span>
              </div>
            </div>
          </div>
          {partsTabs.SchoolPartsTab === "active" && (
            <>
              <div className="col-12 py-3 " >
                <div className='FormLabel'>Reminder Quantity</div>
                <div className=" pe-0">
                  <input type="number" autoComplete="off" name="ReorderQuantity" className="form-control" placeholder="0" min={0}
                    value={formData.reorderQuantity} onChange={(e) => setFormdata({ ...formData, reorderQuantity: e.target.value, })} />
                </div>
              </div>
              <div className="col-12 py-3  ">
                <div className='FormLabel'>Brand Name</div>
                <div className=" pe-0">
                  <input type="text" autoComplete="off" name="brandName" className="form-control" value={formData.brandName}
                    onChange={(e) => setFormdata({ ...formData, brandName: e.target.value })} disabled={FlagForAddUpdateDelete.Flag == 4} />
                </div>
              </div>
            </>
          )}
        </div>
        <div className='col-md-6'>
          <h5 className="mb-2">Part Quantities</h5>
          <div className='p-1 greyBox data-table-container'>
            <table className="table data-table mb-0">
              <thead className='GridHeader'>
                <tr>
                  <th scope="col-6">Building</th>
                  <th scope="col-4">Quantity</th>
                </tr>
              </thead>
              <tbody>
                {Buildings?.length > 0 ?
                  Buildings?.map((item, j) => (
                    <tr className="my-1" key={j}>
                      <td className='py-1 col-6'>
                        At &nbsp; {item.name}
                      </td>
                      <td className='py-1 col-4 ps-0'>
                        <input type="number" autoComplete="off" name="Quantity"
                          className="form-control"
                          value={selectedBuildings[item.id]?.Quantity || 0}
                          onChange={(e) => handleQuantityChange(e, item.id)}
                          placeholder="0" min={0} />
                      </td>
                    </tr>
                  ))
                  :
                  <tr>
                    <td colSpan={2} className="p-3 text-center"> No Building Found </td>
                  </tr>
                }
              </tbody>
              <tfoot>
                <tr>
                  <td >
                    At &nbsp;School :
                  </td>
                  <td className="ps-0 py-1">
                    <input type="number" autoComplete="off" name="Quantity"
                      className="form-control" min={0} value={formData.totalQuantity || 0}
                      onChange={(e) => {
                        const schoolQuantity = parseInt(e.target.value) || 0;
                        setFormdata((prevFormData) => ({
                          ...prevFormData,
                          totalQuantity: schoolQuantity,
                        }));

                        const buildingTotal = Object.values(selectedBuildings).reduce((sum, building) => {
                          return sum + (parseInt(building.Quantity) || 0);
                        }, 0);

                        setTotalQuantity(buildingTotal + schoolQuantity);
                      }}
                    />
                  </td>
                </tr>
                <tr style={{ border: "transparent" }}>
                  <td className="fw-600">
                    Total  :
                  </td>
                  <td>{parseInt(totalQuantity)}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
  return (
    <Fragment>
      <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
        setCurrentPage={setCurrentPage} heading={"Manage Parts"} handleSearch={handleSearchChange}
        loading={loading} Alerts={NewAlerts} showsearch={true} />
      <div className="GridBox mt-2 p-3">
        <div className="col-12 ">
          <div className="row align-items-center">
            <div className="col-xl-6 px-0">
              <ul className="nav nav-tabs px-3">
                <Tab isActive={partsTabs.SchoolPartsTab == 'active'}
                  label="School Parts" onClick={() => handleTabClick('school')} col={'col-md-5'} />
                <Tab isActive={partsTabs.MasterPartsTab == 'active'}
                  label="Master Parts" onClick={() => handleTabClick('master')} col={'col-md-5'} />
              </ul>
            </div>
            <div className="col-xl-6 col-12 text-end">
              {partsTabs.SchoolPartsTab == "active" && (
                <>
                  {GridData.length > 0 && (
                    <label className="BorderBtn text-center" onClick={() => setIsMassEditActive(!isMassEditActive)}>
                      {isMassEditActive ? "Cancel Mass Edit" : "Mass Edit Quantities"}
                    </label>
                  )}
                  {isMassEditActive && (
                    <label className="BorderBtn text-center" onClick={handleSaveMassQtyChanges}>
                      Save Changes
                    </label>
                  )}
                  <label className="BorderBtn text-center"
                    onClick={(e) => {
                      setAddpartsPopup(true); setSelectedBuildings([]); setFlagForAddUpdateDelete({ ...FlagForAddUpdateDelete, Flag: 1 });
                      setFormdata((prevState) => ({ ...prevState, partName: "", variantPrice: "", reorderQuantity: "", brandName: "", totalQuantity: "" }));
                    }}> Add Part <img src="/images/AddInventory.svg" className="img-fluid ps-2" /> </label>
                </>
              )}
            </div>
          </div>

          <div className="col-12 greyBox mt-3 data-table-container">
            <table className="table data-table mb-0">
              <thead className="GridHeader">
                <tr>
                  {columnNames?.map((item) => {
                    const columnName = item.replace(/_/g, ' ');
                    return (
                      <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`}>
                        {Camelcase(columnName)}
                      </th>
                    );
                  })}
                  {partsTabs.SchoolPartsTab == "active" ? (
                    <>
                      <th scope="col" className=" fw-600"> Building </th>
                      <th scope="col" className=" fw-600"> Action </th>
                      <th scope="col" className=" fw-600">Reorder <img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                    </>
                  ) : (
                    <>
                      <th scope="col" className="fw-600">Add to School Part</th>
                      <th scope="col" className=" fw-600">  Order <img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /> </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {GridData.length != 0 ? (
                  GridData.map((item, i) => {
                    return (
                      <tr key={i} className={`OddEvenClass tableBorderHide `} style={{ height: "30px" }}>
                        {columnNames?.map(header => (
                          <td className="py-12" key={header} style={{ whiteSpace: 'break-spaces' }}>
                            {renderContentForColumn(header, item)}
                          </td>
                        ))}
                        {partsTabs.SchoolPartsTab == "active" ? (
                          <>
                            <td className="py-12">
                              {isMassEditActive ? (
                                <>
                                  {item.building_part.length > 0 ?
                                    item.building_part.map((building, index) => (
                                      <div key={index} className="d-flex mb-1 align-items-center">
                                        <label>{building.building_details?.name} : &nbsp;</label>
                                        <input
                                          type="number"
                                          className="form-control w-50"
                                          defaultValue={building.building_quantity}
                                          onChange={(e) =>
                                            setEditedBuildings((prev) => ({
                                              ...prev,
                                              [item.id]: {
                                                ...(prev[item.id] || {}),
                                                [building.building_id]: e.target.value,
                                              },
                                            }))
                                          }
                                        />
                                      </div>
                                    ))
                                    : "-"
                                  }
                                </>
                              ) : (
                                item.building_part.length > 0 ?
                                  item.building_part.map((building) => `${building.building_details?.name} : ${building.building_quantity}`).join(", ")
                                  : "-"
                              )}

                            </td>
                            <td className="py-12">
                              <img src="/images/EditIconNew.svg" title="Edit Part" className="img-fluid pe-2 cursor-pointer partsgetID" partid={item.id}
                                onClick={(e) => {
                                  setAddpartsPopup(true); setSelectedBuildings([]); setFlagForAddUpdateDelete({ ...FlagForAddUpdateDelete, Flag: 2, IdForDelete: item.id });
                                  GetDataById(item.id);
                                }} />
                              <img src="/images/DeleteIcon.svg" title="Delete Part" className="img-fluid cursor-pointer" partid={item.id}
                                onClick={(e) => { setDeletePopup(true); setFlagForAddUpdateDelete({ ...FlagForAddUpdateDelete, Flag: 3, IdForDelete: item.id }); }} />
                            </td>
                            {item.Stockflag == 1 ? (
                              <td className="py-12">
                                <img src="/images/Reorder.svg" className="img-fluid cursor-pointer" onClick={(e) => window.open(item.handle)} partid={item.id} />
                              </td>
                            ) : (
                              <td className="py-12">-</td>
                            )}
                          </>
                        ) : (
                          <>
                            <td className="py-12">
                              <img src="/images/PurchaseParts.svg" className="img-fluid cursor-pointer" title="Add to School Part"
                                onClick={(e) => {
                                  setAddpartsPopup(true);
                                  setSelectedBuildings([]);
                                  setFlagForAddUpdateDelete({ ...FlagForAddUpdateDelete, Flag: 4, IdForDelete: item.id });
                                  GetDataById(item.id);
                                }}
                                partid={item.id}
                              />
                            </td>
                            <td className="py-12">
                              <img src="/images/orderButton.svg" className="img-fluid cursor-pointer" partid={item.id} onClick={(e) => window.open(item.handle)} />
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr> <td colSpan={18} className="p-3 text-center"> No Record Found </td> </tr>
                )}
              </tbody>
            </table>
          </div>
          {showPagination && (
            <Pagination activePage={currentPage} totalItemsCount={pageRangeCount}
              pageRangeDisplayed={15} itemsCountPerPage={LimitForPagination} onChange={(e) => setCurrentPage(e)}
              color="primary" lastPageText="" firstPageText="" itemClassFirst="MasterClass" itemClassPrev="MasterClass"
              itemClassNext="MasterClass" itemClassLast="MasterClass" />
          )}
        </div>
      </div>

      <Popup isshow={addpartsPopup} size={"lg"} title={FlagForAddUpdateDelete.Flag == 1 ? 'Add Part' : FlagForAddUpdateDelete.Flag == 2 ? 'Edit Part' : 'Add to School Part'}
        closePopup={(e) => { setAddpartsPopup(false); setFormdata([]); setTotalQuantity(0); }}
        modalBody={modalBody} handleSave={SavePart} visibleSaveBtn={true} btnText={
          FlagForAddUpdateDelete.Flag == 1 ? 'Add Part'
            : FlagForAddUpdateDelete.Flag == 2 ? 'Update Part'
              : 'Save Part'
        } />

      <Deletepopup text={`You won't be able to revert this!`} deletePopup={deletePopup} size="xl" deletePart={SavePart} DeleteShowClose={(e) => setDeletePopup(false)} />

      {CustomizationModal == true && (
        <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Manage Parts'} GetCall={() => ApiGetHeaders(13, setColumnNames)} />
      )}
    </Fragment>

  );
}
