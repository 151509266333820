import React, { useState, useEffect } from "react";
import { MicrosoftLoginForm } from "./MicrosoftLogin";
import { RemoveAllCookies } from "../../JS/Common";
import { LazyLoadImage } from "react-lazy-load-image-component";
import GoogleOauth from "../../Components/GoogleOauth";
import { Carousel } from "react-bootstrap";

export function Login() {
  const [AlertMsgs, setAlertMsgs] = useState("");
  const [AlertColor, setAlertColor] = useState("");
  useEffect(() => {
    RemoveAllCookies();
  }, []);

  const slides = [
    {
      image: "/images/LoginSlider/Slider1.svg",
      caption: "Everything you need, all at no cost.",
    },
    {
      image: "/images/LoginSlider/Slider2.svg",
      caption: "Free Help Desk with advanced reasoning.",
    },
    {
      image: "/images/LoginSlider/Slider3.svg",
      caption: "Audit your inventory with instant reports.",
    },
    {
      image: "/images/LoginSlider/Slider4.svg",
      caption: "Create custom parental coverage plans.",
    },
    {
      image: "/images/LoginSlider/Slider5.svg",
      caption: "New ‘elevated tech’ role.",
      smallcaption: "Learn more about this feature",
    },
  ];

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <React.Fragment>
      <LazyLoadImage
        src="/images/LoginBg.png"
        alt=""
        className="MainDiv-bg-image"
      />
      <div className="row align-items-center" style={{ height: "100vh" }}>
        <div className="col-md-5 pe-0 offset-md-1 col-12 position-relative">
          <div className="p-3 m-1 text-center card-wrapper ">
            <div
              style={{ color: `${AlertColor}` }}
              className="font-16"
              dangerouslySetInnerHTML={{ __html: AlertMsgs }}
            />
            <div className="col-12 my-4 px-3">
              <img
                src="/images/K-12Logo.svg"
                className="img-fluid w-50 px-4"
                alt="Logo"
              />
            </div>
            <h2 className="fw-semibold my-3">Welcome Back!</h2>
            <label className="mb-4 font-13 text-center">
              New to Rocket?<br />
              <a
                className="ps-2 cursor-pointer"
                style={{ color: "rgb(30 191 162)" }}
                href="/register"
              >
                Create an account, it's free.
              </a>
            </label>
            <div className="col-md-10 offset-md-1 text-center mt-3">
              <GoogleOauth
                alertmsg={setAlertMsgs}
                alertcolor={setAlertColor}
                flag="signin"
              />
            </div>
            <div className="col-12 text-center py-3">
              <img src="/images/NewLoginOr.svg" className="img-fluid" />
            </div>
            <div className="col-md-10 offset-md-1 text-center">
              <MicrosoftLoginForm
                alertmsg={setAlertMsgs}
                alertcolor={setAlertColor}
              />
            </div>
            <div className="row pt-3">
              <div className="col-md-6 ps-md-5  text-center text-nowrap">
                <a
                  className="ps-2 cursor-pointer font-13"
                  style={{ color: "rgb(30 191 162)" }}
                  href="https://www.k12techrepairs.com/system-status/?utm_source=google&utm_medium=website&utm_campaign=rocket&utm_term=login-page&utm_content=system-status"
                  target="_blank"
                >
                  View System Status
                </a>
              </div>
              <div className="col-md-6 text-center pe-md-5 text-nowrap">
                <a
                  className="cursor-pointer font-13"
                  style={{ color: "rgb(30 191 162)" }}
                  href="https://k12tech.clickmeeting.com/k12tech-rocket-demo/register?utm_source=google&utm_medium=website&utm_campaign=rocket&utm_term=login-page&utm_content=demo-sign-up"
                  target="_blank"
                >
                  Join a Live Demo
                </a>
              </div>
              <div className="col-12 text-center mt-5 font-13">
                By proceeding, you agree to the <br />
                <a
                  className="cursor-pointer"
                  style={{ color: "black", textDecoration: "underline" }}
                  href="https://www.k12techrepairs.com/terms-of-service/?utm_source=google&utm_medium=website&utm_campaign=rocket&utm_term=login-page&utm_content=terms-of-service"
                  target="_blank"
                >Terms of Service</a> and &nbsp;
                <a
                  className="cursor-pointer"
                  style={{ color: "black", textDecoration: "underline" }}
                  href="https://rocket.k12techrepairs.com/privacy-policy?utm_source=google&utm_medium=website&utm_campaign=rocket&utm_term=login-page&utm_content=privacy-policy"
                  target="_blank"
                >Privacy Policy.</a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 px-0 position-relative ">
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            controls={false}
            indicators={false}
          >
            {slides.map((item, index) => (
              <Carousel.Item key={index}>
                <div className="d-flex flex-column justify-content-center  align-items-center">
                  <img src={item.image} className="img-fluid " alt={`${item.image}`} title={item.caption} />
                  <p className="m-0 p-0 fs-4 fw-semibold text-center  w-100">
                    {item.caption}
                  </p>
                  {item.smallcaption &&
                    <label>
                      {item.smallcaption}{" "}
                      <a
                        className="cursor-pointer"
                        style={{ color: "rgb(30 191 162)", textDecoration: "underline" }}
                        href="https://www.k12techrepairs.com/system-status/new-features/?utm_source=google&utm_medium=website&utm_campaign=rocket&utm_term=login-page-whats-new&utm_content=new-features"
                        target="_blank"
                      >
                        here
                      </a>
                    </label>
                  }
                </div>

              </Carousel.Item>
            ))}
          </Carousel>
          <div className="custom-indicators">
            {[0, 1, 2, 3, 4].map((idx) => (
              <span
                key={idx}
                className={`carousel-dot-css ${index === idx ? "active" : ""
                  }`}
                onClick={() => setIndex(idx)}
              />
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
