import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { getAllBuildings, LogInSchoolID } from '../../JS/Common';
import { ApiGetCall, ApiGetCallAuthKey, ApiPostCall } from '../../JS/Connector';
import { CheckValidation } from '../../Components/Validations';
import AlertsComp from '../../Components/AlertsComp';

export default function DeleteBuilding({ setLoading, isshow, closePopup, setAlerts, DeleteBuildingId, GetAllBuildings }) {
    const [jsonData, setjsonData] = useState({
        sendBuildingId: 0,
        sendRoomId: 0,
        oldRoomId: 0,
        roomhavedataflag: 0,
        newRoomId: 0,
        newBuildingId: 0,
        newRoomName: ''
    })
    const [Buildings, setBuildings] = useState([]);
    const [Rooms, setRooms] = useState([]);
    const [showsmallloader, setShowsmallloader] = useState(false);
    const [SelectedBuildingRooms, setSelectedBuildingRooms] = useState([]);
    const [BuildingRoomResponse, setBuildingRoomResponse] = useState({});
    useEffect(() => {
        getBuildingRoomDetails();
        getAllBuildings(setBuildings, LogInSchoolID);
    }, []);
    const getBuildingRoomDetails = async () => {
        await ApiGetCall("/getBuildingDetails/" + DeleteBuildingId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setRooms(responseRs.room);
                setBuildingRoomResponse(responseRs);
                setjsonData({ ...jsonData, roomhavedataflag: 0 });
                setShowsmallloader(false);
            }
        });
    }
    const getBuildingData = async (buildingid) => {
        await ApiGetCallAuthKey("/getBuildingDataById/" + buildingid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setSelectedBuildingRooms(responseRs.msg?.room);
            }
        });
    }

    const handleDeleteBuilding = async () => {
        var isFormValid = CheckValidation({ formID: 'NoRoomsDiv' });
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            oldBuilding: DeleteBuildingId.toString(),
            newBuilding: jsonData.sendBuildingId.toString(),
            schId: LogInSchoolID
        });
        await ApiPostCall("/deleteBuilding", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                closePopup();
                if (result == "success") {
                    setAlerts(
                        <AlertsComp show={true} variant="success" msg={'Building Deleted Successfully.'} />
                    );
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                        GetAllBuildings(null, null);
                    }, 2000);
                } else {
                    setAlerts(
                        <AlertsComp show={true} variant="danger" msg={result} />
                    );
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 2000);
                }
            }
        });
    }

    const handleDeleteRoom = async () => {
        if (jsonData.roomhavedataflag == 1) {
            var isFormValid = CheckValidation({ formID: 'DeleteRoomDiv' });
            if (!isFormValid) return;
        }
        setShowsmallloader(true);
        var raw = JSON.stringify({
            oldBuildingId: DeleteBuildingId,
            oldRoomId: jsonData.oldRoomId,
            newRoomId: jsonData.newRoomId,
            newBuildingId: jsonData.newBuildingId,
            flag: jsonData.roomhavedataflag,
            newRoomName: jsonData.newRoomName,
            schoolId: LogInSchoolID
        });
        await ApiPostCall("/transferRoomData", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
                setShowsmallloader(false);
            } else {
                setSelectedBuildingRooms([]);
                getBuildingRoomDetails();
            }
        });
    }
    return (
        <Modal show={isshow} size="md">
            <Modal.Header className='d-flex justify-content-between'>
                <Modal.Title>
                    <b className='font-16'>Delete Building</b>
                </Modal.Title>
                {<FontAwesomeIcon icon={faTimes} className='cursor-pointer' onClick={closePopup} />}
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    {BuildingRoomResponse?.building_have_room_flag == 1 ?
                        <div className='col-12'>
                            <div>This building contains rooms. Before deleting it, please clear the building by removing all rooms.</div>
                            <div className="greyBox mt-3 p-2 data-table-container">
                                <table className="table data-table">
                                    <thead className='GridHeader'>
                                        <tr>
                                            <th scope="col" className=' fw-600'>Existing Rooms</th>
                                            <th scope="col" className=' fw-600 text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Rooms.length != 0 &&
                                            Rooms.map((item, i) => {
                                                return <tr key={i} className="OddEvenClass tableBorderHide">
                                                    <td className="py-12" key={i}>
                                                        {item.name}
                                                    </td>
                                                    <td scope="col" className='text-center py-12'>
                                                        <img src="/images/DeleteIcon.svg" title="Delete Room"
                                                            className="img-fluid cursor-pointer"
                                                            onClick={(e) => setjsonData({
                                                                ...jsonData, oldRoomId: item.id,
                                                                roomhavedataflag: item.room_have_data_flag, newRoomName: item.name
                                                            })} />
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {jsonData.roomhavedataflag == 1 &&
                                <div className='col-12 mt-4 parent' id='DeleteRoomDiv'>
                                    <label className='text-justify font-13'>This room contains data in the system. Before deleting it, please specify the building to which you would like to transfer the room's data.</label>
                                    <div className='col-md-6 offset-md-3 py-2'>
                                        <select required onChange={(e) => {
                                            setjsonData({ ...jsonData, newBuildingId: e.target.value });
                                            getBuildingData(e.target.value);
                                        }}>
                                            <option value="0">Select Building</option>
                                            {Buildings?.filter((item) => item.id !== DeleteBuildingId).map((item, j) => (
                                                <option value={item.id} key={j}>
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="form-text invalid-feedback">
                                            *required
                                        </span>
                                    </div>
                                    {SelectedBuildingRooms?.length > 0 &&
                                        <div className='col-md-6 offset-md-3 py-2'>
                                            <select onChange={(e) => {
                                                setjsonData({ ...jsonData, newRoomId: e.target.value });
                                            }}>
                                                <option value="0">Select Room</option>
                                                {SelectedBuildingRooms?.map((item, j) => (
                                                    <option value={item.id} key={j}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    }

                                </div>
                            }
                            {jsonData.roomhavedataflag != 0 &&
                                <div className='text-center mt-4'>
                                    <label>Are you sure you want to delete this room?</label><br />
                                    <button className='mt-2 SaveBtn' onClick={handleDeleteRoom}>Delete Room</button>
                                    {showsmallloader &&
                                        <img src="/images/loder.svg" className="mx-3 text-center" style={{ width: "30px" }} />
                                    }
                                </div>
                            }
                        </div>
                        : BuildingRoomResponse?.building_have_room_flag == 2 ?
                            <div className='col-12 parent' id='NoRoomsDiv'>
                                Before deleting a building, please transfer all data to another building. Select the target building from the dropdown below.
                                <div className='col-md-6 offset-md-3 py-2'>
                                    <select required onChange={(e) => {
                                        setjsonData({ ...jsonData, sendBuildingId: e.target.value });
                                        getBuildingData(e.target.value);
                                    }}>
                                        <option value="0">Select Building</option>
                                        {Buildings?.filter((item) => item.id !== DeleteBuildingId).map((item, j) => (
                                            <option value={item.id} key={j}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                    <span className="form-text invalid-feedback">
                                        *required
                                    </span>
                                </div>
                                <div className='text-center mt-4'>
                                    <button className='SaveBtn' onClick={handleDeleteBuilding}>Delete Building</button>
                                </div>
                            </div>
                            :
                            <div className='col-12 text-center'>
                                <img src="/images/loder.svg" className="mx-1 text-center" style={{ width: "40px" }} />
                            </div>

                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}
