import { UserDetails } from "../../Components/UsersDetails";
import { useEffect, useState, useRef } from "react";
import { HideLoder, ShowLoder, getUrlParameter, isValidEmail, LogInSchoolID } from "../../JS/Common";
import { userdetailscontent } from "../ManageDevice/addupdate";
import { useNavigate } from "react-router-dom";
import AlertsComp from "../../Components/AlertsComp";
import { ApiGetCall, ApiPostCall } from "../../JS/Connector";
import Deletepopup from "../../Components/Deletepopup";
import { CheckValidation } from "../../Components/Validations";
import Popup from "../../Components/Popup";
export function UsersAddUpdate() {
    const suggestionBoxRef = useRef(null);
    const navigate = useNavigate();
    const [UpdateFlag, setUpdateFlag] = useState(1);
    const [Alerts, setAlerts] = useState("");
    const [DeviceDetails, setDeviceDetails] = useState([]);
    const [devicedeletepopup, setdevicedeletepopup] = useState(false);
    const [AttachDevicepopup, setAttachDevicepopup] = useState(false);
    const [DeleteDeviceId, setDeleteDeviceId] = useState("");
    const [SuggestionBoxArray, setSuggestionBoxArray] = useState([]);
    const [AttachDevice, setAttachDevice] = useState({
        smallloder: "d-none",
        devicesearchstring: "",
        AttachDevices: [],
        UserID: 0,
        blankrow: ""
    });
    const [AttachDeviceSuggestion, setAttachDeviceSuggestion] = useState('d-none');
    const [inputs, setInputs] = useState([{ id: "", value: "", showSuggestion: "d-none" }]);
    const [allData, setAllData] = useState({
        FirstName: "",
        MiddleName: '',
        LastName: "",
        StudentNumber: "",
        ParentEmail: "",
        UserEmail: "",
        ParentName: "",
        ParentPhoneNumber: "",
        ParentCheck: 0,
        emailError: "",
        Grade: "",
        Department: "",
        IsStudent: 0,
        policyflag: 0
    });
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        GetUserData();
        const handleClickOutside = (event) => {
            if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
                setAttachDeviceSuggestion('d-none');
            }
        };
        const handleWindowClick = (event) => {
            handleClickOutside(event);
        };
        window.addEventListener('click', handleWindowClick);
        return () => {
            window.removeEventListener('click', handleWindowClick);
        };
    }, []);
    async function GetUserData() {
        var userid = getUrlParameter("id");
        if (userid != false) {
            setUpdateFlag(2);
            await ApiGetCall("/UtilizerDetailsById/" + userid).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setDeviceDetails(responseRs.allocatedDevice);
                    var Userdata = responseRs.msg;
                    setAllData({
                        ...allData, FirstName: Userdata.device_user_first_name, MiddleName: Userdata.device_user_middle_name,
                        LastName: Userdata.device_user_last_name, StudentNumber: Userdata.student_num, ParentEmail: Userdata.parent_guardian_email,
                        ParentName: Userdata.parent_guardian_name, Grade: Userdata.grade, Department: Userdata.department,
                        ParentPhoneNumber: Userdata.parent_phone_number, ParentCheck: Userdata.parental_coverage, IsStudent: Userdata.is_student,
                        policyflag: responseRs?.plandetails?.policy_agree, UserEmail: Userdata?.student_email
                    })
                }
            });
        }
    }
    async function AddUpdateUsers() {
        var userid = getUrlParameter("id");
        if (userid == false) {
            userid = 0;
        }
        var isFormValid = CheckValidation({ formID: 'AddUpdateUserForm' });
        let errors = {};
        if (allData.ParentEmail == null) {
            allData.ParentEmail = "";
        }
        if (allData.ParentEmail !== "") {
            if (!isValidEmail(allData.ParentEmail)) {
                errors.emailError = "*Invalid Email";
                isFormValid = false;
            } else {
                errors.emailError = "";
            }
        }
        setAllData({ ...allData, ...errors });
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            firstname: allData.FirstName,
            middlename: allData.MiddleName,
            lastname: allData.LastName,
            Grade: allData.Grade,
            Department: allData.Department,
            Studentnum: allData.StudentNumber,
            Parentname: allData.ParentName,
            Parentemail: allData.ParentEmail,
            Parentcoverage: allData.ParentCheck,
            Parentnum: allData.ParentPhoneNumber,
            Schoolid: LogInSchoolID,
            IsStudent: allData.IsStudent,
            Studentemail: allData.UserEmail,
            ID: userid
        });
        await ApiPostCall("/AddUpdateUtilizer", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                if (responseRs.status == "success") {
                    var msg = UpdateFlag == 1 ? 'User Added Successfully.' : 'User Updated Successfully.';
                    setAlerts(<AlertsComp show={true} variant="success" msg={msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                        navigate("/users");
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                }
            }
        });
    }
    async function GetAllDevices(searchstring) {
        setLoading(true)
        if (searchstring == "") {
            searchstring = null;
        }
        await ApiGetCall("/allActiveDevice/" + LogInSchoolID + "&" + searchstring).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                var sugData = responseRs.msg;
                setLoading(false)
                if (responseRs.response == "success") {
                    setAttachDeviceSuggestion("");
                    let vArray = [];
                    for (var i = 0; i < sugData.length; i++) {
                        if (sugData[i].flag !== "assigned") {
                            const existingItem = inputs.find((input) => input.id == sugData[i].id);
                            var html = (
                                <div className="col-12 p-2 brdr-Btm text-start" onClick={(e) => handleInputChange(e)} deviceid={sugData[i].id} serialnumber={sugData[i].serial_number}>
                                    {sugData[i].serial_number}
                                </div>
                            );
                            if (!existingItem) {
                                vArray.push(html);
                            }
                        }
                    }
                    if (vArray.length === 0) {
                        var vhtml = (
                            <div className="brdr-Btm font-14 text-center" style={{ padding: "8px 15px" }}>
                                No Record Found
                            </div>
                        );
                        vArray.push(vhtml);
                    }
                    setSuggestionBoxArray(vArray);
                }


                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                }
            }
        });
    }
    const handleInputChange = (e) => {
        var id = e.currentTarget.attributes[1].value;
        var value = e.currentTarget.attributes[2].value;
        setAttachDeviceSuggestion('d-none');
        const lastWithValueIndex = inputs.reduceRight((acc, input, index) => {
            if (acc === -1 && input.value !== "") {
                return index;
            }
            return acc;
        }, -1);
        setInputs((inputs) => [
            ...inputs.slice(0, lastWithValueIndex + 1),
            { id: `${id}`, value },
            ...inputs.slice(lastWithValueIndex + 1),
        ]);
        setAttachDevice({ ...AttachDevice, blankrow: "" })
        setAttachDeviceSuggestion('d-none');
    };
    async function SaveAttachDevice() {

        var DeviceAllocateArray = [];
        inputs.map((item) => {
            var vjson = { userid: getUrlParameter("id"), deviceid: item.id };
            if (item.id != '') {
                DeviceAllocateArray.push(vjson);
            }
        });
        if (DeviceAllocateArray.length == 0) {
            setAttachDevice({ ...AttachDevice, blankrow: "Select at least one" })
            return
        }
        setLoading(true);
        var raw = JSON.stringify({
            Flag: 1,
            DeviceArray: DeviceAllocateArray,
            Schoolid: LogInSchoolID,
        });
        await ApiPostCall("/DeviceAllocationToUSer", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                setAttachDevicepopup(false);
                if (responseRs.response == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Device Allocated Successfully."} />);
                    GetUserData();
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.response} />);
                }
            }
        });
    }
    function RemoveDevice(attachdeviceid) {
        const index = inputs.findIndex((item) => item.id === attachdeviceid);
        if (index !== -1) {
            if (inputs[index].id != '') {
                inputs.splice(index, 1);
                setInputs([...inputs]); // Update state with the modified array
            } else {
                setAttachDevice({ ...AttachDevice, blankrow: "You can not delete blank row" })
            }
        }
    }
    async function DeleteDevice() {
        setLoading(true);
        var DeviceAllocateArray = [];
        var vjson = { userid: 0, deviceid: DeleteDeviceId };
        DeviceAllocateArray.push(vjson);
        var raw = JSON.stringify({
            Flag: 2,
            DeviceArray: DeviceAllocateArray,
            Schoolid: LogInSchoolID,
        });
        await ApiPostCall("/DeviceAllocationToUSer", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                setdevicedeletepopup(false);
                if (responseRs.response == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Device Deleted Succesfully"} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                        GetUserData();
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    const modalbody = (
        <div className="text-center">
            <div className="col-md-12 font-14 text-start">
                Search Device by serial number, device model.
            </div>
            {inputs.map((input, index) => (
                <div className="row pb-2 pt-4 align-items-center" key={index}>
                    <div className="col-md-11 col-10 row align-items-center ps-3">
                        <form className="gridsearchbar" key={input.id} onSubmit={(e) => e.preventDefault()}>
                            <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
                            <input className="form-control" autoComplete="off" type="text" placeholder="Search Device"
                                onKeyUp={(e) => { setSuggestionBoxArray([]); GetAllDevices(e.target.value) }} defaultValue={input.value} />
                            <div className={`SuggestionBoxDiv ${input.showSuggestion ? '' : 'd-none'} ${AttachDeviceSuggestion}`} ref={suggestionBoxRef}>
                                {SuggestionBoxArray}
                            </div>
                        </form>
                    </div>
                    <div className="col-md-1 col-2">
                        <img src="/images/CancelIcon.svg" title="Delete Device" className="img-fluid cursor-pointer" onClick={(e) => RemoveDevice(input.id)} />
                    </div>
                </div>
            ))}
            <span className="redText">
                {AttachDevice.blankrow}
            </span>

        </div>
    )
    return (
        <>
            <userdetailscontent.Provider value={{ allData, setAllData }}>
                {Alerts}
                {loading ? <ShowLoder /> : <HideLoder />}
                <div className="GridBox mt-2 p-3">
                    <div className="greyBox row">
                        <div className='Header col-8 pb-2'>
                            {UpdateFlag == 1 ?
                                <b className='font-16'>Add New User</b>
                                :
                                <b className='font-16'>Edit User</b>
                            }
                        </div>
                        <div className="col-4 text-end">
                            {UpdateFlag == 2 && <label>Fair Use Acknowledgment : <b>{allData.policyflag == 1 ? 'Yes' : 'No'}</b></label>}
                        </div>
                        <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
                        <div id="AddUpdateUserForm" className="parent">
                            <UserDetails />
                        </div>
                        {/* save cancel button div */}
                        <div className='col-12 text-center pt-4'>
                            {UpdateFlag == 1 ?
                                <button className='SaveBtn' onClick={(e) => AddUpdateUsers(1)}>Save User</button>
                                :
                                <button className='SaveBtn' onClick={(e) => AddUpdateUsers(2)}>Update User</button>
                            }
                            <label className="ms-2 cursor-pointer" onClick={() => navigate("/users")}><u>Cancel</u></label>
                        </div>

                        {/* Manage Device */}
                        {UpdateFlag == 2 && (
                            <div className="GridBox p-3 mt-4">
                                <div className="row col-12 pe-0 px-2 align-items-center">
                                    <div className="col-md-8 d-flex align-items-center">
                                        <h5 className="fw-600">Manage Device</h5>
                                    </div>
                                    <div className="col-md-4 px-0 text-center d-flex justify-content-end align-items-end">
                                        <label className="BorderBtn ms-3 text-center" onClick={(e) => { setAttachDevicepopup(true); setSuggestionBoxArray([]); setAttachDevice({ ...AttachDevice, blankrow: '' }); setAttachDeviceSuggestion('d-none'); setInputs([{ id: "", value: "", showSuggestion: "d-none" }]) }}>
                                            Attach a new Device <img src="/images/AddInventory.svg" className="img-fluid ps-2" />
                                        </label>
                                    </div>
                                </div>
                                <img src="/images/HorizontalLine.svg" className="img-fluid w-100" />
                                {DeviceDetails.length != 0 ?
                                    DeviceDetails.map((item, i) => {
                                        return (
                                            <div className="row px-2  pb-2 font-14" key={i}>
                                                <div className="col-md-11">
                                                    <div className="row" style={{ fontSize: "13px" }}>
                                                        <div className="col-md-4 align-items-center  pt-2">
                                                            <div className="row">
                                                                <div className="col-md-5 col-8" > Serial Number:  </div>
                                                                <div className="col-md-7 col-4 text-md-start text-center"> <b>{item.serial_number}</b> </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 align-items-center  pt-2">
                                                            <div className="row">
                                                                <div className="col-md-5 col-8"> Device Model: </div>
                                                                <div className="col-md-7 col-4 text-md-start text-center"> <b>{item.device_model ? item.device_model : <>-</>}</b> </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 align-items-center  pt-2">
                                                            <div className="row">
                                                                <div className="col-md-7 col-8">  Device Manufacturer: </div>
                                                                <div className="col-md-4 col-4 text-md-start text-center"> <b>{item.device_manufacturer ? item.device_manufacturer : <>-</>}</b> </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4  align-items-center pt-2">
                                                            <div className="row">
                                                                <div className="col-md-5 col-8"> Device Type: </div>
                                                                <div className="col-md-7 col-4 text-md-start text-center"> <b>{item.device_type ? item.device_type : <>-</>}</b> </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4  align-items-center  pt-2">
                                                            <div className="row">
                                                                <div className="col-md-5 col-8"> Device MPN:  </div>
                                                                <div className="col-md-7 col-4 text-md-start text-center"> <b>{item.device_mpn ? item.device_mpn : <>-</>}</b></div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4  align-items-center  pt-2">
                                                            <div className="row">
                                                                <div className="col-md-7 col-8">   Asset Tag: </div>
                                                                <div className="col-md-4 col-4 text-md-start text-center"> <b>{item.asset_tag ? item.asset_tag : <>-</>}</b>  </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4  align-items-center pt-2">
                                                            <div className="row">
                                                                <div className="col-md-5 col-8">  Repair Cap:   </div>
                                                                <div className="col-md-7 col-4 text-md-start text-center"> <b>{item.repair_cap ? item.repair_cap : <>-</>}</b> </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4  align-items-center pt-2">
                                                            <div className="row">
                                                                <div className="col-md-5 col-8"> Device Os: </div>
                                                                <div className="col-md-7 col-4 text-md-start text-center">  <b>{item.device_os ? item.device_os : <>-</>}</b> </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12  align-items-center pt-2">
                                                            <div className="row">
                                                                <div className="col-md-1 col-8"> Notes: </div>
                                                                <div className="col-md-11 col-4 text-md-start text-center">  <b>{item.notes ? item.notes : <>-</>}</b> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-1 pb-2 px-2">
                                                    <div className="row text-md-center text-end">
                                                        <div className="mt-3 pe-lg-0 pe-4">
                                                            <img src="/images/DeleteIcon.svg" title="Delete Device" className="cursor-pointer mb-3" onClick={(e) => { setDeleteDeviceId(item.id); setdevicedeletepopup(true) }} deviceid={item.id} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <img src="/images/HorizontalLine.svg" className="img-fluid w-100 pt-2" />
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="col-12 text-center p-2">  No Record Found  </div>
                                }
                            </div>
                        )}
                    </div>
                </div>
                <Deletepopup text={`You won't be able to revert this!`} deletePopup={devicedeletepopup} deletePart={(e) => DeleteDevice()} DeleteShowClose={(e) => setdevicedeletepopup(false)} />

                <Popup isshow={AttachDevicepopup} size={"md"} title={"Attach a new Device"}
                    closePopup={(e) => { setAttachDevicepopup(false); setSuggestionBoxArray([]); setAttachDevice({ ...AttachDevice, blankrow: '' }); setAttachDeviceSuggestion("d-none"); setInputs([{ id: "", value: "", showSuggestion: "d-none" }]) }}
                    modalBody={modalbody} handleSave={SaveAttachDevice} visibleSaveBtn={true} btnText={"Attach Device"} />
            </userdetailscontent.Provider >
        </>
    )
}
