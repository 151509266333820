import React, { useEffect, useState } from 'react'
import MassTickets from './MassTickets';
import MassDevices from './MassDevices';
import MassUsers from './MassUsers';
import MassTechnician from './MassTechnician';
import MassIncomingBatch from './MassIncomingBatch';
import MassBillingBatch from './MassBillingBatch';
import ReactDatePicker from 'react-datepicker';
import { ApiGetCall } from '../../JS/Connector';
import { HideLoder, ShowLoder } from '../../JS/Common';
import AllRocketAccounts from './AllRocketAccounts';
import AllRocketUsers from './AllRocketUsers';
import AllHelpdeskTickets from './AllHelpdeskTickets';

export default function MassDownload() {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);
    const [loading, setLoading] = useState(false);
    const [alerts, setalerts] = useState("");
    const [Locations, setLocations] = useState([]);
    const [Schools, setSchools] = useState([]);
    const [downloadAllChecked, setDownloadAllChecked] = useState(false);
    const [FilterData, setFilterData] = useState({
        StartDate: oneMonthAgo,
        EndDate: today,
        Location: null,
        School: null
    })
    useEffect(() => {
        GetSchools();
    }, []);
    function GetSchools() {
        ApiGetCall("/GetAllSchoolsByLocation").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs?.allLocation?.length != 0) {
                    setLocations(responseRs?.allLocation)
                    setSchools(responseRs?.allSchool)
                }
            }
        });
    }
    function handleStartDateChange(date) {
        setFilterData((prevdata) => ({ ...prevdata, StartDate: date }));
    };
    function handleEndDateChange(date) {
        setFilterData((prevdata) => ({ ...prevdata, EndDate: date }));
    };
    const handleLocationValue = (val) => {
        setFilterData((prevdata) => ({ ...prevdata, Location: val }));
        if (val == "0") {
            GetSchools()
        } else {
            var filterSchoolByLocation = Locations?.filter((data) => data?.id == parseInt(val))
            setSchools(filterSchoolByLocation[0]?.school)
        }
    }
    return (
        <div>
            {alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row col-12 pe-0 align-items-center pb-3'>
                <div className='col-md-4'>
                    <h1 className="PageHeading">Bulk Reports Download</h1>
                </div>
                <div className="col-md-4 text-end row px-0">
                    <div className="col-md-6 d-flex  align-items-center py-2 px-0">
                        <label className="px-3"></label>
                        <ReactDatePicker
                            className="form-control me-4 text-center"
                            selected={FilterData.StartDate}
                            onChange={(e) => { handleStartDateChange(e); }}
                            dateFormat="MM/dd/yyyy"
                        />
                    </div>
                    <div className="col-md-6 d-flex  align-items-center py-2 px-0">
                        <label className="px-3">To</label>
                        <ReactDatePicker
                            className="form-control me-4 text-center"
                            selected={FilterData.EndDate}
                            onChange={(e) => { handleEndDateChange(e); }}
                            dateFormat="MM/dd/yyyy"
                            maxDate={today}
                        />
                    </div>
                </div>
                <div className="col-md-2 ms-2 py-1 pe-0">
                    <select value={FilterData.Location}
                        onChange={(e) => { handleLocationValue(e.target.value); }}>
                        <option value="0">Filter by Location</option>
                        {Locations.map((locationItem, j) => (
                            <option value={locationItem.id} key={j}>
                                {locationItem.locationName}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-2 ms-2 py-1 pe-0">
                    <select value={FilterData.School}
                        onChange={(e) =>
                            setFilterData((prevdata) => ({ ...prevdata, School: e.target.value == 0 ? null : e.target.value }))}>
                        <option value="0">Filter by School</option>
                        {Schools?.length == 0 && <optgroup label="No school found"></optgroup>}
                        {Schools?.map((item, j) => (
                            <option value={item.id} key={j}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='col-12 my-1 text-end'>
                    <input type="checkbox" class="form-check-input me-2" name="downloadall"
                        defaultChecked={downloadAllChecked} onChange={(e) => setDownloadAllChecked(e.target.checked)} />
                    <label>Download All Data</label>
                </div>
            </div>
            <div className="GridBox p-4">
                <div className='row'>
                    <div className='col-md-6'>
                        <MassTickets setLoading={setLoading} FilterData={FilterData} setalerts={setalerts} downloadAllChecked={downloadAllChecked} />
                    </div>
                    <div className='col-md-6'>
                        <MassDevices setLoading={setLoading} FilterData={FilterData} setalerts={setalerts} downloadAllChecked={downloadAllChecked} />
                    </div>
                    <div className='col-md-6 mt-3'>
                        <MassUsers setLoading={setLoading} FilterData={FilterData} setalerts={setalerts} downloadAllChecked={downloadAllChecked} />
                    </div>
                    <div className='col-md-6 mt-3'>
                        <MassTechnician setLoading={setLoading} FilterData={FilterData} setalerts={setalerts} downloadAllChecked={downloadAllChecked} />
                    </div>
                    <div className='col-md-6 mt-3'>
                        <MassIncomingBatch setLoading={setLoading} FilterData={FilterData} setalerts={setalerts} downloadAllChecked={downloadAllChecked} />
                    </div>
                    <div className='col-md-6 mt-3'>
                        <MassBillingBatch setLoading={setLoading} FilterData={FilterData} setalerts={setalerts} downloadAllChecked={downloadAllChecked} />
                    </div>
                    <div className='col-md-6 mt-3'>
                        <AllRocketAccounts setLoading={setLoading} FilterData={FilterData} setalerts={setalerts} downloadAllChecked={downloadAllChecked} />
                    </div>
                    <div className='col-md-6 mt-3'>
                        <AllRocketUsers setLoading={setLoading} FilterData={FilterData} setalerts={setalerts} downloadAllChecked={downloadAllChecked} />
                    </div>
                    <div className='col-md-6 mt-3'>
                        <AllHelpdeskTickets setLoading={setLoading} FilterData={FilterData} setalerts={setalerts} downloadAllChecked={downloadAllChecked} />
                    </div>
                </div>
            </div>
        </div>
    )
}
