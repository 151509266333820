import React, { useContext, useEffect, useRef, useState } from 'react'
import { CheckValidation } from './Validations';
import { HideLoder, LogInSchoolID, LogInUserID, getpostalcodes, ShowLoder } from '../JS/Common';
import { UserContext } from '../App';
import { ApiGetCall, ApiPostCall } from '../JS/Connector';
import AlertsComp from './AlertsComp';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import SignatureCanvas from "react-signature-canvas";
import Popup from './Popup';
function CreateShippingCom(props) {
    const [showcustominputs, setshowcustominputs] = useState("d-none");
    const [WeightError, setWeightError] = useState("");
    const [phonenoError, setphonenoError] = useState("");
    const [AllState, setallstate] = useState([]);
    const [showpackingtypelist, setshowpackingtypelist] = useState("d-none");
    const [ValidateError, setValidateError] = useState('');
    const [ValidateColor, setValidateColor] = useState('');
    const [shipmentButton, setshipmentButton] = useState(true);
    const [YourAddressDiv, setYourAddressDiv] = useState('d-none');
    const [YourAddressDefaultDiv, setYourAddressDefaultDiv] = useState('d-none');
    const [BatchData, setBatchData] = useState({
        batchname: "",
        notes: "",
        createbatchflag: 1,
        Phoneno: "",
        Street1: "",
        Street2: '',
        city: "",
        state: 0,
        postalcode: "",
        labelsize: 0,
        Weight: "",
        boxsize: "",
        width: "",
        height: "",
        depth: "",
        fedex_company_name: ""
    })
    const [deliveryaddress, setdeliveryaddress] = useState({
        streetline1: "",
        streetline2: "",
        city: "",
        stateOrProvinceCode: "",
        postalCode: "",
        recipientsname: "",
        recipientsphone: "",
        companyname: ""
    });
    const [loading, setLoading] = useState(false);
    const [Alerts, setAlerts] = useState("");
    const { UserData } = useContext(UserContext);
    const accountnumber = process.env.REACT_APP_Fedex_Accountnumber;
    const navigate = useNavigate();
    const [commonDivShow, setCommonDivShow] = useState(false)
    const [batchTicketArray, setBatchTicketArray] = useState([])
    const schoolDetail = Cookies.get('ShippingType')
    const [isChecked, setIsChecked] = useState(false);
    const [SignatureDataForPackedBy, setSignatureDataForPackedBy] = useState('')
    const sigCanvasForPackedBy = useRef({});

    useEffect(() => {
        setshowpackingtypelist("d-none");
        setshowcustominputs("d-none");
        if (props.shippingFlag == 1) {
            setBatchData({ ...BatchData, depth: "", width: "", height: "" });
        } else {
            setIsChecked(true)
            setCommonDivShow(true)
            setBatchData({ ...BatchData, createbatchflag: 2, depth: "", width: "", height: "" });
            ShowTicketDataForBatch()
            setshipmentButton(false)
        }
        GetAddress();
        getpostalcodes(setallstate);
    }, [])
    async function ShowTicketDataForBatch() {
        await ApiGetCall("/getSchoolBatchData/" + props.batchid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.PackerSignature) {
                    setSignatureDataForPackedBy(responseRs.PackerSignature)
                }
                if (responseRs.ticket.length != 0) {
                    setBatchTicketArray(responseRs.ticket)
                } else {
                    setBatchTicketArray([]);
                }
            }
        });
    }
    async function GetAddress() {
        const now = new Date();
        const today = new Date().toISOString().substring(0, 10);
        const time = now.toLocaleTimeString("en-US", { hour12: false }).replace(/:/g, "");
        const batchName = `batch_${today}_${time}`;
        await ApiGetCall("/getSchoolAddress/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                var delieveryaddress = responseRs.adminAddress;
                var shippingaddress = responseRs.schoolAddress;
                Cookies.set('ShippingType', responseRs?.schoolDetail?.shipping_type, { expires: 1, secure: true, sameSite: 'Strict' });
                if (delieveryaddress != null) {
                    setdeliveryaddress({
                        ...deliveryaddress, streetline1: delieveryaddress.street_line_1, streetline2: delieveryaddress.street_line_2, city: delieveryaddress.city, stateOrProvinceCode: delieveryaddress.state_or_province_code,
                        postalCode: delieveryaddress.postal_code, recipientsname: delieveryaddress.contact_name, recipientsphone: delieveryaddress.contact_number,
                        companyname: responseRs?.schoolDetail?.name
                    })
                }
                if (shippingaddress == "null") {
                    setYourAddressDiv('')
                    setYourAddressDefaultDiv('d-none')
                    setBatchData({ ...BatchData, batchname: batchName, notes: "", createbatchflag: 1, Phoneno: "", Street1: "", Street2: "", city: "", state: 0, postalcode: "", labelsize: 0, Weight: "", fedex_company_name: responseRs?.schoolDetail?.name })
                } else {
                    setYourAddressDiv('d-none')
                    setYourAddressDefaultDiv('')
                    setBatchData({
                        ...BatchData, batchname: batchName, notes: "", createbatchflag: 1, Phoneno: shippingaddress.phone_num, Street1: shippingaddress.street_line, Street2: shippingaddress.street_line2,
                        city: shippingaddress.city, state: shippingaddress.state_or_province_code,
                        postalcode: shippingaddress.postal_code,
                        fedex_company_name: shippingaddress.fedex_company_name || responseRs?.schoolDetail?.name
                    })
                }
            }
        });
    }
    async function SaveCreateBatch(flag) {
        var isFormValid = CheckValidation({ formID: 'CreateBatchDiv' });
        if (BatchData.createbatchflag != 1) {
            if (BatchData.Phoneno != "") {
                if (BatchData.Phoneno.length > 11) {
                    setphonenoError("Invalid Phone No.");
                    isFormValid = false;
                } else {
                    setphonenoError("");
                }
            }
            if (BatchData.Weight > 55) {
                setWeightError("Invalid Value");
                isFormValid = false;
            } else {
                setWeightError("");
            }
        }
        if (!isFormValid) return;
        setLoading(true);
        var signatureBase64 = null;
        if (UserData.signatureFlag == 1) {
            if (SignatureDataForPackedBy == '') {
                signatureBase64 = sigCanvasForPackedBy.current.isEmpty() ? null : sigCanvasForPackedBy.current.getTrimmedCanvas().toDataURL("image/png");
            } else {
                signatureBase64 = SignatureDataForPackedBy;
            }
        } else {
            signatureBase64 = null
        }
        const shipperData = {
            "contact": {
                "personName": UserData.UserName,
                "phoneNumber": parseInt(BatchData.Phoneno),
                "companyName": BatchData.fedex_company_name
            },
            "address": {
                "streetLines": [
                    ...(BatchData.Street1 + (BatchData.Street2 ? ', ' + BatchData.Street2 : '')).split(', ')
                ],
                "city": BatchData.city,
                "stateOrProvinceCode": BatchData.state,
                "postalCode": BatchData.postalcode,
                "countryCode": "US"
            }
        };

        const recipientData = {
            "contact": {
                "personName": deliveryaddress.recipientsname,
                "phoneNumber": deliveryaddress.recipientsphone,
                "companyName": "K-12 Repair Tech"
            },
            "address": {
                "streetLines": [
                    ...(deliveryaddress.streetline1 + (deliveryaddress.streetline2 ? ', ' + deliveryaddress.streetline2 : '')).split(', ')
                ],
                "city": deliveryaddress.city,
                "stateOrProvinceCode": deliveryaddress.stateOrProvinceCode,
                "postalCode": deliveryaddress.postalCode,
                "countryCode": "US"
            }
        };

        const shipmentData = {
            "serviceType": "FEDEX_GROUND",
            "packagingType": 'YOUR_PACKAGING',
            "pickupType": "USE_SCHEDULED_PICKUP",
            "blockInsightVisibility": false,
            "shippingChargesPayment": {
                "paymentType": "SENDER"
            },
            "labelSpecification": {
                "imageType": "PNG",
                "labelStockType": BatchData.labelsize,
                "labelRotation": "NONE"
            },
            "requestedPackageLineItems": [
                {
                    "weight": {
                        "units": "LB",
                        "value": parseInt(BatchData.Weight)
                    },
                    "dimensions": {
                        "length": parseInt(BatchData.depth),
                        "width": parseInt(BatchData.width),
                        "height": parseInt(BatchData.height),
                        "units": "IN"
                    }
                }
            ]
        };
        const shippingMethod = props.shippingFlag == 1 ? 0 : 1;
        const shippingrequest = {
            "labelResponseOptions": "URL_ONLY",
            "requestedShipment": {
                "shipper": shipperData,
                "recipients": [recipientData],
                ...shipmentData
            },
            "accountNumber": {
                "value": accountnumber
            },
            "BatchName": BatchData.batchname,
            "Notes": BatchData.notes,
            "SchoolId": LogInSchoolID,
            "TicketArray": props.shippingFlag == 1 ? props.SelectedItems : batchTicketArray,
            "BatchFlag": props.shippingFlag == 1 ? BatchData.createbatchflag : 2,
            "ShippingMethod": shippingMethod,
            "Weight": BatchData.Weight,
            "PackerUserId": LogInUserID,
            "BatchPackerSignature": signatureBase64,
            "SchoolBatchID": props.shippingFlag == 1 ? null : props.batchid
        };
        var raw = JSON.stringify(shippingrequest);
        if (flag == 2) {
            if (props.shippingFlag == 1) {
                await ApiPostCall("/saveSchoolBatches", raw).then((result) => {
                    if (result == undefined || result == "") {
                        alert("Something went wrong");
                    } else {
                        var tempuser = props.AllShipDevices.map(item => { return { ...item, isChecked: false } });
                        props.setAllShipDevices(tempuser);
                        const responseRs = JSON.parse(result);
                        if (responseRs.status == "success") {
                            setAlerts(<AlertsComp show={true} variant="success" msg={"Batch Created Successfully."} />);
                            setTimeout(() => {
                                setLoading(false);
                                setBatchData({ ...BatchData, batchname: '', notes: '' })
                                setAlerts(<AlertsComp show={false} />)
                                navigate("/outgoing-batches");
                            }, 2500);
                        }
                        else {
                            setAlerts(<AlertsComp show={true} variant="danger" msg={"Please ensure the accuracy of your data for FedEx delivery."} />);
                            props.GetTickets(1, null, null);
                            setTimeout(() => {
                                props.setSelectedIdsAll([]);
                                props.setSelectedIdsOpen([]);
                                props.ActiveTab()
                                setAlerts(<AlertsComp show={false} />)
                            }, 3500);
                        }
                    }
                });
            }
            else {
                await ApiPostCall("/normalBatchtoFedex", raw).then((result) => {
                    if (result == undefined || result == "") {
                        alert("Something went wrong");
                    } else {
                        const responseRs = JSON.parse(result);
                        if (responseRs.status == "success") {
                            setAlerts(<AlertsComp show={true} variant="success" msg={"Batch Created Successfully."} />);
                            setTimeout(() => {
                                setLoading(false);
                                props.ClosePopup();
                                setAlerts(<AlertsComp show={false} />)
                                props.AllBatches(null, null);
                            }, 3500);
                        }
                        else {
                            setAlerts(<AlertsComp show={true} variant="danger" msg={"Please ensure the accuracy of your data for FedEx delivery."} />);
                            setTimeout(() => {
                                setAlerts(<AlertsComp show={false} />)
                            }, 3500);
                        }
                    }
                });
            }
        } else {
            await ApiPostCall("/validateShipment", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    if (responseRs.status == "success") {
                        setshipmentButton(true)
                        setValidateError('Your Shipping details are valid. You can go ahead and click on create shipping.')
                        setValidateColor('greenText')
                    }
                    else {
                        setValidateError(responseRs.msg);
                        setshipmentButton(false)
                        setValidateColor('redText')
                    }
                }
            });
        }
    }
    async function ChangeDeviceType(devicetypevalue) {
        var vArray = [];
        {
            batchTicketArray.map((item, i) => {
                var vjson = {};
                vjson['id'] = item.id;
                vArray.push(vjson)
            })
        }
        if (devicetypevalue != 4 && devicetypevalue != 0) {
            var raw = JSON.stringify({
                schoolId: LogInSchoolID,
                DeviceType: devicetypevalue,
                TicketArray: props.shippingFlag == 1 ? props.SelectedItems : vArray
            });
            await ApiPostCall("/calculateTheBatchWeight", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setshowpackingtypelist("");
                    setBatchData({ ...BatchData, Weight: responseRs });
                }
            });
        } else {
            setshowpackingtypelist("");
            setBatchData({ ...BatchData, Weight: "" });
        }
    }
    function addcustominputs(selectedid) {
        var vVal = selectedid.split('-');
        if (selectedid == "custom") {
            setBatchData({ ...BatchData, depth: "", width: "", height: "" });
            setshowcustominputs("");
        } else {
            setBatchData({ ...BatchData, depth: vVal[0], width: vVal[1], height: vVal[2] });
            setshowcustominputs("d-none");
        }
    }
    function handlecheckboxchange(e) {
        if (e.target.checked == true) {
            setIsChecked(true)
            setBatchData({ ...BatchData, createbatchflag: 2 })
            setCommonDivShow(true)
            setshipmentButton(false)
        } else {
            setIsChecked(false)
            setBatchData({ ...BatchData, createbatchflag: 1 })
            setCommonDivShow(false)
            setshipmentButton(true)
        }
    }
    const clearSignatureForPackedBy = () => {
        sigCanvasForPackedBy.current.clear();
    };
    const modalbody = (
        <div id="CreateBatchDiv" className="px-2 parent">
            <div className="row">
                <div className='col-md-6'>
                    <div className="row ps-3 pt-3 align-items-center">
                        <div className='col-12 ps-0'>
                            <div className='FormLabel'>Batch Name:<label className='redText'>*</label></div>
                        </div>
                        {props.shippingFlag == 1 ?
                            <div className="col-12 ps-0">
                                <input type="text" autoComplete='off' name='batchname' className="form-control" required value={BatchData.batchname} onChange={(e) => setBatchData({ ...BatchData, batchname: e.target.value })} />
                                <span className="form-text invalid-feedback" >
                                    *required
                                </span>
                            </div>
                            :
                            <div className="col-12 ps-0">
                                <label className='col-md-12'><b>{props.batchname}</b></label>
                            </div>
                        }
                    </div>
                    <div className="row ps-3 align-items-center  pt-3">
                        <div className='col-12 ps-0'>
                            <div className='FormLabel'>Notes:</div>
                        </div>
                        <div className="col-12 ps-0 pt-2"><textarea type="text" name='notes' rows={2} className="form-control" value={BatchData.notes} onChange={(e) => setBatchData({ ...BatchData, notes: e.target.value })} /></div>
                    </div>
                </div>
                <div className='col-md-6'>
                    {UserData.signatureFlag == 1 && (
                        <div className="col-12 py-1 mt-2 text-center">
                            <div className="greyBox mx-2">
                                <div className='fw-600 font-15 text-center'>Packed By Signature:</div>
                                <div className='mt-2 text-center'>
                                    {SignatureDataForPackedBy ? (
                                        <img src={SignatureDataForPackedBy} alt="Packed By Signature" />
                                    ) : (
                                        <SignatureCanvas
                                            ref={sigCanvasForPackedBy}
                                            canvasProps={{ width: 320, height: 100, className: "signatureCanvas" }}
                                        />
                                    )}
                                </div>
                                <div className="text-end"> <label className="cursor-pointer" onClick={clearSignatureForPackedBy}><u>Clear</u></label></div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {schoolDetail == 2 && (
                <div className='align-items-center py-4 d-flex px-0'>
                    <input className='form-check-input mt-0 me-2 ms-0 cursor-pointer' type="checkbox" checked={isChecked} onChange={(e) => handlecheckboxchange(e)} />
                    Ship Devices
                </div>
            )}
            {commonDivShow == true && (
                <>
                    <div className="row mt-3">
                        <div className="col-md-5">
                            <div className="greyBox">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div style={{ color: '#030E20', size: '18px', fontWeight: 600 }}>Shipping Method</div>
                                    <div><img src='/images/FedexIcon.svg' className='img-fluid w-100 ' /></div>
                                </div>
                                <hr />
                                <div className="pb-3">
                                    <div className="shipdevice-font-style">What device type are you shipping?</div>
                                    <div className="pt-1">
                                        <select required onChange={(e) => ChangeDeviceType(e.target.value)}>
                                            <option value="0">Select</option>
                                            <option value="3">iPad</option>
                                            <option value="1">Chromebook/Laptop</option>
                                            <option value="4">Other</option>
                                        </select>
                                        <span className="form-text invalid-feedback" >
                                            *required
                                        </span>
                                    </div>
                                </div>
                                <div className={` ${showpackingtypelist}`}>
                                    <div>
                                        <div className="shipdevice-font-style">Weight(Min:1, Max:55)*</div>
                                        <div className="input-group pt-1">
                                            <input type="number" autoComplete='off' name='weight' min={1} max={55} className="form-control CommonClass" required value={BatchData.Weight} onChange={(e) => { setBatchData({ ...BatchData, Weight: e.target.value }); }} />
                                            <span className="input-group-text">lbs</span>
                                            <span className="form-text invalid-feedback" >
                                                *required
                                            </span>
                                        </div>
                                        <span className="Emailinvalid">
                                            {WeightError}
                                        </span>
                                    </div>
                                    <div>
                                        <div className="shipdevice-font-style pt-3">Box Size(Length * Width * Height)*</div>
                                        <div className="pt-1">
                                            <select required onChange={(e) => { setBatchData({ ...BatchData, boxsize: e.target.value }); addcustominputs(e.target.value) }}>
                                                <option value="0">Select</option>
                                                {BatchData.Weight >= 1 && BatchData.Weight <= 4 ?
                                                    <>
                                                        <option value="12-8-4">12 * 8 * 4</option>
                                                        <option value="custom">Custom</option>
                                                    </>
                                                    : BatchData.Weight >= 5 && BatchData.Weight <= 10 ?
                                                        <>
                                                            <option value="12-8-4">12 * 8 * 4</option>
                                                            <option value="13-10-7">13 * 10 * 7</option>
                                                            <option value="custom">Custom</option>
                                                        </>
                                                        : BatchData.Weight >= 11 && BatchData.Weight <= 21 ?
                                                            <>
                                                                <option value="12-8-4">12 * 8 * 4</option>
                                                                <option value="13-10-7">13 * 10 * 7</option>
                                                                <option value="14-14-10">14 * 14 * 10</option>
                                                                <option value="custom">Custom</option>
                                                            </>
                                                            : BatchData.Weight >= 22 && BatchData.Weight <= 36 ?
                                                                <>
                                                                    <option value="12-8-4">12 * 8 * 4</option>
                                                                    <option value="13-10-7">13 * 10 * 7</option>
                                                                    <option value="14-14-10">14 * 14 * 10</option>
                                                                    <option value="14-12-14">14 * 12 * 14</option>
                                                                    <option value="custom">Custom</option>
                                                                </>
                                                                : <option value="custom">Custom</option>
                                                }
                                            </select>
                                            <span className="form-text invalid-feedback" >
                                                *required
                                            </span>
                                        </div>
                                    </div>
                                    <div className={`${showcustominputs} row parent`}>
                                        <div className="col-12 pt-2">
                                            <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Width*</div>
                                            <input type="number" autoComplete='off' name='width' className="form-control" required value={BatchData.width} onChange={(e) => setBatchData({ ...BatchData, width: e.target.value })} />
                                            <span className="form-text invalid-feedback" >
                                                *required
                                            </span>
                                        </div>
                                        <div className="col-12 pt-2">
                                            <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Height*</div>
                                            <input type="number" autoComplete='off' name='height' className="form-control" required value={BatchData.height} onChange={(e) => setBatchData({ ...BatchData, height: e.target.value })} />
                                            <span className="form-text invalid-feedback" >
                                                *required
                                            </span>
                                        </div>
                                        <div className="col-12 pt-2">
                                            <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Depth*</div>
                                            <input type="number" autoComplete='off' name='depth' className="form-control" required value={BatchData.depth} onChange={(e) => setBatchData({ ...BatchData, depth: e.target.value })} />
                                            <span className="form-text invalid-feedback" >
                                                *required
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="shipdevice-font-style pt-3">Label Size *</div>
                                    <div className="pt-1">
                                        <select required onChange={(e) => setBatchData({ ...BatchData, labelsize: e.target.value })}>
                                            <option value="0">Select</option>
                                            <option value="PAPER_85X11_TOP_HALF_LABEL">PAPER_85X11_TOP_HALF_LABEL</option>
                                            <option value="PAPER_4X6">PAPER_4X6</option>
                                            <option value="PAPER_4X8">PAPER_4X8</option>
                                        </select>
                                        <span className="form-text invalid-feedback" >
                                            *required
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className={`row pe-0 ${YourAddressDiv} parent`}>
                                <div className="pt-2" style={{ color: '#030E20', size: '18px', fontWeight: 600 }}>Your Address</div>
                                <hr />
                                <div className="col-md-6">
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Phone No.*</div>
                                    <input type="text" autoComplete='off' name='phoneno' className="form-control" required value={BatchData.Phoneno} onChange={(e) => setBatchData({ ...BatchData, Phoneno: e.target.value })}
                                        onKeyPress={(e) => {
                                            if (!/^\d$/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }} />
                                    <span className="form-text invalid-feedback" >
                                        *required
                                    </span>
                                    <span className="Emailinvalid">{phonenoError}</span>
                                </div>
                                <div className="col-md-6 pt-1">
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Street Line 1*</div>
                                    <input type="text" autoComplete='off' name='City' className="form-control" required value={BatchData.Street1} onChange={(e) => setBatchData({ ...BatchData, Street1: e.target.value })} />
                                    <span className="form-text invalid-feedback" >
                                        *required
                                    </span>
                                </div>
                                <div className="col-md-6 pt-3">
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Street Line 2</div>
                                    <input type="text" autoComplete='off' name='City' className="form-control" value={BatchData.Street2} onChange={(e) => setBatchData({ ...BatchData, Street2: e.target.value })} />
                                </div>
                                <div className="col-md-6 pt-3">
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>City*</div>
                                    <input type="text" autoComplete='off' name='City' className="form-control" required value={BatchData.city} onChange={(e) => setBatchData({ ...BatchData, city: e.target.value })} />
                                    <span className="form-text invalid-feedback" >
                                        *required
                                    </span>
                                </div>
                                <div className="col-md-6 pt-3">
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>State*</div>
                                    <select required onChange={(e) => setBatchData({ ...BatchData, state: e.target.value })}>
                                        <option value="0">Select</option>
                                        {AllState.map((item, i) => {
                                            return <option value={item.state_code} selected={BatchData.state == item.state_code}>{item.state_code}</option>
                                        })}
                                    </select>
                                    <span className="form-text invalid-feedback" >
                                        *required
                                    </span>
                                </div>
                                <div className="col-md-6 pt-3">
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Postal Code</div>
                                    <input type="number" autoComplete='off' name='postalcode' className="form-control" value={BatchData.postalcode}
                                        onChange={(e) => setBatchData({ ...BatchData, postalcode: e.target.value })} />
                                </div>
                                <div className="col-md-6 pt-3">
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Country</div>
                                    <input type="text" autoComplete='off' value='US' disabled name='City' className="form-control" />
                                </div>

                            </div>
                            <div className={`col-12 ${YourAddressDefaultDiv}`}>
                                <b>Your Address  : </b>
                                {BatchData.Street1}{BatchData.Street2 ? (', ' + BatchData.Street2) : ''}, {BatchData.city}, {BatchData.state}, {BatchData.postalcode}, US.
                            </div>
                            <hr />
                            <div className="row py-2">
                                <div className="col-md-12 pb-4">
                                    <div className='col-12 text-start pb-1 shipdevice-font-style'>Recipients Company Name : </div>
                                    <label>{BatchData.fedex_company_name}</label>
                                    {/* <input type="text" autoComplete='off' name='companyname' className="form-control" required
                                        value={deliveryaddress.companyname} onChange={(e) => setdeliveryaddress({ ...deliveryaddress, companyname: e.target.value })} />
                                    <span className="form-text invalid-feedback" >
                                        *required
                                    </span> */}
                                </div>
                                <div className="col-md-6">
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Recipients Name*</div>
                                    <input type="text" autoComplete='off' name='recname' className="form-control" required value={deliveryaddress.recipientsname} onChange={(e) => setdeliveryaddress({ ...deliveryaddress, recipientsname: e.target.value })} />
                                    <span className="form-text invalid-feedback" >
                                        *required
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Recipients Phone*</div>
                                    <input type="text" autoComplete='off' name='recphone' className="form-control"
                                        required value={deliveryaddress.recipientsphone} onChange={(e) => setdeliveryaddress({ ...deliveryaddress, recipientsphone: e.target.value })}
                                        onKeyPress={(e) => {
                                            if (!/^\d$/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }} />
                                    <span className="form-text invalid-feedback" >
                                        *required
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 ">
                                <b>Delivery Address  : </b>
                                {deliveryaddress.streetline1} {deliveryaddress.streetline2 ? (', ' + deliveryaddress.streetline2) : ''},{deliveryaddress.city}, {deliveryaddress.stateOrProvinceCode}, {deliveryaddress.postalCode}, US.
                            </div>
                        </div>
                    </div>
                    <div className="col-12 pt-3 text-center">
                        <button className={`SaveBtn text-center`} onClick={(e) => SaveCreateBatch(1)}> Preview Shipping</button>
                        <div className={`${ValidateColor}`}>{ValidateError}</div>
                    </div>
                </>
            )}
        </div>
    )
    return (
        <>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <Popup isshow={props.isShow} size={"lg"} title={"Create Batch"}
                closePopup={(e) => { props.ClosePopup(); setshipmentButton(true) }}
                modalBody={modalbody} handleSave={(e) => SaveCreateBatch(2)} visibleSaveBtn={shipmentButton} btnText={"Create Batch"} />
        </>
    )
}

export default CreateShippingCom
