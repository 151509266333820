import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { CamelCaseWithSpaces, Camelcase, LogInSchoolID, LogInUserID, MMDDYYYY, typewatch } from "../../JS/Common";
import { ApiGetCall, ApiGetHeaders, ApiPostCall } from "../../JS/Connector";
import { DeviceDetailsPopup } from "../../Components/DeviceDetailsPopup";
import AlertsComp from "../../Components/AlertsComp";
import Pagination from "react-js-pagination";
import { useLocation } from "react-router-dom";
import CustomizationPopup from "../../Components/CustomizationPopup";
import UserDetailsPopup from "../../Components/UserDetailsPopup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from "@fortawesome/free-solid-svg-icons";
import PageHead from "../../Components/PageHead";
import Tab from "../../Components/Tab";
import Popup from "../../Components/Popup";
import Deletepopup from "../../Components/Deletepopup";
export function ManageDeviceIndex() {
    const [Alerts, setAlerts] = useState("");
    const navigate = useNavigate();
    const [isshowpopup, setisshowpopup] = useState(false);
    const [isAlreadyTicketModal, setIsAlreadyTicketModal] = useState(false);
    const [isDecommissionPopup, setisDecommissionPopup] = useState(false);
    const [deviceParams, setdeviceParams] = useState({
        DeviceFlag: 1,
        Sortbykey: null,
        SortbyFlag: "asc"
    });
    const [devicetabs, setDeviceTabs] = useState({
        ActiveTab: "active",
        LoanerTab: "",
        DecommissionTab: ""
    });
    const [FetchDeviceData, setFetchDeviceData] = useState({
        Devicedetails: [],
        Devicehistory: [],
        ticketid: "",
        deviceid: "",
        ActionDropDown: false,
        DecommissionBtnName: "",
        DecommissionFlag: '',
        TicketStatusName: '',
        TicketNum: ''
    });
    const [devicedetails, setDeviceDetails] = useState([]);
    const [tabflag, settabflag] = useState(1);
    const [SearchForActive, setSearchForActive] = useState("");
    const [SearchForLoner, setSearchForLoner] = useState("");
    const [SearchForDecomission, setSearchForDecomission] = useState("");
    const [loading, setLoading] = useState(false);
    const showPagination = devicedetails.length > 0;
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const location = useLocation();
    const lonerflag = location.state && location.state.LoanerFlag;
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    const [UserModal, setUserModal] = useState(false)
    const [Userid, setUserid] = useState('');
    const [effectExecuted, setEffectExecuted] = useState(false);
    const [AlreadyTicketFlag, setAlreadyTicketFlag] = useState(3);
    const [deletedeviceshow, setdeletedeviceshow] = useState(false);
    const [DeleteDeviceId, setDeleteDeviceId] = useState([]);
    useEffect(() => {
        if (!effectExecuted) {
            ApiGetHeaders(40, setColumnNames);
            setEffectExecuted(true);
        }
    }, [columnNames, effectExecuted]);


    useEffect(() => {
        if (lonerflag === "yes") {
            GetDeviceDetails(3, null, null);
            navigate('/manage-device', { state: null });
        } else {
            if (devicetabs.ActiveTab == 'active') {
                GetDeviceDetails(1, deviceParams.Sortbykey || null, SearchForActive ? SearchForActive : null);
            } else if (devicetabs.LoanerTab == 'active') {
                GetDeviceDetails(3, deviceParams.Sortbykey || null, SearchForLoner ? SearchForLoner : null);
            } else {
                GetDeviceDetails(2, deviceParams.Sortbykey || null, SearchForDecomission ? SearchForDecomission : null);
            }
            navigate('/manage-device', { state: null });
        }
        setFetchDeviceData({ ...FetchDeviceData, DecommissionBtnName: "Decommission" });
    }, [currentPage, LimitForPagination]);

    async function GetDeviceDetails(Flag, sortbykey, searchkey) {
        setFetchDeviceData({ ...FetchDeviceData, ActionDropDown: false });
        if (!searchkey) searchkey = null;
        if (sortbykey) deviceParams.SortbyFlag = deviceParams.SortbyFlag == "asc" ? "desc" : "asc";
        var joinedString = null;
        let newColumnNames = columnNames.map(name => name.replace(/\(\d+\)/g, ""));
        if (newColumnNames.length > 0) {
            var joinedString = newColumnNames.join('~');
        }
        typewatch(async function () {
            setLoading(true)
            await ApiGetCall("/allInventories/" + LogInSchoolID + "&" + Flag + "&" + searchkey + "&" + sortbykey + "&" + deviceParams.SortbyFlag + '&' + currentPage + '&' + LimitForPagination + "?data=" + joinedString).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    if (Flag == 1) {
                        setDeviceTabs({ ...devicetabs, ActiveTab: "active", LoanerTab: "", DecommissionTab: "" });
                    } else if (Flag == 2) {
                        setDeviceTabs({ ...devicetabs, ActiveTab: "", LoanerTab: "", DecommissionTab: "active" });
                    } else {
                        setDeviceTabs({ ...devicetabs, ActiveTab: "", LoanerTab: "active", DecommissionTab: "" });
                    }
                    setpageRangeCount(responseRs.msg.total)
                    if (responseRs.response == "success") {
                        if (responseRs.msg.data.length != 0) {
                            setDeviceDetails(responseRs.msg.data);
                        }
                        else {
                            setDeviceDetails([]);
                        }
                    }
                }
            });
        }, searchkey != null ? 1000 : 0);
    }
    function handleChange(e) {
        const { name, checked } = e.target;
        if (name == "allSelect") {
            if (checked == true) {
                setFetchDeviceData({ ...FetchDeviceData, ActionDropDown: true });
            } else {
                setFetchDeviceData({ ...FetchDeviceData, ActionDropDown: false });
            }
            let tempUser = devicedetails.map(item => { return { ...item, isChecked: checked } });
            setDeviceDetails(tempUser);
            const selectedIds = checked ? tempUser.map(item => item.id) : [];
            setDeleteDeviceId(selectedIds);
        } else {
            let tempUser = devicedetails.map(item => item.serial_number === name ? { ...item, isChecked: checked } : item);
            setDeviceDetails(tempUser);
            if (tempUser.filter(item => item?.isChecked == true).length <= 0) {
                setFetchDeviceData({ ...FetchDeviceData, ActionDropDown: false });
            } else {
                setFetchDeviceData({ ...FetchDeviceData, ActionDropDown: true });
            }
            const selectedIds = tempUser
                .filter(item => item.isChecked)
                .map(item => item.id);
            setDeleteDeviceId(selectedIds);
        }


    }
    function DecommissionDevices(dropdownval) {
        if (dropdownval == 3) {
            setisDecommissionPopup(true);
        } else {
            SaveDeviceDecommission(dropdownval);
        }
    }
    async function SaveDeviceDecommission(dropdownval) {
        var vArray = [];
        {
            devicedetails.map((item, i) => {
                var vjson = {};
                if (item.isChecked == true) {
                    vjson['ID'] = item.id;
                    vArray.push(vjson)
                }
            })
        }
        var action = "";
        if (devicetabs.ActiveTab == "active" && dropdownval == 3) {
            action = 1;
        } else if (devicetabs.DecommissionTab == "active" && dropdownval == 1) {
            action = 2
        } else if (devicetabs.LoanerTab == "active" && dropdownval == 3) {
            action = 3
        } else if (devicetabs.DecommissionTab == "active" && dropdownval == 2) {
            action = 4
        } else if (devicetabs.ActiveTab == "active" && dropdownval == 2) {
            action = 5
        } else if (devicetabs.LoanerTab == "active" && dropdownval == 1) {
            action = 6
        }
        var raw = JSON.stringify({
            IDArray: vArray,
            actionid: action,
            userID: LogInUserID
        });
        setisDecommissionPopup(false);
        await ApiPostCall("/manageInventoryAction", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    var alertMsg = dropdownval == 1 ? 'Device Activate Successfully' : dropdownval == 2 ? 'Device Loaner Successfully' : 'Device Decommission Successfully'
                    setAlerts(<AlertsComp show={true} variant="success" msg={alertMsg} />);
                    setTimeout(() => {
                        GetDeviceDetails(dropdownval == 1 ? 1 : dropdownval == 2 ? 3 : 2, null, null);
                        setAlerts(<AlertsComp show={false} />)
                    }, 2500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1000);
                }
            }
        });
    }

    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "serial_number": item.serial_number,
            "asset_tag": item.asset_tag || '-',
            "user": !item.device_user_first_name
                ? '-'
                : <u onClick={(e) => { setUserid(item.utilizerid); setUserModal(true) }} className="cursor-pointer">{`${Camelcase(item.device_user_first_name || '')} ${Camelcase(item.device_user_middle_name || '')} ${Camelcase(item.device_user_last_name || '')}`.trim()}</u>,
            "building": item.BuildingName || '-',
            "purchase_date": item.purchase_date == null ? '-' : MMDDYYYY(item.purchase_date),
            "device_model": item.device_model || '-',
            "device_manufacturer": item.device_manufacturer || '-',
            "device_mpn": item.device_mpn || '-',
            "repair_cap": item.repair_cap ? `$${item.repair_cap}` : '-',
            "device_os": item.device_os || '-',
            "notes": <div className="location-name">{item.notes || '-'}</div>,
            "expected_retirement": item.expected_retirement == null ? '-' : MMDDYYYY(item.expected_retirement),
            "manufacturer_warranty_until": item.manufacturer_warranty_until == null ? '-' : MMDDYYYY(item.manufacturer_warranty_until),
            "manufacturer_adp_until": item.manufacturer_adp_until == null ? '-' : MMDDYYYY(item.manufacturer_adp_until),
            "third_party_extended_warranty_until": item.third_party_extended_warranty_until == null ? '-' : MMDDYYYY(item.third_party_extended_warranty_until),
            "third_party_adp_until": item.third_party_adp_until == null ? '-' : MMDDYYYY(item.third_party_adp_until),
            "created_at": item.created_at == null ? '-' : MMDDYYYY(item.created_at),
            "room": item.RoomName || '-',
        };
        return contentMap[columnName] || (item[columnName?.toLowerCase()] == null || item[columnName?.toLowerCase()] === ''
            ? ' - '
            : (Object.prototype.toString.call(new Date(item[columnName?.toLowerCase()])) !== "[object Date]"
                ? MMDDYYYY(item[columnName?.toLowerCase()])
                : item[columnName?.toLowerCase()]));
    }

    const handleSortClick = (item) => {
        if (item == "user") {
            item = "device_user_first_name";
        } else if (item == 'building') {
            item = 'BuildingName'
        } else {
            item = item.toLowerCase().split(' ').join('_');
        }
        GetDeviceDetails(deviceParams.DeviceFlag, item, tabflag == 1 ? SearchForActive : tabflag == 2 ? SearchForLoner : SearchForDecomission);
        setdeviceParams({ ...deviceParams, Sortbykey: item })
    };
    async function AgainCreateTicketCall(deviceIDD, ticketIDD) {
        var raw = JSON.stringify({
            SchoolId: LogInSchoolID,
            UserId: LogInUserID,
            TicketId: ticketIDD,
            InventoryId: deviceIDD,
        });
        await ApiPostCall("/ChangeRepairBilledToClose", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = result;
                if (responseRs == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={'Ticket Closed Successfully'} />);
                    setTimeout(function () {
                        setAlerts(<AlertsComp show={false} />)
                        navigate('/create-ticket/?deviceid=' + FetchDeviceData.deviceid)
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }
    let newColumnNames = columnNames.map(name => name.replace(/\(\d+\)/g, ""));
    function handleSearchChange(e) {
        if (tabflag == 1) {
            setSearchForActive(e.target.value);
            setCurrentPage(1);
            GetDeviceDetails(deviceParams.DeviceFlag, deviceParams.Sortbykey, e.target.value)
        } else if (tabflag == 2) {
            setSearchForLoner(e.target.value);
            setCurrentPage(1);
            GetDeviceDetails(deviceParams.DeviceFlag, deviceParams.Sortbykey, e.target.value);
        } else {
            setSearchForDecomission(e.target.value);
            setCurrentPage(1);
            GetDeviceDetails(deviceParams.DeviceFlag, deviceParams.Sortbykey, e.target.value)
        }
    }
    function handleTabClick(tabType) {
        if (tabType == "active") {
            setDeviceTabs({ ...devicetabs, ActiveTab: "active", LoanerTab: "", DecommissionTab: "" });
            GetDeviceDetails(1, null, null);
            setdeviceParams({ ...deviceParams, DeviceFlag: 1 });
            setFetchDeviceData({ ...FetchDeviceData, DecommissionBtnName: "Decommission", ActionDropDown: false });
            settabflag(1);
            setSearchForActive('');
            setSearchForLoner('');
            setSearchForDecomission('');
            setCurrentPage(1)
        } else if (tabType == "loaner") {
            setDeviceTabs({ ...devicetabs, ActiveTab: "", LoanerTab: "active", DecommissionTab: "" });
            GetDeviceDetails(3, null, null);
            setdeviceParams({ ...deviceParams, DeviceFlag: 3 });
            settabflag(2);
            setSearchForActive('');
            setSearchForLoner('');
            setSearchForDecomission('');
            setFetchDeviceData({ ...FetchDeviceData, ActionDropDown: false });
            setCurrentPage(1)
        } else if (tabType == "decommission") {
            setDeviceTabs({ ...devicetabs, ActiveTab: "", LoanerTab: "", DecommissionTab: "active" });
            GetDeviceDetails(2, null, null);
            setdeviceParams({ ...deviceParams, DeviceFlag: 2 });
            setFetchDeviceData({ ...FetchDeviceData, DecommissionBtnName: "Active", ActionDropDown: false });
            settabflag(3);
            setSearchForActive('');
            setSearchForLoner('');
            setSearchForDecomission('');
            setCurrentPage(1)
        }
    }
    const modalBody = (
        <>
            There is currently an open ticket on this device <br />(Ticket Num :{FetchDeviceData.TicketNum}). The current ticket status of this ticket is {FetchDeviceData.TicketStatusName}.
            {FetchDeviceData.TicketStatusName == "Repair Billed" && (
                <div className='col-md-12 mt-3'>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name='ticket'
                            onChange={(e) => setAlreadyTicketFlag(1)}
                        />
                        <label className="form-check-label">Open New Ticket, Close Current Ticket</label>
                    </div>
                    <div className="form-check my-1">
                        <input
                            className="form-check-input"
                            type="radio"
                            name='ticket'
                            onChange={(e) => setAlreadyTicketFlag(0)}
                        />
                        <label className="form-check-label">Edit Current Ticket</label>
                    </div>
                </div>
            )}
        </>
    )
    async function DeleteDevice() {
        setLoading(true);
        var vArray = [];
        {
            DeleteDeviceId?.map(item => {
                var vjson = {};
                vjson['id'] = item
                vArray.push(vjson);
            })
        }
        var raw = JSON.stringify({
            deviceIds: vArray,
            schoolId: LogInSchoolID
        });
        await ApiPostCall("/DeleteDecommissionDevice", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setdeletedeviceshow(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Device Deleted Successfully."} />);
                    setTimeout(() => {
                        GetDeviceDetails(2, deviceParams.Sortbykey || null, SearchForDecomission ? SearchForDecomission : null);
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    return (
        <React.Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={"Manage Devices"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <div className='row my-2 d-flex justify-content-between pe-0 align-items-center'>
                <div className='col-md-12 ps-0 text-center d-md-flex justify-content-end align-items-center'>
                    <label>Total Devices : <b>{pageRangeCount}</b></label>
                    <a onClick={(e) => navigate("/import-export")} className='BlackFont cursor-pointer ms-3' title="Import Devices"><label className='BorderBtn text-center'> Import Devices
                        <img src='/images/ImportInventory.svg' className='img-fluid ps-2' />
                    </label></a>
                    <label className='BorderBtn text-center' onClick={(e) => navigate("/addupdate-device")} title="Add Device"> Add Device
                        <img src='/images/AddInventory.svg' className='img-fluid ps-2' />
                    </label>
                    <label className='BorderBtn text-center' onClick={(e) => navigate("/custom-field")} title="Device Fields Setting"> Device Fields Setting
                        <FontAwesomeIcon icon={faGear} className="px-2" style={{ color: '#4c5258' }} />
                    </label>
                </div>
            </div>
            <div className="GridBox p-3">
                <div className='row align-items-center'>
                    <div className="col-md-10 px-0">
                        <ul className="nav nav-tabs px-3">
                            <Tab isActive={devicetabs.ActiveTab == 'active'}
                                label="Active Devices" onClick={() => handleTabClick('active')} col={'col-md-3'} />
                            <Tab isActive={devicetabs.LoanerTab == 'active'}
                                label="Loaner Devices" onClick={() => handleTabClick('loaner')} col={'col-md-3'} />
                            <Tab isActive={devicetabs.DecommissionTab == 'active'}
                                label="Decommissioned Devices" onClick={() => handleTabClick('decommission')} col={'col-md-4'} />
                        </ul>
                    </div>
                    <div className={`col-md-2 text-end`}>
                        {FetchDeviceData.ActionDropDown == true && (
                            <div className='pt-1'>
                                <select onChange={(e) => DecommissionDevices(e.target.value)}>
                                    <option value="0">Select</option>
                                    {devicetabs.ActiveTab == "active" ?
                                        <>
                                            <option value="2">Loaner</option>
                                            <option value="3">Decommission</option>
                                        </>
                                        : devicetabs.LoanerTab == "active" ?
                                            <>
                                                <option value="1">Active</option>
                                                <option value="3">Decommission</option>
                                            </>
                                            :
                                            <>
                                                <option value="1">Active</option>
                                                <option value="2">Loaner</option>
                                            </>
                                    }
                                </select>
                            </div>
                        )}
                    </div>
                </div>
                <div className="row pt-2">
                    <div className="col-4">
                        {FetchDeviceData.ActionDropDown == true && devicetabs.DecommissionTab == "active" && (
                            <label className='BorderBtn text-center'
                                onClick={(e) => { setdeletedeviceshow(true) }} title="Delete Decommission Device">
                                Delete Device
                            </label>
                        )}
                    </div>
                    <div className="col-8 text-end">
                        <label className="redText">* A ticket has been created on the device</label>
                    </div>
                </div>
                <div className='col-12 greyBox mt-3 data-table-container'>
                    <table className="table data-table mb-0" >
                        <thead className='GridHeader'>
                            <tr>
                                <th scope="col">
                                    {devicedetails.length != 0 && (<input className="form-check-input mt-1 cursor-pointer" type="checkbox" name="allSelect" onChange={handleChange} checked={devicedetails.filter(item => item?.isChecked !== true).length === 0} />)}
                                </th>
                                {newColumnNames?.map((item) => {
                                    let columnName = item.replace(/_/g, ' ');
                                    let isSortable = ["serial_number", "asset_tag", "user", "building", "purchase_date", "device_model", "device_manufacturer", "device_mpn", "repair_cap", "device_os", "notes", "expected_retirement", "manufacturer_warranty_until", "manufacturer_adp_until", "third_party_extended_warranty_until", "third_party_adp_until", "created_at"].includes(item);

                                    return (
                                        <th key={item} scope="col" className={`fw-600 ${isSortable ? 'cursor-pointer' : ''}`} title={isSortable ? `Sort by ${columnName}` : undefined} onClick={isSortable ? () => handleSortClick(item) : undefined}>
                                            {CamelCaseWithSpaces(columnName)}
                                            {isSortable && <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />}
                                        </th>
                                    );
                                })}
                                <th scope="col">Action  <img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {devicedetails.length != 0 ?
                                devicedetails.map((item, i) => {
                                    return <tr key={i} className={`OddEvenClass tableBorderHide ${item.TicketFlag}`}>
                                        <td className='py-12'>
                                            <input type="checkbox" className="form-check-input cursor-pointer" onChange={handleChange} name={item.serial_number} checked={item?.isChecked || false} />
                                        </td>
                                        {newColumnNames?.map(header => (
                                            <td className="py-12" key={header}> {renderContentForColumn(header, item)} </td>
                                        ))}
                                        <td className='py-12'>
                                            {devicetabs.DecommissionTab != "active" && (
                                                <>
                                                    <img src='/images/EditIconNew.svg' title='Edit Device' className='img-fluid pe-2 cursor-pointer' inventoryid={item.id} onClick={(e) => { navigate("/addupdate-device/?id=" + item.id) }} />
                                                    {item.ticketId == null ?
                                                        <img src="/images/TicketIcon.svg" title='Create Ticket' className='pe-2 img-fluid cursor-pointer' inventoryid={item.id} onClick={(e) => navigate('/create-ticket/?deviceid=' + item.id)} />
                                                        :
                                                        <img src="/images/TicketIcon.svg" title='Edit Ticket' className='pe-2 img-fluid cursor-pointer' inventoryid={item.id} onClick={(e) => { setIsAlreadyTicketModal(true); setFetchDeviceData({ ...FetchDeviceData, deviceid: item.id, ticketid: item.ticketId, TicketStatusName: item.TicketStatusName, TicketNum: item.ticketNum }) }} />
                                                    }
                                                </>)}
                                            <img src='/images/InfoIcon.svg' title="Show Device Details" className="cursor-pointer" inventoryid={item.id} onClick={(e) => { setisshowpopup(true); setFetchDeviceData({ ...FetchDeviceData, deviceid: item.id, ticketid: item.ticketId }) }} />
                                            {devicetabs.DecommissionTab == "active" && (
                                                <img src="/images/DeleteIcon.svg" title="Delete Device"
                                                    className="ps-1 img-fluid cursor-pointer" onClick={(e) => { setdeletedeviceshow(true); setDeleteDeviceId((prev) => [...prev, item.id]) }} />
                                            )}
                                        </td>
                                    </tr>
                                })
                                :
                                <tr>
                                    <td colSpan={18} className="p-3 text-center">
                                        No Record Found
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {showPagination && (
                    <Pagination
                        activePage={currentPage}
                        totalItemsCount={pageRangeCount}
                        pageRangeDisplayed={15}
                        itemsCountPerPage={LimitForPagination}
                        onChange={(e) => setCurrentPage(e)}
                        color="primary"
                        lastPageText=""
                        firstPageText=""
                        itemClassFirst="MasterClass"
                        itemClassPrev="MasterClass"
                        itemClassNext="MasterClass"
                        itemClassLast="MasterClass"
                    />
                )}
            </div>

            {isshowpopup == true && (
                <DeviceDetailsPopup Showbuttons={1} createticketbutton={true} deviceid={FetchDeviceData.deviceid} ticketid={FetchDeviceData.ticketid} activetab="device" isShow={isshowpopup} ClosePopup={(e) => setisshowpopup(false)} TabFlag={deviceParams.DeviceFlag} />
            )}

            <Popup isshow={isDecommissionPopup} size={"md"} title={"Decommission Device"}
                closePopup={(e) => setisDecommissionPopup(false)}
                modalBody={"When you decommission any device(s), all open tickets attached to them will be closed automatically"}
                handleSave={(e) => SaveDeviceDecommission(3)} visibleSaveBtn={true} btnText={"Continue"} />

            {CustomizationModal == true && (
                <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={"Manage Devices"} GetCall={() => ApiGetHeaders(40, setColumnNames)} />
            )}
            {UserModal == true && (
                <UserDetailsPopup isShow={UserModal} ClosePopup={(e) => setUserModal(false)} userid={Userid} />
            )}

            <Deletepopup text={`This action is permanent. You will lose all history on this device. Existing tickets and batches will remain for records purposes.`}
                deletePopup={deletedeviceshow}
                deletePart={DeleteDevice} DeleteShowClose={(e) => setdeletedeviceshow(false)}>
            </Deletepopup>

            <Popup isshow={isAlreadyTicketModal} size={"md"} title={"Ticket Management"}
                closePopup={(e) => { setIsAlreadyTicketModal(false); setAlreadyTicketFlag(3) }}
                modalBody={modalBody}
                handleSave={(e) => {
                    FetchDeviceData.TicketStatusName == "Repair Billed" ?
                        AlreadyTicketFlag == 0 ?
                            navigate('/create-ticket/?deviceid=' + FetchDeviceData.deviceid + "&ticketid=" + FetchDeviceData.ticketid)
                            : AgainCreateTicketCall(FetchDeviceData.deviceid, FetchDeviceData.ticketid)
                        :
                        navigate('/create-ticket/?deviceid=' + FetchDeviceData.deviceid + "&ticketid=" + FetchDeviceData.ticketid)
                }} visibleSaveBtn={true} btnText={
                    FetchDeviceData.TicketStatusName == "Repair Billed" ?
                        AlreadyTicketFlag == 0 ? 'Edit Ticket' : 'Create Ticket'
                        :
                        'Edit Ticket'
                } />
        </React.Fragment>
    )
}
