import { useContext, useEffect, useState } from "react";
import { HideLoder, MMDDYYYY, ShowLoder, formatDateToMMDDYYYY } from "../JS/Common";
import { useNavigate } from "react-router-dom";
import { ApiGetCall } from "../JS/Connector";
import Tab from "./Tab";
import Popup from "./Popup";
import { UserContext } from "../App";

export function DeviceDetailsPopup(props) {
    const navigate = useNavigate();
    const [ShowActive, setShowActive] = useState({
        details: "",
        history: "d-none",
        userhistory: "d-none"
    });
    const [Tabactive, setTabActive] = useState({
        DevicedetailsTab: "active",
        DeviceHistoryTab: "",
        UserHistoryTab: ""
    });
    const [DeviceDetails, setDeviceDetails] = useState([]);
    const [DeviceHistoryDetails, setDeviceHistoryDetails] = useState([]);
    const [UserHistoryDetails, setUserHistoryDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [customData, setCustomData] = useState({});
    const { UserData } = useContext(UserContext);
    useEffect(() => {
        GetDeviceData();
        if (props.activetab == "device") {
            setShowActive({ ...ShowActive, details: "", history: "d-none" });
        } else {
            setShowActive({ ...ShowActive, details: "d-none", history: "" });
        }
    }, []);
    async function GetDeviceData() {
        setLoading(true)
        await ApiGetCall("/getInventoryDataById/" + props.deviceid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false)
                const responseRs = JSON.parse(result);
                setDeviceDetails(responseRs.msg);
                if (responseRs?.msg?.ticket?.length != 0) {
                    setDeviceHistoryDetails(responseRs.msg.ticket);
                }
                else {
                    setDeviceHistoryDetails([]);
                }
                if (responseRs.history.length != 0) {
                    setUserHistoryDetails(responseRs.history);
                } else {
                    setUserHistoryDetails([]);
                }
                if (responseRs?.msg?.customData?.length != 0) {
                    setCustomData(responseRs?.msg?.customData)
                }
                else {
                    setCustomData({})
                }
            }
        });
    }
    const [ticketHistoryVisibility, setTicketHistoryVisibility] = useState({});
    function checktickethistorydiv(ticketId) {
        setTicketHistoryVisibility((prevState) => ({
            ...prevState,
            [ticketId]: !prevState[ticketId]
        }));
    }
    const [PartHistoryVisibility, setPartHistoryVisibility] = useState({});
    function checkPartshistorydiv(ticketId) {
        setPartHistoryVisibility((prevState) => ({
            ...prevState,
            [ticketId]: !prevState[ticketId]
        }));
    }
    function handleTabClick(tabType) {
        if (tabType == "device") {
            setShowActive({ ...ShowActive, details: "", history: "d-none", userhistory: "d-none" });
            setTabActive({ ...Tabactive, DevicedetailsTab: "active", DeviceHistoryTab: "", UserHistoryTab: "" })
        } else if (tabType == "ticket") {
            setShowActive({ ...ShowActive, details: "d-none", history: "", userhistory: "d-none" });
            setTabActive({ ...Tabactive, DevicedetailsTab: "", DeviceHistoryTab: "active", UserHistoryTab: "" })
        } else if (tabType == "user") {
            setShowActive({ ...ShowActive, details: "d-none", history: "d-none", userhistory: "" });
            setTabActive({ ...Tabactive, DevicedetailsTab: "", DeviceHistoryTab: "", UserHistoryTab: "active" })
        }
    }
    const modalbody = (
        <>
            <ul className="nav nav-tabs">
                <Tab isActive={Tabactive.DevicedetailsTab == 'active'}
                    label="Device Details" onClick={() => handleTabClick('device')} col={'col-md-3'} />
                <Tab isActive={Tabactive.DeviceHistoryTab == 'active'}
                    label="Ticket Details" onClick={() => handleTabClick('ticket')} col={'col-md-3'} />
                <Tab isActive={Tabactive.UserHistoryTab == 'active'}
                    label="User History" onClick={() => handleTabClick('user')} col={'col-md-3'} />
            </ul>
            <div className={`${ShowActive.details}`}>
                <div className='row pt-3'>
                    <div className='col-lg-6 row py-1'>
                        <div className='col fw-600 pe-0'>Serial # : </div>
                        <div className='col wordwrap'> {DeviceDetails.serial_number}</div>
                    </div>

                    <div className='col-lg-6 row py-1'>
                        <div className='col fw-600 pe-0'>Building : </div>
                        <div className='col'>{DeviceDetails.building_name?.name || '-'}</div>
                    </div>

                    <div className='col-lg-6 row py-1'>
                        <div className='col fw-600 pe-0'>Room : </div>
                        <div className='col'>{DeviceDetails?.room_details?.name || '-'}</div>
                    </div>
                    <div className='col-lg-6 row py-1'>
                        <div className='col fw-600 pe-0'>Device Model : </div>
                        <div className='col'> {DeviceDetails.device_model || '-'}</div>
                    </div>
                    <div className='col-lg-6 row py-1'>
                        <div className='col fw-600 pe-0'>Device Manufacturer :</div>
                        <div className='col'> {DeviceDetails.device_manufacturer || '-'}</div>
                    </div>
                    <div className='col-lg-6 row py-1'>
                        <div className='col fw-600 pe-0'>Device MPN : </div>
                        <div className='col'> {DeviceDetails.device_mpn || '-'}</div>
                    </div>
                    <div className='col-lg-6 row py-1'>
                        <div className='col fw-600 pe-0'>Asset Tag : </div>
                        <div className='col'> {DeviceDetails.asset_tag || '-'}</div>
                    </div>
                    <div className='col-lg-6 row py-1'>
                        <div className='col fw-600 pe-0'>Repair Cap : </div>
                        <div className='col'>{DeviceDetails.repair_cap == null || DeviceDetails.repair_cap == '' ? '-' : `$${DeviceDetails.repair_cap}`}</div>
                    </div>
                    <div className='col-lg-6 row py-1'>
                        <div className='col fw-600 pe-0'>Device OS : </div>
                        <div className='col'>{DeviceDetails.device_os || '-'} </div>
                    </div>
                    <div className='col-lg-12 row py-1'>
                        <div className='col-lg-3 fw-600 pe-0'>Notes : </div>
                        <div className='col-lg-9 ps-0'>{DeviceDetails.notes || '-'} </div>
                    </div>
                    {Object.entries(customData).map(([label, value], index) => (
                        <div className='col-lg-6 row py-1' key={index}>
                            <div className='col fw-600 pe-0'>{label?.replace(/_/g, ' ')?.replace(/\b\w/g, c => c.toUpperCase())} :</div>
                            <div className='col'>{value || '-'}</div>
                        </div>
                    ))}
                    <img src='/images/HorizontalLine.svg' className='img-fluid w-100 my-2' />
                    {DeviceDetails.purchase_date != null && (
                        <>
                            <div className='col-lg-6 row py-1'>
                                <div className='col fw-600'>Purchase Date : </div>
                                <div className='col'> {MMDDYYYY(DeviceDetails.purchase_date) || '-'}</div>
                            </div>
                        </>
                    )}
                    {DeviceDetails.expected_retirement != null && (
                        <div className='col-lg-6 row py-1'>
                            <div className='col fw-600'>Expected Retirement : </div>
                            <div className='col'> {DeviceDetails.expected_retirement == null ? <>-</> : MMDDYYYY(DeviceDetails.expected_retirement)}</div>
                        </div>
                    )}
                    {DeviceDetails.manufacturer_warranty_until != null && (
                        <div className='col-lg-6 row py-1'>
                            <div className='col fw-600 pe-0'>Manufacturer Warranty Until : </div>
                            <div className='col'>  {DeviceDetails.manufacturer_warranty_until == null ? <>-</> : MMDDYYYY(DeviceDetails.manufacturer_warranty_until)}</div>
                        </div>
                    )}

                    {DeviceDetails.manufacturer_adp_until != null && (
                        <div className='col-lg-6 row py-1'>
                            <div className='col fw-600'>Manufacturer ADP Until : </div>
                            <div className='col'> {DeviceDetails.manufacturer_adp_until == null ? <>-</> : MMDDYYYY(DeviceDetails.manufacturer_adp_until)}</div>
                        </div>
                    )}
                    {DeviceDetails.third_party_extended_warranty_until != null && (
                        <div className='col-lg-6 row py-1'>
                            <div className='col fw-600'>Third Party Extended Warranty Until : </div>
                            <div className='col'> {DeviceDetails.third_party_extended_warranty_until == null ? <>-</> : MMDDYYYY(DeviceDetails.third_party_extended_warranty_until)}</div>
                        </div>
                    )}
                    {DeviceDetails.third_party_adp_until != null && (
                        <div className='col-lg-6 row py-1'>
                            <div className='col fw-600'>Third Party ADP Until : </div>
                            <div className='col'> {DeviceDetails.third_party_adp_until == null ? <>-</> : MMDDYYYY(DeviceDetails.third_party_adp_until)}</div>
                        </div>
                    )}
                    <div className='col-lg-6 row py-1'>
                        <div className='col fw-600'>Loaner Device : </div>
                        <div className='col'> {(DeviceDetails.loaner_device == 1) ?
                            <>Yes</> : <>No</>}</div>
                    </div>

                    {DeviceDetails.student != null ?

                        <div className='row' id="ModalStudentDiv">
                            <img src='/images/HorizontalLine.svg' className='img-fluid w-100 my-2 pe-0' />
                            <div className='col-lg-6 row py-1'>
                                <div className='col fw-600'>Name : </div>
                                <div className='col'> {DeviceDetails.student.device_user_first_name} {DeviceDetails.student.device_user_middle_name == null ? '' : DeviceDetails.student.device_user_middle_name}  {DeviceDetails.student.device_user_last_name}</div>
                            </div>
                            <div className='col-lg-6 row py-1'>
                                <div className='col fw-600'>Student # : </div>
                                <div className='col'> {DeviceDetails.student.student_num || '-'}</div>
                            </div>
                            <div className='col-lg-6 row py-1'>
                                <div className='col fw-600'>Grade : </div>
                                <div className='col'>{DeviceDetails.student.grade || '-'}</div>
                            </div>
                            <div className='col-lg-6 row py-1'>
                                <div className='col fw-600'>Department : </div>
                                <div className='col'>{DeviceDetails.student.department || '-'}</div>
                            </div>
                            <div className='col-lg-6 row py-1'>
                                <div className='col fw-600'>Parent Name : </div>
                                <div className='col'>{DeviceDetails.student.parent_guardian_name || '-'}</div>
                            </div>
                            <div className='col-lg-6 row py-1'>
                                <div className='col fw-600'>Parent Contact : </div>
                                <div className='col'>{DeviceDetails.student.parent_phone_number || '-'} </div>
                            </div>
                            <div className='col-lg-6 row py-1'>
                                <div className='col fw-600'>Parental Coverage : </div>
                                <div className='col'>{DeviceDetails.student.parental_coverage == 1 ? 'Yes' : 'No' || '-'}</div>
                            </div>
                            <div className='col-lg-6 row py-1'>
                                <div className='col fw-600'>Parent Email : </div>
                                <div className='col ps-1'>{DeviceDetails.student.parent_guardian_email || '-'}</div>
                            </div>
                        </div>
                        :
                        <></>
                    }
                </div>
            </div>
            <div className={`${ShowActive.history}`}>
                <div className='row px-3 pt-3'>
                    {DeviceHistoryDetails.length != 0 ?
                        <>
                            {DeviceHistoryDetails.map((item, i) => {
                                const ticketId = item.id;
                                const isTicketHistoryVisible = ticketHistoryVisibility[ticketId];
                                const isPartHistoryVisibility = PartHistoryVisibility[ticketId];
                                var returData;
                                returData = (<div className='row' key={i}>
                                    <div className='row p-1 pe-0 row'>
                                        <div className='col col-6 fw-600'> Ticket # : </div>
                                        <div className='col col-6'>  {item.ticket_num || '-'}</div>
                                    </div>
                                    <div className=' p-1 pe-0 row'>
                                        <div className='col fw-600'>Status : </div>
                                        <div className='col' > <label style={{ color: "#3CBBA5" }}>{item.statusName}</label>
                                        </div>
                                    </div>
                                    <div className='row p-1 pe-0 row'>
                                        <div className='col col-6 fw-600'>Created Date : </div>
                                        <div className='col col-6'>  {MMDDYYYY(item.createdDate)}</div>
                                    </div>
                                    <div className='row p-1 pe-0 row'>
                                        <div className='col col-6 fw-600'>User : </div>
                                        <div className='col col-6'>  {(DeviceDetails?.student?.device_user_first_name || '-') + ' ' + (DeviceDetails?.student?.device_user_middle_name || '') + ' ' + (DeviceDetails?.student?.device_user_last_name || '')}</div>
                                    </div>
                                    <div className='row p-1 pe-0 row'>
                                        <div className='col col-6 fw-600'>Created By : </div>
                                        <div className='col col-6'> {item.ticketCreatedBy}</div>
                                    </div>
                                    <div className='row p-1 pe-0 row'>
                                        <div className='col col-6 fw-600'>Issue : </div>
                                        <div className='col col-6' style={{ display: "inline" }}>
                                            {item.issues.map(item => item).join(', ')}
                                        </div>
                                    </div>
                                    <div className='row p-1 pe-0 row text-justify'>
                                        <div className='col col-6 fw-600'>Notes : </div>
                                        <div className='col col-6'> {item.notes}</div>
                                    </div>
                                    <div className='row p-1 pe-0 row text-justify'>
                                        <div className='col col-6 fw-600'>Repaired Notes : </div>
                                        <div className='col col-6'> {item?.ticket_repair_log[0]?.repaired_item || '-'}</div>
                                    </div>

                                    <div className="col-12 ps-0 py-1">
                                        <label className='cursor-pointer' onClick={() => checktickethistorydiv(ticketId)}>
                                            <img src="/images/DownRoundArrow.svg" className="img-fluid cursor-pointer pe-2" title="Show Ticket History" />
                                            Show Ticket History
                                        </label></div>
                                    <div className={`col-12 pt-3 ${isTicketHistoryVisible ? "" : "d-none"}`}>
                                        <div className="row">
                                            <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
                                            {(item.ticket_history.length != 0) ?
                                                item.ticket_history.map((tickethistory, j) => {
                                                    var returData;
                                                    returData = <div className="col-12 py-1" key={j}>
                                                        On <b>{MMDDYYYY(tickethistory.date)}</b>, {tickethistory.updateby} has changed the ticket status {tickethistory.to} to {tickethistory.from}
                                                    </div>
                                                    return returData;
                                                })
                                                :
                                                <div className="col-12 text-center">
                                                    <label className="pt-2">No Record Found</label>
                                                    <img src='/images/HorizontalLine.svg' className='img-fluid w-100 px-0' />
                                                </div>
                                            }
                                        </div>
                                    </div>



                                    <div className="col-12 ps-0 py-2">
                                        <label className='cursor-pointer' onClick={() => checkPartshistorydiv(ticketId)}>
                                            <img src="/images/DownRoundArrow.svg" className="img-fluid cursor-pointer pe-2" title="Show Parts History" />
                                            Show Parts History
                                        </label></div>
                                    <div className={`col-12 ${isPartHistoryVisibility ? "" : "d-none"}`}>
                                        <>
                                            {item.ticket_attachments.length != 0 ?
                                                <>
                                                    {
                                                        item.ticket_attachments.map((item, i) => {
                                                            var returData;
                                                            returData = (
                                                                <div className="row px-4" key={i}>
                                                                    <img src="/images/HorizontalLine.svg" className="img-fluid w-100 mt-2 ps-0" />
                                                                    <div className="col-12 p-1 pe-0 row mt-1">
                                                                        <div className="col-md-3 fw-600">Part Name : </div>
                                                                        <div className={`${item.part_covered_by == 1 ? 'highlight-part col-md-9' : 'col-md-9'}`}> {item?.part?.title + ' ' + '(' + (item.part_flag == 1 ? 'Master Part' : 'School Part') + ')'}</div>
                                                                    </div>
                                                                    <div className="col-12 p-1 pe-0 row mt-1">
                                                                        <div className="col-md-3 fw-600">Building : </div>
                                                                        <div className="col-md-9">  {item.building ? item.building?.name : '-'}</div>
                                                                    </div>
                                                                    <div className="col-12 p-1 pe-0 row mt-1">
                                                                        <div className="col-md-3 fw-600"> Price : </div>
                                                                        <div className="col-md-9">${item?.part_price.toFixed(2) || '0'}</div>
                                                                    </div>
                                                                    <div className="col-12 p-1 pe-0 row mt-1">
                                                                        <div className="col-md-3 fw-600">Notes : </div>
                                                                        <div className="col-md-9"> {item.part_note || '-'}</div>
                                                                    </div>
                                                                </div>
                                                            );
                                                            return returData;
                                                        })
                                                    }
                                                </>
                                                :
                                                <div className="col-12 pt-3 text-center">
                                                    <label>No Record Found</label>
                                                </div>
                                            }
                                        </>
                                    </div>

                                    <hr className="mb-0 py-2" />
                                </div>

                                );
                                return returData;
                            })}
                        </>
                        :
                        <div className="col-12 GridNoRecord text-center">
                            <label>No Record Found</label>
                        </div>
                    }
                </div>
            </div>
            <div className={`${ShowActive.userhistory}`}>
                <div>
                    <div className="col-12 greyBox p-3 my-3">
                        <table className="table">
                            <thead className="GridHeader">
                                <tr>
                                    <th scope="col" className="cursor-pointer fw-600 text-start">
                                        User Id
                                    </th>
                                    <th scope="col" className="cursor-pointer fw-600 text-start">
                                        User Name
                                    </th>
                                    <th scope="col" className="cursor-pointer fw-600 text-start">
                                        Allocated Date
                                    </th>
                                    <th scope="col" className="cursor-pointer fw-600 text-start">
                                        Vacant Date
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {UserHistoryDetails?.length != 0 ? (
                                    UserHistoryDetails?.map((item, i) => {
                                        return (
                                            <tr key={i} className="OddEvenClass tableBorderHide">
                                                <td className="py-12 text-start">
                                                    {item.student?.student_num || '-'}
                                                </td>
                                                <td className="py-12 text-start">
                                                    {item.student?.device_user_first_name} {item.student?.device_user_middle_name == null ? '' : item.student?.device_user_middle_name}  {item.student?.device_user_last_name}
                                                </td>
                                                <td className="py-12 text-start">
                                                    {item.allocated_date ? formatDateToMMDDYYYY(item.allocated_date) : <>-</>}
                                                </td>
                                                <td className="py-12 text-start">
                                                    {item.vacant_date ? formatDateToMMDDYYYY(item.vacant_date) : <>-</>}
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) :
                                    <tr>
                                        <td colspan={5} className="text-center">
                                            No Record Found
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
    return (
        <>
            {loading ? <ShowLoder /> : <HideLoder />}
            <Popup isshow={props.isShow} size={"lg"} title={"Device Details"}
                closePopup={props.ClosePopup} modalBody={modalbody} btntype={true}
                visibleSaveBtn={!DeviceDetails.deleted_at && UserData.MenuAccessFlag != 2 && props.Showbuttons == 1 && props.TabFlag != 2} handleSave={(e) => navigate("/addupdate-device/?id=" + props.deviceid)}
                btnText={"Edit Device"} ExtraText={props.createticketbutton == true && props.ticketid != null ? 'Edit Ticket' : 'Create Ticket'}
                handleSaveForExtra={(e) => props.createticketbutton == true && props.ticketid != null ? navigate('/create-ticket/?deviceid=' + props.deviceid + "&ticketid=" + props.ticketid) :
                    navigate('/create-ticket/?deviceid=' + props.deviceid)} extraBtn={!DeviceDetails.deleted_at && props.createticketbutton}
            />
        </>
    )
}
