import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiGetCall, ApiPostCall } from '../JS/Connector';
import { HideLoder, LogInSchoolID, LogInUserID, ShowLoder, typewatch } from '../JS/Common';
import AlertsComp from './AlertsComp';
import { UserContext } from '../App';
import { CheckValidation } from './Validations';
import UserDetailsPopup from './UserDetailsPopup';
import Deletepopup from './Deletepopup';
import AlertPopup from './AlertPopup';
import TicketDetailsPopup from './TicketDetailsPopup';
import { DeviceDetailsPopup } from './DeviceDetailsPopup';
import ReworkPopup from './ReworkPopup';

function RepairDetails() {
    const navigate = useNavigate();
    const location = useLocation();
    const TicketId = location.state && location.state.tabTicketId;
    const ticketTabFlag = location.state && location.state.ticketTabFlag;
    const workedonflag = location.state && location.state.workedonflag;
    const tabflag = location.state && location.state.tickettabflag;
    const schoolIdForAdmin = location.state && location.state.schoolid;
    const billingflag = location.state && location.state.billingflag;
    const FlagForParental = location.state && location.state.parentalCoverageFlag;
    const [AttachParts, setAttachParts] = useState([]);
    const [SuggestionBoxArray, setSuggestionBoxArray] = useState([]);
    const [repairFinished, setRepairFinished] = useState(false);
    const [MarkasCloseTicket, setMarkasCloseTicket] = useState('');
    const [vData, setvData] = useState([]);
    const [isTicketDetailspopup, setisTicketDetailspopup] = useState(false);
    const [DevicePopup, setDevicePopup] = useState(false);
    const [ReworkFlag, setReworkFlag] = useState(0);
    const suggestionBoxWhoWorkOnRef = useRef(null);
    const suggestionBoxRef = useRef(null);
    const [TicketData, setTicketData] = useState({
        TicketId: '',
        ShowSuggestion: 'd-none',
        RepairCharge: '',
        flag: 0,
        partDeleteFlag: 0
    });
    const [ShowDefaultImg, setShowDefaultImg] = useState(true)
    const [RepairNotes, setRepairNotes] = useState('')
    const [InventoryId, setInventoryId] = useState('')
    const [TicketSerialNum, setTicketSerialNum] = useState({
        SerialNum: "",
        TicketNum: "",
        Userid: ''
    })
    const [UserName, setUserName] = useState("")
    const [selectDamageId, setSelectDamageId] = useState(0);
    const [selectDeviceId, setSelectDeviceId] = useState(0);
    const [NewAlerts, setNewAlerts] = useState("");
    const [SearchForParts, setSearchForParts] = useState("");
    const [loading, setLoading] = useState(false);
    const { UserData } = useContext(UserContext);
    const [DeviceTypes, setDeviceTypes] = useState([]);
    const [DamangeTyppe, setDamangeTyppe] = useState([]);
    const [TicketStatus, setTicketStatus] = useState('');
    const [SearchForWhoWorkOn, setSearchForWhoWorkOn] = useState('')
    const [ShowSuggestionForWhoWorkOn, setShowSuggestionForWhoWorkOn] = useState('d-none')
    const [SuggestionBoxArrayForWhoWorkOn, setSuggestionBoxArrayForWhoWorkOn] = useState([]);
    const [TechnicianId, setTechnicianId] = useState('')
    const [ConfirmationModal, setConfirmationModal] = useState(false);
    const [ConfirmationCancelModal, setConfirmationCancelModal] = useState(false);
    const [UserModal, setUserModal] = useState(false);
    const [NumberOfClaim, setNumberOfClaim] = useState('')
    const [BillableFlag, setBillableFlag] = useState('');
    const [deletePopup, setDeletePopup] = useState(false);
    const [deletepartid, setdeletepartid] = useState('');
    const loggedinemailid = UserData.emailid;
    const [RepairLoder, setRepaiLoder] = useState(false);
    const [EnrollmentHistory, setEnrollmentHistory] = useState([]);
    const [ReworkModal, setReworkModal] = useState(false);
    const [ReworkMsg, setReworkMsg] = useState('');
    const [RepairTypeMsg, setRepairTypeMsg] = useState('');
    const [MarkasRepair, setMarkasRepair] = useState('');
    useEffect(() => {
        if (workedonflag == 1) {
            GetAllDeviceType();
        }
        GetAllDamangeType();
        GetTicketDataByID();
        const handleClickOutside = (event) => {
            if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
                setTicketData({ ...TicketData, ShowSuggestion: "d-none" })
            }
        };
        const handleClickOutsideWhoWorkOn = (event) => {
            if (suggestionBoxWhoWorkOnRef.current && !suggestionBoxWhoWorkOnRef.current.contains(event.target)) {
                setShowSuggestionForWhoWorkOn('d-none')
            }
        };
        const handleWindowClick = (event) => {
            handleClickOutside(event);
            handleClickOutsideWhoWorkOn(event);
        };
        window.addEventListener('click', handleWindowClick);
        return () => {
            window.removeEventListener('click', handleWindowClick);
        };
    }, []);
    async function GetRepairParts(searchString) {
        if (searchString == '') {
            setTicketData({ ...TicketData, ShowSuggestion: 'd-none' });
        }
        if (searchString == "" || searchString == undefined) {
            searchString = null;
        }
        if (workedonflag == 1) {
            var Schoolid = schoolIdForAdmin
        } else {
            var Schoolid = parseInt(LogInSchoolID)
        }
        setTicketData({ ...TicketData, ShowSuggestion: '' });
        setRepaiLoder(true)
        typewatch(async function () {
            if (workedonflag == 1) {
                var result = await ApiGetCall(`/getAllPartsForAdmin/${searchString}&${TicketId}`);
            } else {
                var result = await ApiGetCall(`/getAllParts/${Schoolid}&${searchString}&${TicketId}`);
            }
            if (!result) {
                alert("Something went wrong");
                setLoading(false);
                return;
            }
            setLoading(false);
            setRepaiLoder(false)
            const responseRs = JSON.parse(result);
            const sugData = responseRs.msg
            var vHtml = "";
            if (sugData.length != 0) {
                var vArray = [];
                for (var i = 0; i < sugData.length; i++) {
                    if (workedonflag == 1) {
                        vHtml =
                            <div className={`row brdr-Btm font-14 schoolid cursor-pointer p-Suggestion ${sugData[i].flag}`} onClick={(e) => showpartsdetails(e)} dataid={sugData[i].id} >
                                <div className="col-9 text-start">
                                    <b>{sugData[i].title}</b>
                                </div>
                                <div className="col-3 text-end font-13 vl">Master Part</div>
                            </div>
                    } else {
                        vHtml = responseRs.masterInventoryFlag == 1 ?
                            <div className={`row brdr-Btm font-14 schoolid cursor-pointer p-Suggestion ${sugData[i].flag}`} onClick={(e) => showpartsdetails(e)} dataid={sugData[i].id}>
                                <div className="col-9 text-start">
                                    <b>{sugData[i].title}</b>
                                </div>
                                {sugData[i].school_id == null ?
                                    <div className="col-3 text-end font-13 vl">Master Part</div>
                                    :
                                    <div className="col-3 text-end font-13 vl">School Part</div>
                                }
                            </div>
                            :
                            <>
                                {sugData[i].school_id !== null && (
                                    <div className={`row brdr-Btm font-14 schoolid cursor-pointer p-Suggestion ${sugData[i].flag}`} onClick={(e) => showpartsdetails(e)} dataid={sugData[i].id}>
                                        <div className="col-9 text-start">
                                            <b>{sugData[i].title}</b>
                                        </div>
                                        <div className="col-3 text-end font-13 vl">School Part</div>
                                    </div>
                                )}
                            </>
                    }
                    vArray.push(vHtml);
                }
                setSuggestionBoxArray(vArray);
            } else {
                vHtml = <div className="col-12 text-center">
                    <label>No Record Found</label>
                </div>
                setSuggestionBoxArray(vHtml);
            }
        }, 1000);
    };
    async function showpartsdetails(e) {
        var dataID = e.currentTarget.attributes[1].value;
        setSearchForParts('')
        await ApiGetCall("/getPartsById/" + dataID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setTicketData({ ...TicketData, ShowSuggestion: 'd-none' });
                setShowDefaultImg(false)
                AddNewRow(responseRs);
            }
        });
    }
    function handleDelete(id) {
        const vFinalId = id.split('_')[1];
        const updatedData = vData.filter((row) => {
            if (row.props && row.props.id) {
                return row.props.id !== vFinalId;
            }
            return row.id != vFinalId;
        });
        setvData(updatedData);
    }
    async function SaveRepairParts(e) {
        const updatedPartNotesArray = [];
        AttachParts.forEach(item => {
            const finalPartId = item.part_id;
            const notes = document.getElementById(`Notes_${finalPartId}`).value;
            const price = document.getElementById(`Price_${finalPartId}`).value;
            const partflag = item.part_flag;
            const partCoverBy = item.part_covered_by;
            updatedPartNotesArray.push({
                PartID: finalPartId,
                Notes: notes ? notes : null,
                Price: price ? price : 0,
                PartFlag: partflag,
                PartCoverBy: partCoverBy
            });
        });
        const actionArray = [];
        let totalPriceSum = 0;
        vData.map((item) => {
            const vid = item.id;
            const warrantyCheckbox = document.querySelector(`#WarrantyCheckbox_${vid}`);
            const warrantyFlag = warrantyCheckbox ? (warrantyCheckbox.checked ? 1 : 0) : null;
            var PartCoverFlag = null
            if (warrantyFlag == 1 && ReworkFlag == 1) {
                PartCoverFlag = 3;
            } else if (warrantyFlag == 0 && ReworkFlag == 1) {
                PartCoverFlag = 2;
            } else if (warrantyFlag == null && ReworkFlag == 1) {
                PartCoverFlag = 2;
            } else if (warrantyFlag == 1 && ReworkFlag == 0) {
                PartCoverFlag = 1;
            } else if (warrantyFlag == 0 && ReworkFlag == 0) {
                PartCoverFlag = 0;
            } else if (warrantyFlag == null && ReworkFlag == 0) {
                PartCoverFlag = null;
            }
            var vjson = {};
            vjson['Notes'] = document.querySelector(`#NotesId_${vid}`).value;
            vjson['PartID'] = parseInt(document.querySelector(`#hdnPartId_${vid}`).value);
            vjson['Price'] = document.querySelector(`#PartsPrice_${vid}`).value;
            vjson['PartFlag'] = item.flag === "schoolColorStatus" ? 2 : 1;
            vjson['PartCoverBy'] = PartCoverFlag;
            vjson['Building_id'] = item.building;
            totalPriceSum += vjson['Price'];
            actionArray.push(vjson);
        });

        const mergedArray = updatedPartNotesArray.concat(actionArray);

        var flagForAdminAndSchool = 0
        if (workedonflag == 1) {
            var Schoolid = schoolIdForAdmin;
            var flagForAdminAndSchool = 1
        } else {
            var Schoolid = parseInt(LogInSchoolID);
            var flagForAdminAndSchool = 2
        }

        const repairfinishflag = repairFinished ? 1 : 0;
        const MarkAsCloseflag = MarkasCloseTicket ? 1 : 0;
        var raw = JSON.stringify({
            SchoolID: Schoolid,
            LoginUserID: LogInUserID,
            TicketID: TicketId,
            RepairedItem: RepairNotes,
            DamageType: parseInt(selectDamageId),
            DeviceType: parseInt(selectDeviceId),
            Part: mergedArray,
            Flag: flagForAdminAndSchool,
            RepairFinished: repairfinishflag,
            MarkAsCloseflag: MarkAsCloseflag,
            TechnicianOrAdmin: TechnicianId ? TechnicianId : LogInUserID,
            markAsRework: MarkasRepair,
            ...(workedonflag == 0 && { BillableFlag: BillableFlag }),
        });
        setLoading(true)
        await ApiPostCall("/RepairTagPopUpData", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = result;
                setConfirmationModal(false)
                setLoading(false)
                if (responseRs == "success") {
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Data Added Successfully"} />);
                    setTimeout(function () {
                        if (workedonflag == 0) {
                            navigate('/manage-tickets')
                        } else {
                            navigate('/school-tickets')
                        }
                    }, 1500);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }
    async function GetTicketDataByID() {
        await ApiGetCall("/getTicketDataById/" + TicketId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                var sugdata = responseRs.ticket;
                if (responseRs.response == "success") {
                    setTicketSerialNum({
                        ...TicketSerialNum, SerialNum: sugdata.inventory_management.serial_number, TicketNum: sugdata.ticket_num, TicketID: sugdata.ID, Userid: sugdata?.Student?.id
                    })
                    setInventoryId(sugdata?.inventory_id)
                    if (sugdata?.Student != null) {
                        setUserName((sugdata?.Student?.device_user_first_name || '') + ' ' + (sugdata?.Student?.device_user_middle_name || "") + ' ' + (sugdata?.Student?.device_user_last_name || ''))
                    }
                    setMarkasRepair(sugdata.mark_as_rework)
                    setReworkFlag(sugdata.rework_status)
                    setTicketStatus(sugdata.Status)
                    if (sugdata.ticket_repair_log.length != 0) {
                        setTicketData({ ...TicketData, partDeleteFlag: sugdata.ticketUsedInInvoiceFlag, RepairCharge: sugdata.ticket_repair_log[0].price })
                        setRepairNotes(sugdata.ticket_repair_log[0].repaired_item)
                        setSelectDamageId(sugdata.ticket_repair_log[0].damage_type)
                        setSelectDeviceId(sugdata.ticket_repair_log[0].device_type?.id)
                        setSearchForWhoWorkOn(sugdata.ticket_repair_log[0].repair_tech.first_name + ' ' + sugdata.ticket_repair_log[0].repair_tech.last_name)
                    } else {
                        setTicketData({ ...TicketData, partDeleteFlag: sugdata.ticketUsedInInvoiceFlag, RepairCharge: '' })
                        setSelectDamageId(0)
                        setSelectDeviceId(0)
                        setRepairNotes('')
                    }
                    if (sugdata.ticket_attachments.length != 0) {
                        setAttachParts(sugdata.ticket_attachments)
                    } else {
                        setAttachParts([]);
                    }
                    if (sugdata.Status == 'Repair Finished') {
                        setRepairFinished(true)
                    }
                    setNumberOfClaim(responseRs?.parentalDetails?.numberofClaim)
                    setBillableFlag(sugdata?.chargeability);
                    setEnrollmentHistory(responseRs?.parentalDetails?.enrollmentHistory)
                }
            }
        });
    }
    async function deletePart(partId) {
        await ApiPostCall("/removeAttachedPart/" + TicketId + "&" + partId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = result;
                setDeletePopup(false);
                if (responseRs == "success") {
                    setAttachParts(AttachParts.filter(part => part.part_id !== partId));
                    setNewAlerts(<AlertsComp show={true} variant="success" msg={"Part Deleted Successfully"} />);
                    setTimeout(function () {
                        setNewAlerts(<AlertsComp show={false} />)
                        GetTicketDataByID();
                    }, 1500);
                } else if (responseRs == "error") {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={"Unable to delete this part. It is associated with an existing invoice"} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2500);
                } else {
                    setNewAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setNewAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }
    function handleRepairFinished(event) {
        setRepairFinished(event.target.checked);
    }
    function handleMarkasCloseTicket(event) {
        setMarkasCloseTicket(event.target.checked);
    }
    async function GetAllDeviceType() {
        await ApiGetCall("/allDevice").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setDeviceTypes(responseRs);
            }
        });
    }
    async function GetAllDamangeType() {
        await ApiGetCall("/DamageType").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setDamangeTyppe(responseRs);
            }
        });
    }
    function handleKeyDown(event) {
        const searchValue = event.target.value.trim();
        if (searchValue !== "" && (event.key === "Enter" || event.keyCode === 13)) {
            event.preventDefault();
            GetRepairParts(searchValue)
            return false;
        }
    }
    async function GetTechniciansBySchoolLocationData(searchString) {
        setLoading(true);
        if (searchString == "") {
            searchString = null;
        }
        setShowSuggestionForWhoWorkOn('')
        await ApiGetCall('/GetTechnociansBySchoolLocation/' + schoolIdForAdmin + '&' + searchString).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);;
                var sugData = responseRs.msg;
                var sugArray = [];
                var i = 1;
                if (responseRs.response == "success") {
                    if (sugData?.length != 0) {
                        for (var i = 0; i < sugData?.length; i++) {
                            if (sugData[i]?.techName) {
                                sugArray.push(
                                    <div className="col-md-12 justify-content-between brdr-Btm font-14" key={i} onClick={(e) => SetWhoWorkOnValue(e)} techID={sugData[i]?.techID} techName={sugData[i]?.techName} style={{ padding: "4px 10px" }}>
                                        <div className='text-start'><b>{sugData[i]?.techName}</b></div>
                                        <div className='text-end'>{sugData[i]?.techEmail}</div>
                                    </div>
                                )
                            }
                        }
                        setSuggestionBoxArrayForWhoWorkOn(sugArray)
                    } else {
                        sugArray.push(
                            <div className="col-12 text-center" key="no-record">
                                <label>No Record Found</label>
                            </div>
                        );
                        setSuggestionBoxArrayForWhoWorkOn(sugArray)
                    }
                }

            }
        })
    }
    function SetWhoWorkOnValue(e) {
        var techId = parseInt(e.currentTarget.attributes[1].value);
        var TechName = e.currentTarget.attributes[2].value;
        setSearchForWhoWorkOn(TechName)
        setTechnicianId(techId)
        setShowSuggestionForWhoWorkOn('d-none')
    }
    function saveDetailsCall() {
        var isFormValid = CheckValidation({ formID: 'validationClass' });
        if (workedonflag == 1) {
            if (selectDeviceId == 0 || selectDeviceId == undefined || selectDeviceId == null) {
                setRepairTypeMsg("*required");
                isFormValid = false;
            } else {
                setRepairTypeMsg("");
            }
        } else {
            if (selectDamageId == 0 || selectDamageId == undefined || selectDamageId == null) {
                setRepairTypeMsg("*required");
                isFormValid = false;
            } else {
                setRepairTypeMsg("");
            }
        }
        if (workedonflag == 1) {
            if (ReworkFlag) {
                if (MarkasRepair == 0) {
                    setReworkMsg('*required');
                    isFormValid = false;
                }
            }
        }
        if (!isFormValid) return;
        setConfirmationModal(true)
    }
    function AddNewRow(responseRs) {
        const idExists = vData.some(item => item.id === responseRs.id);
        if (!idExists) {
            var warranty = null;
            if (selectDeviceId == 6 && workedonflag == 1) {
                warranty = true;
            } else {
                warranty = false;
            }
            let price;

            if (FlagForParental == 1) {
                if (EnrollmentHistory?.enrollment_status == 2) {
                    price = responseRs.variant_price;
                } else {
                    price = 0;
                }
            } else if (warranty == true) {
                price = 0;
            } else if (ReworkFlag == 1) {
                price = 0;
            } else {
                price = responseRs.variant_price;
            }
            const newData = {
                id: responseRs.id,
                partId: `hdnPartId_${responseRs.id}`,
                variantPrice: `PartsPrice_${responseRs.id}`,
                notesId: `NotesId_${responseRs.id}`,
                flag: responseRs.flag,
                title: responseRs.title,
                variantPriceValue: price,
                originalPrice: responseRs.variant_price,
                warrantyCheckbox: warranty,
                qty: responseRs.quantity,
                building: responseRs.building,
                building_part: responseRs.building_part
            };
            setvData([...vData, newData]);
        }
    }
    function handleRepairTypeChange(devicetypeid) {
        setSelectDeviceId(devicetypeid)
        if (devicetypeid == 6) {
            if (vData.length > 0) {
                const updatedVData = vData.map(item => {
                    return { ...item, warrantyCheckbox: true, variantPriceValue: 0 };
                });
                setvData(updatedVData);
            }
        } else if (ReworkFlag == 1) {
            if (vData.length > 0) {
                const updatedVData = vData.map(item => {
                    return { ...item, warrantyCheckbox: false, variantPriceValue: 0 };
                });
                setvData(updatedVData);
            }
        }
        else {
            if (vData.length > 0) {
                const updatedVData = vData.map(item => {
                    return { ...item, warrantyCheckbox: false, variantPriceValue: FlagForParental == 1 ? 0 : item.originalPrice };
                });
                setvData(updatedVData);
            }
        }
    }
    function handleReworkChange(flag) {
        setMarkasRepair(flag);
        if (flag == 1) {
            if (vData.length > 0) {
                const updatedVData = vData.map(item => {
                    return { ...item, warrantyCheckbox: true, variantPriceValue: 0 };
                });
                setvData(updatedVData);
            }
        } else {
            if (vData.length > 0) {
                const updatedVData = vData.map(item => {
                    return { ...item, warrantyCheckbox: false, variantPriceValue: FlagForParental == 1 ? 0 : item.originalPrice };
                });
                setvData(updatedVData);
            }
        }
    }
    const handleCheckboxChange = (partId, responseRs, e) => {
        var checked = e.target.checked;
        const updatedVData = vData.map(item => {
            if (item.id == partId) {
                return { ...item, warrantyCheckbox: checked, variantPriceValue: checked == true ? 0 : item.originalPrice };
            }
            return item;
        });
        setvData(updatedVData);
    };
    const handlePartCostChange = (e, partId) => {
        let value = e.target.value;
        value = value.replace(/^0+/, '');
        if (!value) {
            value = '0';
        }
        const updatedVData = vData.map(item => {
            if (item.id == partId) {
                return { ...item, variantPriceValue: value };
            }
            return item;
        });
        setvData(updatedVData);
    };
    const handleBuildingChange = (e, partId) => {
        const value = e.target.value;
        const updatedVData = vData.map(item => {
            if (item.id == partId) {
                return { ...item, building: value };
            }
            return item;
        });
        setvData(updatedVData);
    }
    useEffect(() => {
        setAttachParts(AttachParts);
    }, [AttachParts]);
    return (
        <>
            {NewAlerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row align-items-center pe-0'>
                <div className='col-5'>
                    <h1 className="PageHeading mb-0">Repair details for the device</h1>
                </div>
                <div className='col-7 text-end h5 mb-0'>
                    {FlagForParental == 1 ? (
                        EnrollmentHistory?.enrollment_status == 2 &&
                        <div className='blueText fs-6 fw-600 mb-2'>Coverage Plan Expired</div>
                    ) : (
                        <></>
                    )}
                    {FlagForParental == 1 ?
                        (
                            <>
                                <span className={`${NumberOfClaim > 1 ? 'redText fw-600' : ''}`}>No of Claim : <b>{NumberOfClaim || '0'}</b></span>
                                <b>,&nbsp;</b>
                            </>
                        ) : (
                            <></>
                        )}
                    &nbsp;
                    <span>{UserName ? <b><u className='cursor-pointer' onClick={(e) => setUserModal(true)}>{UserName}</u></b> : <></>}</span>
                </div>
            </div >
            <div className="GridBox px-3 pb-3 mt-3 pt-2">
                <div className='row pb-2 align-items-center'>
                    <div className='col-md-6 d-flex align-items-center'>
                        <span className='ps-2'><b>Who Worked On:  </b></span>
                        {workedonflag == 1 && UserData.MenuAccessFlag != 6 ?
                            <div className='ms-3 col-md-7'>
                                <form className="gridsearchbar">
                                    <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
                                    <input className="form-control" autoComplete="off" type="text" placeholder="Search"
                                        value={SearchForWhoWorkOn}
                                        onChange={(e) => { setSearchForWhoWorkOn(e.target.value); GetTechniciansBySchoolLocationData(e.target.value) }}
                                        onKeyUp={(e) => { GetTechniciansBySchoolLocationData(e.target.value); setSearchForWhoWorkOn(e.target.value) }}
                                    />
                                    <div className={`SuggestionBoxDiv ${ShowSuggestionForWhoWorkOn}`} ref={suggestionBoxWhoWorkOnRef} style={{ zIndex: 1 }}>
                                        {SuggestionBoxArrayForWhoWorkOn}
                                    </div>
                                </form>
                            </div>
                            : <span className='ps-3'>{loggedinemailid}</span>
                        }

                    </div>
                    <div className='col-md-6 text-end align-items-center'>
                        <b>Device:</b> <u className='cursor-pointer' onClick={(e) => setDevicePopup(true)}>{TicketSerialNum.SerialNum}</u>&nbsp; ,<b>Ticket #: </b> <u className='cursor-pointer' onClick={(e) => setisTicketDetailspopup(true)}>{TicketSerialNum.TicketNum}</u>
                    </div>
                </div>

                <div className="row">
                    {/* Ticket Details Div */}
                    <div className="col-lg-7 pl-2">
                        <div className="greyBox parent" id='validationClass'>
                            <div className='row mt-2'>
                                <div className="col-md-3 pe-0 mb-2 FormLabel fw-600">Repair Type<label className='redText'>*</label> :</div>
                                <div className='col-md-9 row'>
                                    {workedonflag == 1 ?
                                        <>
                                            {DeviceTypes.map((devicetype, j) => {
                                                return (
                                                    <div className="col-md-6 pb-1 px-0 d-flex" key={devicetype.id}>
                                                        <input
                                                            className="form-check-input repairtechIssueClass me-2"
                                                            checked={selectDeviceId === devicetype.id}
                                                            type="radio"
                                                            name="LoanerDevice"
                                                            onChange={() => handleRepairTypeChange(devicetype.id)}
                                                        />
                                                        <label className="form-check-label font-13">{devicetype.type}</label>
                                                    </div>
                                                );
                                            })}

                                        </>
                                        :
                                        <>
                                            {DamangeTyppe.map((damagetype, j) => {
                                                return (
                                                    <div className="col-md-6 pb-1" key={damagetype.id}>
                                                        <input
                                                            className="form-check-input repairtechIssueClass me-2"
                                                            checked={selectDamageId === damagetype.id}
                                                            type="radio"
                                                            name="LoanerDevice"
                                                            onChange={() => setSelectDamageId(damagetype.id)}
                                                        />
                                                        <label className="form-check-label font-13">{damagetype.damage_type}</label>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    }
                                </div>
                                <span className='Emailinvalid'>{RepairTypeMsg}</span>
                            </div>

                            <div className="col-12 mt-2">
                                <div className="row align-items-center">
                                    <div className="col-md-3 FormLabel fw-600">Repair Notes<label className='redText'>*</label> :</div>
                                    <div className="col-md-9 ps-0">
                                        <textarea type="textarea" rows={2} autoComplete="off" name="RepairedNm" className="form-control" required value={RepairNotes} onChange={(e) => setRepairNotes(e.target.value)} />
                                        <span className="form-text invalid-feedback">
                                            *required
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex mt-2 FormLabel fw-600'>Has Parental Coverage? &nbsp;<label className='fw-600 ps-2' style={{ color: '#16ad93' }}>{FlagForParental == 1 ? EnrollmentHistory?.enrollment_status == 2 ? (<>Yes <span className='blueText'>(Plan expired)</span></>) : 'Yes' : 'No'}</label></div>
                            {workedonflag == 1 && (
                                <>
                                    <div className='col-12 d-flex mt-2'>
                                        <b className='FormLabel fw-600'> Is this a repeat of the previous ticket's issue?</b>
                                        <div className='ps-3 d-flex align-items-center'>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name='billable'
                                                    onChange={(e) => { setMarkasRepair(1); handleReworkChange(1) }}
                                                    checked={MarkasRepair == 1}
                                                />
                                                <label className="form-check-label">Yes</label>
                                            </div>
                                            <div className="form-check ms-5">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name='billable'
                                                    onChange={(e) => { setMarkasRepair(2); handleReworkChange(2) }}
                                                    checked={MarkasRepair == 2}
                                                />
                                                <label className="form-check-label">No</label>
                                            </div>
                                            <img src="/images/InfoBtn.svg" title="Show Details" className="ps-3 cursor-pointer" height='16px'
                                                onClick={(e) => setReworkModal(true)} />
                                        </div>
                                    </div>
                                    <span className='Emailinvalid'>{ReworkMsg}</span>
                                </>
                            )}

                            {workedonflag == 0 && (
                                <div className='col-12 d-flex mt-2'>
                                    <b className='FormLabel fw-600'> Would you like to mark this ticket as billable to the student?</b>
                                    <div className='ps-3 d-flex align-items-center'>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name='billable'
                                                onChange={(e) => setBillableFlag(1)}
                                                checked={BillableFlag == 1}
                                            />
                                            <label className="form-check-label">Yes</label>
                                        </div>
                                        <div className="form-check ms-5">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name='billable'
                                                onChange={(e) => setBillableFlag(0)}
                                                checked={BillableFlag != 1}
                                            />
                                            <label className="form-check-label">No</label>
                                        </div>
                                    </div>
                                </div>)}
                        </div>
                        {/* school parts and master parts inputs */}
                        <div className="greyBox my-3" >
                            <div className="col-md-12 px-1 d-flex">
                                {billingflag != 1 && (
                                    <div className="col-md-12 mt-2">
                                        <div className="col-12 pb-2 font-12" style={{ color: "#4C5258" }}>
                                            Search for the specific part in either our inventory or the master list, and attach it to the ticket. The visibility of master parts depends on your selection of 'yes' or 'no' in the settings screen.
                                        </div>
                                        <form className="gridsearchbar" >
                                            <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon">
                                                <i className="bi bi-search"></i>
                                            </div>
                                            <input className="form-control" autoComplete="off" type="text" placeholder="Search the necessary repair part with the ticket"
                                                value={SearchForParts} onKeyDown={(e) => { handleKeyDown(e); setSuggestionBoxArray([]) }} onChange={(e) => { setSearchForParts(e.target.value); setSuggestionBoxArray([]) }}
                                                onKeyUp={(e) => { GetRepairParts(e.target.value); setSearchForParts(e.target.value); setSuggestionBoxArray([]) }} />
                                            <div className={`SuggestionBoxDiv ${TicketData.ShowSuggestion}`} style={{ zIndex: 1, height: '480px' }} ref={suggestionBoxRef}>
                                                {RepairLoder == true ?
                                                    <div className='row'><div className="col-12 text-center pt-2"><img src="/images/loder.svg" className="" style={{ width: "30px" }} /></div></div>
                                                    :
                                                    SuggestionBoxArray
                                                }
                                            </div>
                                        </form>
                                    </div>
                                )}
                            </div>
                            {ShowDefaultImg == true && (
                                <div className='text-center py-5'>
                                    <img src="/images/repairPortalImgxSmall.svg" className="img-fluid" style={{ height: "265px" }} />
                                </div>
                            )}
                            {vData.map((responseRs) => (
                                <>
                                    <img src="/images/HorizontalLine.svg" className="img-fluid w-100 my-2 postclass" />
                                    <div className="row RowClass pt-1" id={`MainRow_${responseRs.id}`}>
                                        <input type="hidden" id={responseRs.partId} value={responseRs.id} />
                                        <div className={`col-md-11 font-14 ${responseRs.flag}`}>
                                            {responseRs.title}
                                            {responseRs.flag === 'schoolColorStatus' ?
                                                <>(School Part)</>
                                                : <>(Master Part)</>}
                                        </div>
                                        <div className="col-md-1">
                                            <img
                                                src="/images/CancelIcon.svg"
                                                title="Delete Part"
                                                className="img-fluid cursor-pointer"
                                                onClick={() => handleDelete(`MainRow_${responseRs.id}`)}
                                            />
                                        </div>
                                        {workedonflag == 0 &&
                                            <div className="col-md-12 row pt-3">
                                                <div className="col-md-3 mt-2 FormLabel fw-600">Building</div>
                                                <div className="col-md-6 ps-3">
                                                    {responseRs.building_part.length > 0 ?
                                                        <select className="form-select" onChange={(e) => handleBuildingChange(e, responseRs.id)}>
                                                            <option value="0">Select Building</option>
                                                            {responseRs.building_part.map((building, i) => {
                                                                return <option value={building.building_id} key={i}>{building?.building_details?.name}</option>
                                                            })}
                                                        </select>
                                                        :
                                                        <>-</>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className="col-12 py-3 row">
                                            <div className="col-md-6 row pe-0 ">
                                                <div className="col-md-6 mt-2 FormLabel fw-600">Part Cost<label className='redText'>*</label></div>
                                                <div className="col-md-6 px-0 ps-4">
                                                    <div className="input-group">
                                                        <span className="input-group-text">$</span>
                                                        <input
                                                            type="number"
                                                            autoComplete="off"
                                                            className="form-control"
                                                            name="PartsPrice"
                                                            id={`PartsPrice_${responseRs.id}`}
                                                            value={responseRs.variantPriceValue}
                                                            required
                                                            onChange={(e) => handlePartCostChange(e, responseRs.id)}
                                                            min={0}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 row px-4 py-1">
                                                {selectDeviceId == 6 && workedonflag == 1 && (
                                                    <span>
                                                        <input
                                                            className="form-check-input mt-1 cursor-pointer"
                                                            type="checkbox"
                                                            id={`WarrantyCheckbox_${responseRs.id}`}
                                                            checked={responseRs.warrantyCheckbox}
                                                            onChange={(e) => handleCheckboxChange(responseRs.id, responseRs, e)}
                                                        /> (Under Warranty)
                                                    </span>
                                                )}
                                            </div>
                                            {responseRs.flag === 'schoolColorStatus' ?
                                                <div className="col-md-6 row pe-0 pt-3">
                                                    <div className="col-md-6 mt-2 FormLabel fw-600">Available Qty</div>
                                                    <div className="col-md-6 px-0 ps-2">
                                                        <input type="text" autoComplete="off" name="Quanitty" className="form-control ms-3 text-center"
                                                            value={responseRs.qty} disabled />
                                                    </div>
                                                </div>
                                                : <></>}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3 FormLabel fw-600"> Notes</div>
                                            <div className='col-md-9'><textarea className="form-control " rows={1} id={`NotesId_${responseRs.id}`}></textarea> </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                    </div>
                    {/* Attach Parts Div */}
                    <div className="col-lg-5 pl-2">
                        <div className="greyBox h-100">
                            <div className="d-flex col-md-12 mt-2">
                                <h5 className="fw-600 px-1">Attached Parts</h5>
                            </div>
                            <div className='d-flex justify-content-between font-13'>
                                <label className='highlight-part'>*Warranty</label>
                                <label className='redText'>*Rework</label>
                                <label className='blueText'>*Rework & Warranty</label>
                            </div>
                            <hr />
                            <div className="scrollbar-css-repairdetails row">
                                {AttachParts.length != 0 ?
                                    <>
                                        {AttachParts.map((item, i) => {
                                            var returData;
                                            returData = (
                                                <div className="row" key={item.part_id} partId={item.part_id}>
                                                    <div className='col-11'>
                                                        <div className="row align-items-center p-1">
                                                            <div className='col-md'>
                                                                <b>Part Name : </b>
                                                            </div>
                                                            <div className={`col-md ${item.part_covered_by == 1 ? 'highlight-part' : item.part_covered_by == 2 ? 'redText' : item.part_covered_by == 3 ? 'blueText' : 'col-md'}`}>
                                                                <span>{item.part_name + ' ' + '(' + (item.part_flag == 1 ? 'Master Part' : 'School Part') + ')'}</span>
                                                            </div>

                                                        </div>
                                                        <div className="row align-items-center px-1 py-2">
                                                            <div className='col'>
                                                                <b>Building : </b>
                                                            </div>
                                                            <div className="col px-0">
                                                                <span>
                                                                    {item.building ? item.building?.name : '-'}
                                                                </span>
                                                            </div>

                                                        </div>
                                                        <div className="row align-items-center px-1 py-2">
                                                            <div className='col'>
                                                                <b>Created By : </b>
                                                            </div>
                                                            <div className="col px-0">
                                                                <span>
                                                                    {item.user_name == "null" || item.user_name == "" || item.user_name == null ? (
                                                                        <>-</>
                                                                    ) : (
                                                                        <>{item.user_name}</>
                                                                    )}
                                                                </span>
                                                            </div>

                                                        </div>
                                                        {item.part_flag != 1 && (
                                                            <div className="row align-items-center px-1 py-2">
                                                                <div className='col'>
                                                                    <b>Quantity : </b>
                                                                </div>
                                                                <div className="col px-0">
                                                                    <span>
                                                                        {item.quantity == null ? (
                                                                            <>0</>
                                                                        ) : (
                                                                            <>{item.quantity}</>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="row align-items-center p-1">
                                                            <div className='col pe-0'>
                                                                <b>Part Price : </b>
                                                            </div>
                                                            <div className="col px-0">
                                                                <div className="input-group">
                                                                    <span className="input-group-text">$</span>
                                                                    {billingflag != 1 ?
                                                                        <input className="form-control" autocomplete="off" type="number" name="notes" placeholder="Part Price"
                                                                            id={`Price_${item.part_id}`} defaultValue={item.part_price.toFixed(2)}
                                                                            onChange={e => {
                                                                                let value = e.target.value;
                                                                                value = value.replace(/^0+/, '');
                                                                                if (!value) {
                                                                                    value = "00";
                                                                                }
                                                                                e.target.value = value;
                                                                            }}
                                                                            min={0} />
                                                                        :
                                                                        <input className="form-control" autocomplete="off" type="number" name="notes" placeholder="Part Price"
                                                                            id={`Price_${item.part_id}`} defaultValue={item.part_price.toFixed(2)}
                                                                            onChange={e => {
                                                                                let value = e.target.value;
                                                                                value = value.replace(/^0+/, '');
                                                                                if (!value) {
                                                                                    value = "00";
                                                                                }
                                                                                e.target.value = value;
                                                                            }}
                                                                            min={0} disabled />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row p-1 align-items-center">
                                                            <div className="col pe-0">
                                                                <b>Notes : </b>{" "}
                                                            </div>
                                                            <div className="col px-0">
                                                                <span>
                                                                    <input className="form-control" autocomplete="off" type="text" name="notes" placeholder="Notes"
                                                                        id={`Notes_${item.part_id}`} defaultValue={item.part_note} />
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='col-1 pe-0'>
                                                        {billingflag != 1 && (
                                                            <div className='col-12 text-end pt-2'>
                                                                <img src="/images/DeleteIcon.svg" title="Delete Part" className="img-fluid cursor-pointer"
                                                                    onClick={() => { setDeletePopup(true); setdeletepartid(item.part_id) }}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>


                                                    <div className='col-md-12 text-center'><hr className='m-2' /></div>
                                                </div>
                                            );
                                            return returData;
                                        })}
                                    </>
                                    :
                                    <div className="col-12 text-center">
                                        <label>No Record Found</label>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div >
                {workedonflag == 1 && (
                    <div className="col-md-12 text-center mt-2 py-3 justify-content-center align-items-center">
                        {tabflag == 3 && TicketStatus != 'Repair Billed' ?
                            <div className='d-flex align-items-center justify-content-center'>
                                <input checked className="form-check-input m-0" type="checkbox" name="allSelect" onChange={handleRepairFinished} /><span className='fw-600 ps-3' style={{ fontSize: '17px' }}> Mark as 'Repair Finished'</span>
                            </div> :
                            <div className='d-flex align-items-center justify-content-center'>
                                <input checked={repairFinished} className="form-check-input m-0" type="checkbox" name="allSelect" onChange={handleRepairFinished} /><span className='fw-600 ps-3' style={{ fontSize: '17px' }}> Mark as 'Repair Finished'</span>
                            </div>
                        }
                    </div>
                )}
                {workedonflag == 0 && ticketTabFlag == 1 || workedonflag == 0 && ticketTabFlag == 3 ?
                    <> <div className='col-12 d-flex align-items-center justify-content-center py-2'>
                        <input checked={MarkasCloseTicket} className="form-check-input m-0" type="checkbox" name="allSelect" onChange={handleMarkasCloseTicket} /><span className='fw-600 ps-3' style={{ fontSize: '17px' }}> Mark as 'Close Ticket'</span>
                    </div></>
                    :
                    <></>}
                <div className='col-12 text-center py-2'>
                    <button className="SaveBtn mt-2" onClick={(e) => saveDetailsCall()}>Save Details</button>
                    <label className="ms-2 cursor-pointer" onClick={(e) => setConfirmationCancelModal(true)}><u>Cancel</u></label>
                </div>
            </div >
            <AlertPopup isshow={ConfirmationModal} img={'/images/greenInfo.svg'}
                headingtext={'Proceed to save?'} text={workedonflag == 1 &&
                    !repairFinished ? <label className='text-danger'>You have not marked this ticket as Repair Finished.</label> : ''} cancelcall={(e) => setConfirmationModal(false)}
                canceltext={"No"} successtext={"Yes"} successcall={(e) => SaveRepairParts(e)} btntype={'SaveBtn'} />

            <AlertPopup isshow={ConfirmationCancelModal} img={'/images/redInfo.svg'}
                headingtext={'Confirm to cancel changes?'} text={''} cancelcall={(e) => setConfirmationCancelModal(false)}
                canceltext={"No"} successtext={"Yes"} successcall={(e) => { workedonflag == 0 ? navigate('/manage-tickets') : navigate('/school-tickets') }}
                btntype={'deleteBtn'} />

            {UserModal == true && (
                <UserDetailsPopup isShow={UserModal} ClosePopup={(e) => setUserModal(false)} userid={TicketSerialNum.Userid} />
            )}

            <Deletepopup text={`You won't be able to revert this!`} deletePopup={deletePopup} size="xl" deletePart={(e) => deletePart(deletepartid)} DeleteShowClose={(e) => setDeletePopup(false)} />
            {isTicketDetailspopup == true && (
                <TicketDetailsPopup isShow={isTicketDetailspopup} ticketid={TicketId} ClosePopup={(e) => setisTicketDetailspopup(false)} />
            )}
            {DevicePopup == true && (
                <DeviceDetailsPopup Showbuttons={1} createticketbutton={false} deviceid={InventoryId} activetab="device" isShow={DevicePopup} ClosePopup={(e) => setDevicePopup(false)} />
            )}
            {ReworkModal == true && (
                <ReworkPopup isShow={ReworkModal} ClosePopup={(e) => setReworkModal(false)} ticketid={TicketId} />
            )}
        </>
    )
}

export default RepairDetails
