import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HideLoder, LogInSchoolID, MMDDYYYY, ShowLoder } from '../../JS/Common';
import { ApiGetCall, ApiPostCall } from '../../JS/Connector';
import HdTypeModal from './HdTypeModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCopy, faTimes } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import UpdateCategoryModal from './UpdateCategoryModal';
import Deletepopup from '../../Components/Deletepopup';
import AlertsComp from '../../Components/AlertsComp';
import { UserContext } from '../../App';
import { MDBSwitch } from 'mdb-react-ui-kit';
import Popup from '../../Components/Popup';
import AlertPopup from '../../Components/AlertPopup';

const ExtendExistingJson = () => {
    var vJson = {
        "Schoolid": LogInSchoolID,
        "Categories": [
            {
                "Has_subcategory": true,
                "name": "Device/Hardware",
                "Description": "",
                "Icon": "",
                "typename": "Technology",
                "Subcategory": [
                    {
                        "value": "Lenovo 100e G3",
                        "issues": [
                            {
                                "value": "Login Issues",
                                "description": "<p>Can you try to logout completely, force-close all applications, and try to login again? If that does not work, can you try to restart your device once and try again?</p>",
                                "icon": ""
                            },
                            {
                                "value": "Wifi Issues",
                                "description": "<p>Can you try to forget all paired wifi connections and try to add a new connection from the beginning?</p>",
                                "icon": ""
                            },
                            {
                                "value": "Wont Turn On",
                                "description": "<p>Have you made sure that there is enough charging in the device for it to turn on? Can you try charging it before restarting again?</p>",
                                "icon": ""
                            }
                        ],
                        "Has_subcategory": false,
                        "description": "",
                        "icon": "",
                        "SubCategoryVisibility": false
                    },
                    {
                        "value": "iPad",
                        "issues": [
                            {
                                "value": "Physical Break",
                                "description": "",
                                "icon": ""
                            }
                        ],
                        "Has_subcategory": false,
                        "description": "",
                        "icon": "",
                        "SubCategoryVisibility": false
                    }
                ]
            },
            {
                "Has_subcategory": false,
                "name": "Software/Online Systems",
                "Description": "",
                "Icon": "",
                "typename": "Technology",
                "Subcategory": []
            },
            {
                "Has_subcategory": false,
                "name": "Network/Wifi",
                "Description": "",
                "Icon": "",
                "typename": "Technology",
                "Subcategory": []
            },
            {
                "Has_subcategory": false,
                "name": "User Accounts/Access",
                "Description": "",
                "Icon": "",
                "typename": "Technology",
                "Subcategory": []
            },
            {
                "Has_subcategory": false,
                "name": "Other",
                "Description": "",
                "Icon": "",
                "typename": "Technology",
                "Subcategory": []
            },
            {
                "Has_subcategory": false,
                "name": "Heating and Cooling",
                "Description": "",
                "Icon": "",
                "typename": "Maintenance",
                "Subcategory": []
            },
            {
                "Has_subcategory": false,
                "name": "Athletic Courts/Fields/Grounds",
                "Description": "",
                "Icon": "",
                "typename": "Maintenance",
                "Subcategory": []
            },
            {
                "Has_subcategory": false,
                "name": "Plumbing",
                "Description": "",
                "Icon": "",
                "typename": "Maintenance",
                "Subcategory": []
            },
            {
                "Has_subcategory": false,
                "name": "Carpentry",
                "Description": "",
                "Icon": "",
                "typename": "Maintenance",
                "Subcategory": []
            },
            {
                "Has_subcategory": false,
                "name": "Pest Control",
                "Description": "",
                "Icon": "",
                "typename": "Maintenance",
                "Subcategory": []
            },
            {
                "Has_subcategory": false,
                "name": "Kitchen Equipment",
                "Description": "",
                "Icon": "",
                "typename": "Maintenance",
                "Subcategory": []
            },
            {
                "Has_subcategory": false,
                "name": "Windows and Doors",
                "Description": "",
                "Icon": "",
                "typename": "Maintenance",
                "Subcategory": []
            },
            {
                "Has_subcategory": false,
                "name": "Furniture/Equipment Request",
                "Description": "",
                "Icon": "",
                "typename": "Maintenance",
                "Subcategory": []
            },
            {
                "Has_subcategory": false,
                "name": "Request to Move Items",
                "Description": "",
                "Icon": "",
                "typename": "Maintenance",
                "Subcategory": []
            },
            {
                "Has_subcategory": false,
                "name": "Report Vandalism",
                "Description": "",
                "Icon": "",
                "typename": "Maintenance",
                "Subcategory": []
            },
            {
                "Has_subcategory": false,
                "name": "Other",
                "Description": "",
                "Icon": "",
                "typename": "Maintenance",
                "Subcategory": []
            }
        ],
        "HdTypes": [
            {
                "name": "Technology",
                "description": ""
            },
            {
                "name": "Maintenance",
                "description": ""
            }
        ],
    }
    var raw = JSON.stringify(vJson);
    return raw;
}

function HelpdeskIndex() {
    const navigate = useNavigate()
    var schoolnumber = Cookies.get("Schoolnumber");
    const [AllCategories, setAllCategories] = useState([]);
    const [UpdateData, setUpdateData] = useState({
        Name: '',
        Updateid: '',
        Flag: '',
        description: '',
        headername: '',
        icon: ''
    })
    const [DeleteId, setDeleteId] = useState('')
    const [deletePopup, setDeletePopup] = useState(false);
    const [showloader, setshowloader] = useState(false);
    const [showimagepopup, setshowimagepopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mainimage, setmainimage] = useState(false);
    const [isModal, setisModal] = useState(false);
    const [updateModal, setupdateModal] = useState(false);
    const [showcopymsg, setshowcopymsg] = useState('d-none');
    const [AddData, setAddData] = useState({
        data: [],
        name: "",
        title: "",
        hd_type_id: null,
        id: null,
        categoryname: "",
        norecord: "",
        headername: "",
        alerttext: ""
    })
    const [MainCatData, setMainCatData] = useState({ id: null, index: null })
    var baseurl = window.location.origin;
    const labelRef = useRef(null);
    const [Alerts, setAlerts] = useState('')
    const [Imageicon, setImageicon] = useState('')
    const [copyissuemodal, setcopyissuemodal] = useState(false);
    const [Issues, setIssues] = useState([]);
    const [checkedIssues, setCheckedIssues] = useState([]);
    const [Subcatid, setSubcatid] = useState('');
    const [IssueerrorMessage, setIssueerrorMessage] = useState('');
    const [helpdeskReportData, setHelpdeskReportData] = useState([]);
    const [HelpdeskFlag, setHelpdeskFlag] = useState('')
    const { UserData, setUserData } = useContext(UserContext);
    const [isShow, setisShow] = useState(false);
    const [helpdeskId, setHelpdeskId] = useState('');
    useEffect(() => {
        GetAllCategories();
        GetHelpdeskReportData();
    }, []);
    async function GetAllCategories() {
        setLoading(true);
        await ApiGetCall("/GetHdTypes/" + LogInSchoolID + "&3").then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                const categoriesWithVisibility = responseRs.map(category => ({
                    ...category,
                    HDCategoryVisibility: AllCategories.some(cat => cat.id === category.id && cat.HDCategoryVisibility),
                    subcategories: category.categories.map(subcat => {
                        const existingSubCategory = AllCategories.find(cat => cat.id === category.id)?.subcategories?.find(s => s.id === subcat.id);
                        const SubCategoryVisibility = existingSubCategory ? existingSubCategory.SubCategoryVisibility : false;

                        return {
                            ...subcat,
                            SubCategoryVisibility: SubCategoryVisibility,
                            subcategories: subcat?.subcategories?.map(issues => {
                                const existingIssue = AllCategories.find(cat => cat.id === category.id)?.subcategories?.find(s => s.id === subcat.id)?.subcategories?.find(i => i.id === issues.id);
                                const IssueVisibility = existingIssue ? existingIssue.IssueVisibility : false;

                                return {
                                    ...issues,
                                    IssueVisibility: IssueVisibility
                                };
                            })
                        };
                    })
                }));
                setAllCategories(categoriesWithVisibility);
                if (responseRs.length == 0) {
                    setmainimage(true);
                }
            }
        });
    }
    const handleCopyClick = () => {
        if (labelRef.current) {
            const labelValue = labelRef.current.innerText;
            const tempInput = document.createElement('input');
            tempInput.value = labelValue;
            document.body.appendChild(tempInput);
            tempInput.select();
            tempInput.setSelectionRange(0, 99999); // For mobile devices
            document.execCommand('copy');
            document.body.removeChild(tempInput);
            setshowcopymsg("");
        }
        setTimeout(() => {
            setshowcopymsg("d-none");
        }, 1500);
    };
    const toggleCategoryVisibility = (index) => {
        setAllCategories(prevCategories => {
            return prevCategories.map((category, i) => {
                if (i === index) {
                    return { ...category, HDCategoryVisibility: !category.HDCategoryVisibility };
                } else {
                    return { ...category, HDCategoryVisibility: false };
                }
            });
        });
    };
    const toggleSubCategoryVisibility = (categoryIndex, subcategoryIndex) => {
        setAllCategories(prevCategories => {
            return prevCategories.map((category, i) => {
                if (i === categoryIndex) {
                    return {
                        ...category,
                        subcategories: category.subcategories.map((subcat, j) => {
                            if (j === subcategoryIndex) {
                                return { ...subcat, SubCategoryVisibility: !subcat.SubCategoryVisibility };
                            } else {
                                return { ...subcat, SubCategoryVisibility: false };
                            }
                        })
                    };
                } else {
                    return category;
                }
            });
        });
    };
    const toggleIssueVisibility = (categoryIndex, subcategoryIndex, issueIndex) => {
        setAllCategories(prevCategories => {
            return prevCategories.map((category, i) => {
                if (i === categoryIndex) {
                    return {
                        ...category,
                        subcategories: category.subcategories.map((subcat, j) => {
                            if (j === subcategoryIndex) {
                                return {
                                    ...subcat,
                                    subcategories: subcat.subcategories.map((subIssue, k) => {
                                        if (k === issueIndex) {
                                            return { ...subIssue, IssueVisibility: !subIssue.IssueVisibility };
                                        } else {
                                            return { ...subIssue, IssueVisibility: false };
                                        }
                                    })
                                }
                            } else {
                                return subcat;
                            }

                        })
                    };
                } else {
                    return category;
                }
            });
        });
    }
    const DeleteCategory = async () => {
        var raw = JSON.stringify({
            UpdateId: DeleteId,
            SchoolID: LogInSchoolID,
            Flag: 3
        });
        await ApiPostCall("/UpdateIssueandCategory", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={`Deleted Successfully`} />);
                    setDeletePopup(false)
                    GetAllCategories();
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1000);
                }
            }
        });
    }
    const AddDataFunction = (val, hd_type_id, id, indexI, indexJ, indexY, categoryname, flag) => {
        let data = [];
        let name = "";
        let title = "";
        let norecord = "";
        let headername = "";
        let alerttext = "";
        switch (val) {
            case "AddMainCategory":
                if (flag == 2) {
                    ExtendExisting(2);
                }
                data = AllCategories;
                name = "HdType";
                title = "Helpdesk Types";
                headername = "Add Helpdesk Type";
                norecord = "No Helpdesk Type added";
                alerttext = "Helpdesk Types Saved!";
                break;
            case "SubCategory":
                data = AllCategories[indexI]?.subcategories[indexJ]?.subcategories;
                name = "Sub Category";
                title = `Subcategories in ${categoryname}`;
                headername = `Add Subcategories in ${categoryname}`;
                norecord = "No Subcategory added";
                alerttext = "Subcategory Saved!";
                break;
            case "Issues":
                data = AllCategories[indexI]?.subcategories[indexJ]?.subcategories[indexY]?.subcategories;
                name = "Issues";
                title = `Issues in ${categoryname}`;
                headername = `Add Issues in ${categoryname}`;
                norecord = "No Issue added";
                alerttext = "Issues Saved!";
                break;
            default:
                break;
        }
        setAddData({
            data: data,
            name: name,
            title: title,
            hd_type_id: hd_type_id,
            id: id,
            categoryname: categoryname,
            norecord: norecord,
            headername: headername,
            alerttext: alerttext
        })
        setisModal(true)
    }
    async function GetAllIssues(categoryid) {
        await ApiGetCall("/GetAllIssuesofsubcategory/" + LogInSchoolID + "&" + categoryid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setCheckedIssues([]);
                setcopyissuemodal(true);
                setIssueerrorMessage('');
                setIssues(responseRs.data);
            }
        });
    }
    async function GetHelpdeskReportData() {
        await ApiGetCall("/HelpdeskTicketByBuildingforReport/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setHelpdeskReportData(responseRs?.HelpdeskTicketByBuildingforReport?.pdfData);
            }
        });
    }
    async function SaveCopyChanges() {
        if (checkedIssues.length == 0) {
            setIssueerrorMessage('One Issue is required.');
            return;
        }
        setIssueerrorMessage('');
        var raw = JSON.stringify({
            Schoolid: LogInSchoolID,
            Issues: checkedIssues,
            Subcategoryid: Subcatid,
        });
        await ApiPostCall("/SaveIssues", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={`Issues Added Successfully!`} />);
                    setcopyissuemodal(false);
                    GetAllCategories();
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 2500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1000);
                }
            }
        });
    }
    function handleCheckboxchange(e, hdcategoryId) {
        if (e.target.checked) {
            if (!checkedIssues.some(item => item.catid === hdcategoryId)) {
                setCheckedIssues(prevCheckedIssues => [...prevCheckedIssues, { catid: hdcategoryId }]);
            }
        } else {
            setCheckedIssues(prevCheckedIssues => prevCheckedIssues.filter(item => item.catid !== hdcategoryId));
        }
    }
    async function ExtendExisting(flag) {
        if (flag == 1) {
            setshowloader(true);
        }
        var raw = ExtendExistingJson();
        await ApiPostCall("/SaveExistingHelpdesk", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    if (flag == 1) {
                        setTimeout(() => {
                            setshowloader(false);
                            GetAllCategories();
                            setUserData((prevUserData) => ({ ...prevUserData, helpdeskUpdatedFlag: responseRs.helpdesk_updated_at }));
                        }, 2500);
                    }
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setshowloader(false);
                        setAlerts(<AlertsComp show={false} />)
                    }, 1000);
                }
            }
        });
    }
    async function HelpdeskCall() {
        var raw = JSON.stringify({
            SchoolId: LogInSchoolID,
            HelpdeskFlag: HelpdeskFlag
        })
        await ApiPostCall("/HelpdeskformShowHide", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Setting Updated Successfully"} />);
                    setTimeout(() => {
                        // window.location.reload()
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    function handleChange(hid, itemstatus, e) {
        const { name, checked } = e.target;

        let tempuser = AllCategories.map(user =>
            user.name === name ? { ...user, isChecked: checked } : user
        );
        setHelpdeskId(hid)
        setAllCategories(tempuser);
        if (itemstatus == 1) {
            setisShow(true);
        } else {
            ChangeisActiveHdTypesCall(hid, 1);
        }
    }
    async function ChangeisActiveHdTypesCall(id, status) {
        var raw = JSON.stringify({
            isactive: status,
            schoolid: LogInSchoolID,
            hdtypeid: id
        })
        await ApiPostCall("/ChangeisActiveHdTypes", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setisShow(false);
                setLoading(false);
                if (result == "success") {//flag 2 deactive flag 1 active
                    setAlerts(<AlertsComp show={true} variant="success" msg={`${status == 0 ? 'Helpdesk Type Deactivated Successfully' : 'Helpdesk Type Activate Successfully'}`} />);
                    setTimeout(() => {
                        GetAllCategories()
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    const issuemodalbody = (
        <div>
            {Issues.map((item, index) => (
                <div className='col-12 py-1' key={index}>
                    <b>{item.subcategory}(Subcategory)</b>
                    {item.issues.map((issue, i) => (
                        <div className='col-12 pt-1' key={i}>
                            <input className="form-check-input cursor-pointer me-2"
                                type="checkbox" onChange={(e) => handleCheckboxchange(e, issue.id)} />
                            <label className='font-13 fw-500 FormLabel'>{issue.name} (Issue Type)</label>
                            <br />
                            {issue.description && (
                                <label className='font-12 pt-1 ps-4 text-justify'><div dangerouslySetInnerHTML={{ __html: issue.description.replace(/<img /g, "<img class='img-fluid'") }} /></label>
                            )}
                        </div>
                    ))}

                </div>
            ))}
            {IssueerrorMessage && <div className='col-md-9 offset-md-2 pt-2'><p style={{ color: 'red' }}>{IssueerrorMessage}</p></div>}
        </div>
    )
    const downloadCsv = () => {
        const csvContent = [];
        const gradeMap = new Map();
        helpdeskReportData?.forEach((buildingTickets) => {
            if (buildingTickets.length > 0) {
                const building = buildingTickets[0]?.building?.name || buildingTickets[0]?.building_name // Get the building name
                if (!gradeMap.has(building)) {
                    gradeMap.set(building, []);
                }
                buildingTickets.forEach((ticket) => {
                    const rowData = {
                        'Subject': ticket.title,
                        'Name': ticket?.name,
                        'Email': ticket?.email,
                        'Room': ticket?.room_name ? ticket?.room_name : ticket.room ? ticket?.room?.name : '-',
                        'Student No.': ticket?.student_no, // Handle null/undefined values
                        'Created at': MMDDYYYY(ticket.created_at),  // Format the date
                        'Assigned To': ticket?.assigned_to ? `${ticket?.user?.first_name} ${ticket?.user?.last_name}` : '-',  // Format the date
                        'Helpdesk Type': ticket?.type?.name,  // Format the date
                    };

                    // Push the row data to the map for the current building
                    gradeMap.get(building).push(rowData);
                });
            }
        });


        gradeMap.forEach((gradeData, building) => {
            csvContent.push([`Building: ${building}`]);
            const headers = ['Subject', 'Name', 'Email', 'Room', 'Student No.', 'Created at', 'Assigned To', 'Helpdesk Type'];
            csvContent.push(headers);
            gradeData.forEach((rowData) => {
                const values = Object.values(rowData);
                csvContent.push(values);
            });
            csvContent.push([]);
        });

        if (csvContent.length > 0) {
            const csvString = csvContent.map(row => row.join(',')).join('\n'); // Convert to CSV string
            const blob = new Blob([csvString], { type: 'text/csv' });  // Create a Blob with the CSV content
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'OpenHelpdekTickets.csv';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            setAlerts(<AlertsComp show={true} variant="danger" msg={"No Data Found"} />);
            setTimeout(() => {
                setAlerts(<AlertsComp show={false} />)
            }, 1500);
        }
    };
    return (
        <div>
            {Alerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <div className='row align-items-center pe-0 mb-2'>
                <div className='col-md-3 pe-0'>
                    <h3 className="PageHeading mb-0">Helpdesk Setup</h3>
                </div>
                <div className="col-md-9 px-0 text-end">
                    {AllCategories.length > 0 && (
                        <>
                            <label className='BorderBtn me-1 text-center' title="Create Main Category" onClick={() => navigate('/import-export')}>
                                <img src='/images/ImportInventory.svg' className='img-fluid pe-2' />Import Helpdesk
                            </label>
                            <label className='BorderBtn me-1 text-center' title="Create Main Category" onClick={downloadCsv}>
                                <img src='/images/downloadPlan.svg' className='img-fluid pe-2' />Open Tickets Report
                            </label>
                            <label className='BorderBtn me-1 text-center' title="Create Main Category" onClick={() => AddDataFunction('AddMainCategory', null, null, 0, 0, 0, 0, 1)}>
                                <img src='/images/AddInventory.svg' className='img-fluid pe-2' />Add Helpdesk Type
                            </label>
                        </>
                    )}

                </div>
                {AllCategories.length > 0 && (
                    <div className='col-12 text-end pt-2'>
                        <label className={`pt-2 ${showcopymsg}`} style={{ color: "#3bbba4", fontSize: "15px" }}>Copied!</label>
                        <label className='d-none' ref={labelRef} style={{ wordBreak: 'break-all' }}>
                            <b> {baseurl}/helpdesk-request/{schoolnumber}</b>
                        </label>
                        <label className='cursor-pointer text-decoration-underline ms-3 text-center' title="Use this URL to collect helpdesk requests from your users." onClick={handleCopyClick}>
                            <FontAwesomeIcon icon={faCopy} className='pe-2' />Copy URL
                        </label>
                        {UserData.MenuAccessFlag == 1 && (
                            <label className='cursor-pointer text-decoration-underline ms-3 text-center' title="Ticket Assignment" onClick={(e) => navigate("/helpdesk-ticket-assignments")}>
                                Ticket Assignments
                            </label>
                        )}
                    </div>
                )}
            </div>

            {AllCategories.length > 0 && (
                <>
                    <div className='BorderBtn me-0 p-3 mt-3 d-flex align-items-center'>
                        <div className="col-md-8 font-12">
                            <b>Do users need to sign in using their Google or Microsoft accounts before they can access the helpdesk request form?</b>
                        </div>
                        <div className="col-md-4">
                            <div className='col-md-12 d-flex align-items-center justify-content-center'>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name='MasterInventory'
                                        onChange={(e) => setHelpdeskFlag(1)}
                                        defaultChecked={UserData.helpdeskflag == 1}
                                    />
                                    <label className="form-check-label">Yes</label>
                                </div>
                                <div className="form-check ms-5">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name='MasterInventory'
                                        onChange={(e) => setHelpdeskFlag(0)}
                                        defaultChecked={UserData.helpdeskflag != 1}
                                    />
                                    <label className="form-check-label">No</label>
                                </div>
                                <div className="ps-5">
                                    <button className="SaveBtn" onClick={HelpdeskCall}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="GridBox mt-2 p-3">
                        <div className='row'>
                            {AllCategories.map((item, i) => {
                                return <div className='col-md-4 mt-3' key={i}>
                                    <div className="col-md-12">
                                        <div className='p-3 GridBox categoryDivs '>
                                            <div className='row'>
                                                <div className='col-md-8'>
                                                    <label className='mb-0 fw-600'>
                                                        <img src="/images/Laptop.svg" className='img-fluid pe-1' style={{ height: "20px" }} /> {item.name}
                                                    </label>
                                                </div>
                                                <div className='col-md-4 d-flex justify-content-end align-items-center'>
                                                    {item.is_active != 0 ?
                                                        <MDBSwitch name={item.id} checked={item?.isChecked || true} className="BgPink cursor-pointer" onChange={(e) => { handleChange(item.id, "1", e) }} hid={item.id} />
                                                        :
                                                        <MDBSwitch name={item.id} checked={item?.isChecked || false} className="BgPink cursor-pointer" onChange={(e) => { handleChange(item.id, "2", e) }} hid={item.id} />}
                                                    <img src="/images/helpdeskcategoryadd.svg" className="cursor-pointer me-1 mt-1" title={`Helpdesk setup for ${item.name}`} style={{ float: 'right', height: "16px", width: "16px" }}
                                                        onClick={(e) => navigate("/add-helpdesk", { state: { categoryid: item.id } })} />
                                                    <img src="/images/helpdeskedit.svg" className="cursor-pointer me-1 mt-1" title={`Edit ${item.name} Helpdesk`} style={{ float: 'right', height: "13px", width: "13px" }}
                                                        onClick={(e) => { setupdateModal(true); setUpdateData({ ...UpdateData, Name: item.name, Updateid: item.id, Flag: 1, description: item.description, issueflag: 2, headername: "Edit Helpdesk Type", icon: item.icon }) }} />
                                                    <FontAwesomeIcon icon={faAngleDown} style={{ color: "#969c9b", height: "14px" }} title="Show More Details" className={`cursor-pointer mt-1 ${item.HDCategoryVisibility ? 'd-none' : ''}`} onClick={() => { toggleCategoryVisibility(i); setMainCatData({ id: item.id, index: i }); }} />
                                                    <FontAwesomeIcon icon={faAngleUp} style={{ color: "#969c9b", height: "14px" }} title="Hide Details" className={`cursor-pointer mt-1 ${item.HDCategoryVisibility ? '' : 'd-none'}`} onClick={() => { toggleCategoryVisibility(i); setMainCatData({ id: item.id, index: i }); }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className='col-12 mt-3 data-table-container' style={{ overflowX: "hidden" }}>
                            {AllCategories.map((item, i) => {
                                return item.HDCategoryVisibility && (
                                    <div className='col-12 GridBox' style={{ border: "1px solid #3CBBA5" }} key={i}>
                                        <div className='categoryDivs p-3 row align-items-center'>
                                            <div className='col-md-10'>
                                                <label className='mb-0 fw-600 font-14'>
                                                    <img src="/images/Laptop.svg" className='img-fluid pe-2' /> {item.name} (Helpdesk Type)
                                                </label>
                                            </div>
                                            <div className='col-md-2 d-flex justify-content-end align-items-center'>
                                                <img src="/images/helpdeskcategoryadd.svg" className="cursor-pointer me-1 mt-1" title={`Helpdesk setup for ${item.name}`} style={{ float: 'right', height: "16px", width: "16px" }}
                                                    onClick={(e) => navigate("/add-helpdesk", { state: { categoryid: item.id } })} />
                                                <img src="/images/helpdeskedit.svg" className="cursor-pointer me-1 mt-1" title={`Edit ${item.name} Helpdesk`} style={{ float: 'right', height: "13px", width: "13px" }}
                                                    onClick={(e) => { setupdateModal(true); setUpdateData({ ...UpdateData, Name: item.name, Updateid: item.id, Flag: 1, description: item.description, issueflag: 2, headername: "Edit Helpdesk Type", icon: item.icon }) }} />
                                                <FontAwesomeIcon icon={faAngleDown} style={{ color: "#969c9b", height: "14px" }} title="Show More Details" className={`cursor-pointer mt-1 ${item.HDCategoryVisibility ? 'd-none' : ''}`} onClick={() => { toggleCategoryVisibility(i); setMainCatData({ id: item.id, index: i }); }} />
                                                <FontAwesomeIcon icon={faAngleUp} style={{ color: "#969c9b", height: "14px" }} title="Hide Details" className={`cursor-pointer mt-1 ${item.HDCategoryVisibility ? '' : 'd-none'}`} onClick={() => { toggleCategoryVisibility(i); setMainCatData({ id: item.id, index: i }); }} />
                                            </div>
                                        </div>
                                        <div className='col-12 px-3 py-2'>
                                            {item.subcategories.length > 0 ?
                                                item.subcategories.map((cat, k) => {
                                                    return <div className='GreyGridBox my-3' key={k}>
                                                        <div className=' px-3'>
                                                            <div className='helpdeskgreyBox py-1'>
                                                                <div className='row py-2 align-items-center'>
                                                                    <div className='col-9 d-flex align-items-center'>
                                                                        <label>
                                                                            {cat.icon &&
                                                                                <img src={cat.icon} className='img-icon cursor-pointer' onClick={(e) => { setshowimagepopup(true); setImageicon(cat.icon) }} />
                                                                            }
                                                                        </label>
                                                                        <label className='ps-2 fw-600 font-13'>
                                                                            {cat.name} (Category)
                                                                        </label>
                                                                    </div>
                                                                    <div className='col-3 text-end'>
                                                                        {cat?.subcategories.length === 0 && (
                                                                            <img className="img-fluid cursor-pointer pe-3" style={{ height: "14px" }} src="/images/DeleteHdTicket.svg" title='Delete' onClick={(e) => { setDeletePopup(true); setDeleteId(cat.id) }} />
                                                                        )}
                                                                        <img className="img-fluid cursor-pointer pe-3" style={{ height: "14px" }} src="/images/helpdeskcategoryadd.svg" title='Add'
                                                                            onClick={() => AddDataFunction('SubCategory', cat?.hd_type_id, cat?.id, MainCatData?.index, k, 0, cat.name, 1)} />
                                                                        <img src="/images/helpdeskedit.svg" className="cursor-pointer pe-3" style={{ height: "14px" }} title={`Edit ${cat.name} Help Desk`}
                                                                            onClick={(e) => { setupdateModal(true); setUpdateData({ ...UpdateData, Name: cat.name, Updateid: cat.id, Flag: 2, description: cat.description, issueflag: 2, headername: "Edit Helpdesk Category", icon: cat.icon }) }} />
                                                                        <FontAwesomeIcon icon={faAngleDown} style={{ color: "#969c9b", height: "14px" }} title="Show More Details" className={`cursor-pointer ${cat.SubCategoryVisibility ? 'd-none' : ''}`} onClick={() => toggleSubCategoryVisibility(i, k)} />
                                                                        <FontAwesomeIcon icon={faAngleUp} style={{ color: "#969c9b", height: "14px" }} title="Hide Details" className={`cursor-pointer ${cat.SubCategoryVisibility ? '' : 'd-none'}`} onClick={() => toggleSubCategoryVisibility(i, k)} />
                                                                    </div>
                                                                </div>
                                                                {cat?.SubCategoryVisibility && (
                                                                    cat?.subcategories?.length > 0 ?
                                                                        cat?.subcategories?.map((subcat, j) => {
                                                                            return <div key={j}>
                                                                                <div className='row admin-table-border-top  py-2 px-3' style={{ backgroundColor: "#FAFAFA" }}>
                                                                                    <div className='col-9 d-flex align-items-center'>
                                                                                        <label>
                                                                                            {subcat.icon &&
                                                                                                <img src={subcat.icon} className='img-icon cursor-pointer' onClick={(e) => { setshowimagepopup(true); setImageicon(subcat.icon) }} />
                                                                                            }
                                                                                        </label>
                                                                                        <label className='ps-2 fw-500 font-13'>
                                                                                            {subcat.name} (Subcategory)
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className='col-3 text-end'>
                                                                                        {cat?.subcategories.length > 1 && (
                                                                                            <FontAwesomeIcon icon={faCopy} title='Copy Issues From Previous Subcategories' className='pe-3 cursor-pointer' onClick={(e) => { GetAllIssues(cat.id); setSubcatid(subcat.id) }} />
                                                                                        )}
                                                                                        {subcat?.subcategories.length === 0 && (
                                                                                            <img className="img-fluid cursor-pointer pe-3" style={{ height: "14px" }} src="/images/DeleteHdTicket.svg" title='Delete' onClick={(e) => { setDeletePopup(true); setDeleteId(subcat.id) }} />
                                                                                        )}
                                                                                        <img className="img-fluid cursor-pointer pe-3" style={{ height: "14px" }} src="/images/helpdeskcategoryadd.svg" title='Add'
                                                                                            onClick={() => AddDataFunction('Issues', subcat?.hd_type_id, subcat?.id, MainCatData?.index, k, j, subcat.name, 1)} />
                                                                                        <img src="/images/helpdeskedit.svg" className="cursor-pointer pe-3" style={{ height: "14px" }} title={`Edit ${subcat.name} Help Desk`}
                                                                                            onClick={(e) => { setupdateModal(true); setUpdateData({ ...UpdateData, Name: subcat.name, Updateid: subcat.id, Flag: 2, description: subcat.description, issueflag: 2, headername: "Edit Subcategory", icon: subcat.icon }) }} />
                                                                                        <FontAwesomeIcon icon={faAngleDown} style={{ color: "#969c9b", height: "14px" }} title="Show More Details" className={`cursor-pointer ${subcat.IssueVisibility ? 'd-none' : ''}`} onClick={() => toggleIssueVisibility(i, k, j)} />
                                                                                        <FontAwesomeIcon icon={faAngleUp} style={{ color: "#969c9b", height: "14px" }} title="Hide Details" className={`cursor-pointer ${subcat.IssueVisibility ? '' : 'd-none'}`} onClick={() => toggleIssueVisibility(i, k, j)} />
                                                                                    </div>
                                                                                </div>
                                                                                {subcat?.IssueVisibility && (
                                                                                    subcat?.subcategories.length > 0 ?
                                                                                        subcat?.subcategories?.map((issues, y) => {
                                                                                            return <div key={y} >
                                                                                                <div className='row admin-table-border-top  py-2 px-5' style={{ backgroundColor: "#FDFDFD" }}>
                                                                                                    <div className='col-9 d-flex align-items-center'>
                                                                                                        <label>
                                                                                                            {issues.icon &&
                                                                                                                <img src={issues.icon} className='img-icon cursor-pointer' onClick={(e) => { setshowimagepopup(true); setImageicon(issues.icon) }} />
                                                                                                            }
                                                                                                        </label>
                                                                                                        <label className='ps-2 font-12'>
                                                                                                            {issues.name} (Issue Type)
                                                                                                        </label>
                                                                                                    </div>
                                                                                                    <div className='col-3 text-end'>
                                                                                                        <img className="img-fluid cursor-pointer pe-3" style={{ height: "14px" }} src="/images/DeleteHdTicket.svg" title='Delete Issue' onClick={(e) => { setDeletePopup(true); setDeleteId(issues.id) }} />
                                                                                                        <img src="/images/helpdeskedit.svg" className="cursor-pointer" style={{ height: "14px" }} title={`Edit ${issues.name} Help Desk`}
                                                                                                            onClick={(e) => { setupdateModal(true); setUpdateData({ ...UpdateData, Name: issues.name, Updateid: issues.id, Flag: 2, description: issues.description, issueflag: 1, headername: "Edit Issue", icon: issues.icon }) }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        })
                                                                                        :
                                                                                        <div className='col-12 text-center py-2 font-12'>
                                                                                            No Issue Found
                                                                                        </div>

                                                                                )}
                                                                            </div>
                                                                        })
                                                                        :
                                                                        <div className='col-12 text-center py-2 font-12'>
                                                                            No Subcategory Found
                                                                        </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                                :
                                                <div className='col-12 text-center py-2 font-12'>
                                                    No Record Found
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </>
            )
            }

            {
                AllCategories.length == 0 && mainimage == true && (
                    <div className='row pt-2'>
                        <div className='col-md-5 pe-0'>
                            <div className="HDDiv p-5">
                                <div className='text-justify fw-600 font-14'>
                                    Helpdesk is a place where you can collect tickets/issues from your end users (students, teachers, parents, staff members etc) regarding any category of your school. You can use and extend our existing Helpdesk model or create your own from scratch using any option below.
                                </div>
                                {showloader ?
                                    <label className='mt-3 BorderBtn text-center HdBtnDiv' title="Extend Existing Helpdesk" >
                                        <label>+</label> Extend Existing Helpdesk
                                    </label>
                                    :
                                    <label className='mt-3 BorderBtn text-center HdBtnDiv' title="Extend Existing Helpdesk" onClick={(e) => ExtendExisting(1)}>
                                        <label>+</label> Extend Existing Helpdesk
                                    </label>
                                }
                                <br />
                                <label className='mt-3 BorderBtn text-center HdBtnDiv' title="Create Main Category" onClick={() => AddDataFunction('AddMainCategory', null, null, 0, 0, 0, 0, 2)}>
                                    <label>+</label> Create Your Own
                                </label>
                                <div className='col-12 pt-2 text-center'>
                                    {showloader && (
                                        <div className='col-12 text-center'>
                                            <img src="/images/loder.svg" className="pe-3" style={{ width: "30px" }} />
                                            <br />
                                            Generating your helpdesk..
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-7 ps-0'>
                            <video width="100%" height="100%" autoPlay muted loop style={{ objectFit: "fill", border: "1px solid #ded5d5" }}>
                                <source src="/images/FinalHDVideo.mp4" type="video/mp4" />
                            </video>
                        </div>
                    </div>
                )
            }

            {
                isModal == true && (
                    <HdTypeModal isShow={isModal} ClosePopup={(e) => setisModal(false)} categories={AddData} apicall={GetAllCategories} />
                )
            }
            {
                updateModal == true && (
                    <UpdateCategoryModal show={updateModal} ClosePopup={(e) => setupdateModal(false)} UpdateData={UpdateData} getcall={GetAllCategories} setAlerts={setAlerts} />
                )
            }
            <Deletepopup text={`You won't be able to revert this!`} deletePopup={deletePopup} size="xl" deletePart={DeleteCategory} DeleteShowClose={(e) => setDeletePopup(false)} />

            <Popup isshow={showimagepopup} size={"md"} title={"Icon"}
                closePopup={(e) => setshowimagepopup(false)}
                modalBody={<div className="row text-center">
                    <img src={Imageicon} alt="" style={{ maxHeight: "400px" }} />
                </div>} visibleSaveBtn={false} />

            <Popup isshow={copyissuemodal} size={"md"} title={"Copy Issues from previous Subcategories"}
                closePopup={(e) => setcopyissuemodal(false)} btnText={"Save Changes"}
                modalBody={issuemodalbody} visibleSaveBtn={true} handleSave={SaveCopyChanges} />

            <AlertPopup isshow={isShow} img={'/images/greenInfo.svg'}
                headingtext={`Are you sure you want to deactivate this helpdesk type?`} text={''}
                cancelcall={(e) => setisShow(false)}
                canceltext={"No"} successtext={"Yes"} successcall={(e) => { ChangeisActiveHdTypesCall(helpdeskId, "0") }} btntype={'SaveBtn'} />

        </div >
    )
}

export default HelpdeskIndex
