import React from 'react'
import { useEffect } from 'react'
import { HideLoder, MMDDYYYY, ShowLoder, formatDateToMMDDYYYY } from '../JS/Common';
import { ApiGetCall } from '../JS/Connector';
import { useState } from 'react';
import Tab from './Tab';
import Popup from './Popup';
import { faDollarSign, faStamp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function UserDetailsPopup(props) {
    const [loading, setLoading] = useState(false);
    const [userdata, setuserdata] = useState([]);
    const [planData, setPlanData] = useState("");
    const [policyflag, setpolicyflag] = useState("");
    const [userHistory, setUserHistory] = useState([]);
    const [claimLog, setClaimLog] = useState([]);
    const [currentAllocation, setcurrentAllocation] = useState([]);
    const [pastAllocation, setpastAllocation] = useState([]);
    const [CurrentTickets, setCurrentTickets] = useState([]);
    const [PastTickets, setPastTickets] = useState([]);
    const [isstudent, setisstudent] = useState(0);
    const [Tabactive, setTabActive] = useState({
        UserdetailsTab: "active",
        UserHistoryTab: "",
        PlanHistoryTab: '',
        UserTicketsTab: ''
    });
    const [noteVisibility, setNoteVisibility] = useState({});

    useEffect(() => {
        setLoading(true)
        ApiGetCall("/StudentDataByID/" + props.userid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false)
                const responseRs = JSON.parse(result);
                setisstudent(responseRs?.msg?.is_student)
                setuserdata(responseRs.msg || []);
                setpolicyflag(responseRs.studentAgreePolicy);
                setpastAllocation(responseRs.past_allocation || [])
                setcurrentAllocation(responseRs.current_allocation || [])
                setUserHistory(responseRs.device_Allocation_Logs || []);
                setPlanData(responseRs.parental_coverage_details || "");
                setClaimLog(responseRs.parental_coverage_claim_Details || []);
                const combinedTickets = responseRs.current_allocation.flatMap(device =>
                    device.ticket_details.map(ticket => ({
                        ...ticket,
                        serial_number: device.serial_number
                    }))
                );
                setCurrentTickets(combinedTickets)
                const combinedpastTickets = responseRs.past_allocation.flatMap(device =>
                    device.ticket_details.map(ticket => ({
                        ...ticket,
                        serial_number: device.serial_number
                    }))
                );
                setPastTickets(combinedpastTickets)
            }
        });
    }, []);
    function handleArrowClick(ticketId) {
        setNoteVisibility(prevID => (prevID == ticketId ? null : ticketId));
    }
    function handleTabClick(tabType) {
        if (tabType == "userdetails") {
            setTabActive({ ...Tabactive, UserdetailsTab: "active", UserHistoryTab: "", PlanHistoryTab: '', UserTicketsTab: '' });
        } else if (tabType == "userhistory") {
            setTabActive({ ...Tabactive, UserdetailsTab: "", UserHistoryTab: "active", PlanHistoryTab: '', UserTicketsTab: '' });
        } else if (tabType == "parental") {
            setTabActive({ ...Tabactive, UserdetailsTab: "", UserHistoryTab: "", PlanHistoryTab: 'active', UserTicketsTab: '' });
        } else if (tabType == "usertickets") {
            setTabActive({ ...Tabactive, UserdetailsTab: "", UserHistoryTab: "", PlanHistoryTab: '', UserTicketsTab: 'active' });
        }
    }
    const modalbody = (
        <>
            <ul className="nav nav-tabs">
                <Tab isActive={Tabactive.UserdetailsTab == 'active'}
                    label="User Details" onClick={() => handleTabClick('userdetails')} col={'col-md-2'} />
                <Tab isActive={Tabactive.UserHistoryTab == 'active'}
                    label="Device History" onClick={() => handleTabClick('userhistory')} col={'col-md-3'} />
                <Tab isActive={Tabactive.PlanHistoryTab == 'active'}
                    label="Parental Coverage History" onClick={() => handleTabClick('parental')} col={'col-md-4'} />
                <Tab isActive={Tabactive.UserTicketsTab == 'active'}
                    label="User Tickets" onClick={() => handleTabClick('usertickets')} col={'col-md-2'} />
            </ul>

            {Tabactive.UserdetailsTab == 'active' && (
                <div >
                    <div className="row my-3" style={{ fontSize: "13px" }}>
                        <div className="col-lg-6 row py-1">
                            <div className="col ">First Name : </div>
                            <div className="col fw-600"> {userdata?.device_user_first_name}</div>
                        </div>
                        <div className="col-lg-6 row py-1">
                            <div className="col ">Middle Name : </div>
                            <div className="col fw-600">{userdata?.device_user_middle_name || '-'}</div>
                        </div>
                        <div className="col-lg-6 row py-1">
                            <div className="col ">Last Name : </div>
                            <div className="col fw-600">{userdata?.device_user_last_name}</div>
                        </div>
                        <div className="col-lg-6 row py-1">
                            <div className="col ">User ID : </div>
                            <div className="col fw-600">{userdata?.student_num}</div>
                        </div>
                        <div className="col-lg-6 row py-1">
                            <div className="col ">User Email : </div>
                            <div className="col fw-600">{userdata?.student_email || '-'}</div>
                        </div>
                        <div className="col-lg-6 row py-1">
                            <div className="col">Is the user a student?  : </div>
                            <div className="col fw-600">{isstudent == 1 ? 'Yes' : 'No'}</div>
                        </div>
                        <div className="col-lg-6 row py-1">
                            <div className="col">Grade  : </div>
                            <div className="col fw-600">{userdata?.grade || '-'}</div>
                        </div>
                        <div className="col-lg-6 row py-1">
                            <div className="col">Department  : </div>
                            <div className="col fw-600">{userdata?.department || '-'}</div>
                        </div>
                        <div className="col-lg-6 row py-1">
                            <div className="col">Parent Email : </div>
                            <div className="col fw-600">{userdata?.parent_guardian_email || '-'}</div>
                        </div>
                        <div className="col-lg-6 row py-1">
                            <div className="col ">Parent Name : </div>
                            <div className="col fw-600">{userdata?.parent_guardian_name || '-'}</div>
                        </div>
                        <div className="col-lg-6 row py-1">
                            <div className="col">Parent Contact : </div>
                            <div className="col fw-600">{userdata?.parent_phone_number || '-'}</div>
                        </div>
                        <div className="col-lg-6 row py-1">
                            <div className="col">Parental Coverage : </div>
                            <div className="col fw-600">{userdata?.parental_coverage == 1 ? 'Yes' : 'No'}</div>
                        </div>
                        <div className="col-lg-6 row py-1">
                            <div className="col">Fair Use Acknowledgment : </div>
                            <div className="col fw-600">{policyflag == 1 ? 'Yes' : policyflag == 0 ? 'No' : '-'}</div>
                        </div>
                    </div>
                    <hr />
                    <div className='row'>
                        <h5 className='fw-600'>Current Allocated Devices</h5>
                        {currentAllocation?.length != 0 ?
                            currentAllocation?.map((item, i) => {
                                return (
                                    <div className="row font-14" key={i}>
                                        <div className="col-md-12">
                                            <div className="row" style={{ fontSize: "13px" }}>
                                                <div className="col-md-6 align-items-center  pt-2">
                                                    <div className="row">
                                                        <div className="col-md-6 " > Serial #:  </div>
                                                        <div className="col-md-6 text-md-start text-center wordwrap"> <b>{item.serial_number}</b> </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-items-center  pt-2">
                                                    <div className="row">
                                                        <div className="col-md-6"> Device Model: </div>
                                                        <div className="col-md-6 text-md-start text-center"> <b>{item.device_model ? item.device_model : <>-</>}</b> </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-items-center  pt-2">
                                                    <div className="row">
                                                        <div className="col-md-6">  Device Manufacturer: </div>
                                                        <div className="col-md-6 text-md-start text-center"> <b>{item.device_manufacturer ? item.device_manufacturer : <>-</>}</b> </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6  align-items-center  pt-2">
                                                    <div className="row">
                                                        <div className="col-md-6"> Device MPN:  </div>
                                                        <div className="col-md-6 text-md-start text-center"> <b>{item.device_mpn ? item.device_mpn : <>-</>}</b></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6  align-items-center  pt-2">
                                                    <div className="row">
                                                        <div className="col-md-6">   Asset Tag: </div>
                                                        <div className="col-md-6 text-md-start text-center"> <b>{item.asset_tag ? item.asset_tag : <>-</>}</b>  </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6  align-items-center pt-2">
                                                    <div className="row">
                                                        <div className="col-md-6">  Repair Cap:   </div>
                                                        <div className="col-md-6 text-md-start text-center"> <b>{item.repair_cap ? `$${item.repair_cap}` : <>-</>}</b> </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6  align-items-center pt-2">
                                                    <div className="row">
                                                        <div className="col-md-6"> Device Os: </div>
                                                        <div className="col-md-6 text-md-start text-center">  <b>{item.device_os ? item.device_os : <>-</>}</b> </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12  align-items-center pt-2">
                                                    <div className="row">
                                                        <div className="col-md-3 col-8"> Notes: </div>
                                                        <div className="col-md-9 col-4 text-md-start text-center">  <b>{item.notes ? item.notes : <>-</>}</b> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <img src="/images/HorizontalLine.svg" className="img-fluid w-100 pt-2" />
                                    </div>
                                )
                            })
                            :
                            <div className="col-12 text-center p-2">  No Record Found  </div>
                        }
                        <h5 className='fw-600 pt-3'>Past Allocated Devices</h5>
                        {pastAllocation?.length != 0 ?
                            pastAllocation?.map((item, i) => {
                                return (
                                    <div className="row font-14" key={i}>
                                        <div className="col-md-12">
                                            <div className="row" style={{ fontSize: "13px" }}>
                                                <div className="col-md-6 align-items-center  pt-2">
                                                    <div className="row">
                                                        <div className="col-md-6 " > Serial #:  </div>
                                                        <div className="col-md-6 text-md-start text-center wordwrap"> <b>{item.serial_number}</b> </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-items-center  pt-2">
                                                    <div className="row">
                                                        <div className="col-md-6"> Device Model: </div>
                                                        <div className="col-md-6 text-md-start text-center"> <b>{item.device_model ? item.device_model : <>-</>}</b> </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-items-center  pt-2">
                                                    <div className="row">
                                                        <div className="col-md-6">  Device Manufacturer: </div>
                                                        <div className="col-md-6 text-md-start text-center"> <b>{item.device_manufacturer ? item.device_manufacturer : <>-</>}</b> </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6  align-items-center  pt-2">
                                                    <div className="row">
                                                        <div className="col-md-6"> Device MPN:  </div>
                                                        <div className="col-md-6 text-md-start text-center"> <b>{item.device_mpn ? item.device_mpn : <>-</>}</b></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6  align-items-center  pt-2">
                                                    <div className="row">
                                                        <div className="col-md-6">   Asset Tag: </div>
                                                        <div className="col-md-6 text-md-start text-center"> <b>{item.asset_tag ? item.asset_tag : <>-</>}</b>  </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6  align-items-center pt-2">
                                                    <div className="row">
                                                        <div className="col-md-6">  Repair Cap:   </div>
                                                        <div className="col-md-6 text-md-start text-center"> <b>{item.repair_cap ? `$${item.repair_cap}` : <>-</>}</b> </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6  align-items-center pt-2">
                                                    <div className="row">
                                                        <div className="col-md-6"> Device Os: </div>
                                                        <div className="col-md-6 text-md-start text-center">  <b>{item.device_os ? item.device_os : <>-</>}</b> </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12  align-items-center pt-2">
                                                    <div className="row">
                                                        <div className="col-md-3 col-8"> Notes: </div>
                                                        <div className="col-md-9 col-4 text-md-start text-center">  <b>{item.notes ? item.notes : <>-</>}</b> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <img src="/images/HorizontalLine.svg" className="img-fluid w-100 pt-2" />
                                    </div>
                                )
                            })
                            :
                            <div className="col-12 text-center p-2">  No Record Found  </div>
                        }
                    </div>
                </div>
            )}

            {Tabactive.UserHistoryTab == 'active' && (
                <div>
                    <div className="col-12 greyBox p-3 my-3">
                        <table className="table">
                            <thead className="GridHeader">
                                <tr>
                                    <th scope="col" className="cursor-pointer fw-600 text-start">
                                        Serial No. #
                                    </th>
                                    <th scope="col" className="cursor-pointer fw-600 text-start">
                                        Allocated Date
                                    </th>
                                    <th scope="col" className="cursor-pointer fw-600 text-start">
                                        Loaner Device Allocation Date
                                    </th>
                                    <th scope="col" className="cursor-pointer fw-600 text-start">
                                        Vacant Date
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userHistory?.length != 0 ? (
                                    userHistory?.map((item, i) => {
                                        const isNoteVisible = item.id === noteVisibility;
                                        var returData = (
                                            <tr key={i} className="OddEvenClass tableBorderHide">
                                                <td className="py-12 text-start">
                                                    {item.inventory.serial_number || '-'}
                                                </td>
                                                <td className="py-12 text-start">
                                                    {item.allocated_date ? formatDateToMMDDYYYY(item.allocated_date) : <>-</>}
                                                </td>
                                                <td className="py-12 text-start">
                                                    {item.loner_allocation_date ? formatDateToMMDDYYYY(item.loner_allocation_date) : <>-</>}
                                                </td>
                                                <td className="py-12 text-start">
                                                    {item.vacant_date ? formatDateToMMDDYYYY(item.vacant_date) : <>-</>}
                                                </td>
                                                <td>
                                                    <img src="/images/DownRoundArrow.svg" title="Show More Details" className={`cursor-pointer img-fluid ${isNoteVisible ? "d-none" : ""}`} ticketid={item.id} onClick={() => handleArrowClick(item.id)} />
                                                    <img src="/images/UpRoundArrow.svg" title="Hide More Details" className={`img-fluid cursor-pointer ${isNoteVisible ? "" : "d-none"}`} ticketid={item.id} onClick={() => handleArrowClick(item.id)} />
                                                </td>
                                            </tr>
                                        )
                                        if (isNoteVisible) {
                                            returData = (
                                                <>
                                                    {returData}
                                                    <tr className={`col-12  px-5`}>
                                                        <td colSpan={18}>
                                                            {item?.inventory?.ticket?.length != 0 ?
                                                                <>
                                                                    {item?.inventory?.ticket?.map((itemData, i) => {
                                                                        var returData = <div className="col-12 py-1" key={i}>
                                                                            Ticket number <b>{itemData.ticket_num}</b> was created on {formatDateToMMDDYYYY(itemData.created_at)}.
                                                                        </div>
                                                                        return returData;
                                                                    })}
                                                                </>
                                                                :
                                                                <div className="col-12 text-center">
                                                                    <label>No Record Found</label>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>

                                                </>
                                            );
                                        }
                                        return returData;
                                    })
                                ) :
                                    <tr>
                                        <td colspan={5} className="text-center">
                                            No Record Found
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )}


            {Tabactive.PlanHistoryTab == 'active' && (
                <>
                    {planData ?
                        <>
                            {planData.enrollment_status == 2 &&
                                <div className='blueText fw-600 fs-6 mt-3'>This coverage plan is expired.</div>
                            }
                            <div className="row my-3">
                                <div className="col-lg-6 row py-1">
                                    <div className="col fw-600">Plan Name : </div>
                                    <div className="col"> {planData?.planName || '-'}</div>
                                </div>
                                <div className="col-lg-6 row py-1">
                                    <div className="col fw-600"> No of Claims : </div>
                                    <div className="col wordwrap fw-600" style={{ color: '#2cc3a9' }}> {planData?.claim_count || '-'}</div>
                                </div>
                                <div className="col-lg-6 row py-1">
                                    <div className="col fw-600"> Plan Price : </div>
                                    <div className="col wordwrap"> ${planData?.planPrice || 0}</div>
                                </div>
                                <div className="col-lg-6 row py-1">
                                    <div className="col fw-600"> Paid Amount : </div>
                                    <div className="col wordwrap"> ${planData?.paid_amount || 0}</div>
                                </div>
                                <div className="col-lg-6 row py-1">
                                    <div className="col fw-600">Sign Up Start Date: </div>
                                    <div className="col"> {MMDDYYYY(planData?.startDate) || '-'}</div>
                                </div>
                                <div className="col-lg-6 row py-1">
                                    <div className="col fw-600">Sign Up End Date : </div>
                                    <div className="col"> {MMDDYYYY(planData?.endDate) || '-'}</div>
                                </div>
                                <div className="col-lg-6 row py-1">
                                    <div className="col fw-600">Coverage Start Date : </div>
                                    <div className="col"> {MMDDYYYY(planData?.coverageStartDate) || '-'}</div>
                                </div>
                                <div className="col-lg-6 row py-1">
                                    <div className="col fw-600">Coverage End Date : </div>
                                    <div className="col"> {MMDDYYYY(planData?.coverageEndDate) || '-'}</div>
                                </div>

                                <div className='col-12 pt-3'>
                                    <div className="greyBox">
                                        <table className="table">
                                            <thead className="GridHeader">
                                                <tr>
                                                    <th scope="col" className="cursor-pointer fw-600 text-start">
                                                        Ticket No. #
                                                    </th>
                                                    <th scope="col" className="cursor-pointer fw-600 text-start">
                                                        Serial No. #
                                                    </th>
                                                    <th scope="col" className="cursor-pointer fw-600 text-start">
                                                        Claim Date
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {claimLog.length > 0 ? (
                                                    claimLog?.map((item, i) => {
                                                        var returData = (
                                                            <tr key={i} className="OddEvenClass tableBorderHide">
                                                                <td className="py-12 text-start">
                                                                    {item.TicketNum || '-'}
                                                                </td>
                                                                <td className="py-12 text-start">
                                                                    {item.SerialNum || '-'}
                                                                </td>
                                                                <td className="py-12 text-start">
                                                                    {item.Date ? item.Date : <>-</>}
                                                                </td>
                                                            </tr>
                                                        )
                                                        return returData;
                                                    })
                                                ) :
                                                    <tr>
                                                        <td colspan={5} className="text-center"> No Record Found </td>
                                                    </tr>
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div className='text-center pt-4'>No Record Found</div>
                    }
                </>
            )}

            {Tabactive.UserTicketsTab == 'active' && (
                <div>
                    <div className="row scrollbar-css-ticketform h-100 mt-3">
                        <h5 className='fw-600'>Current Allocated Devices Tickets</h5>
                        {CurrentTickets.length != 0 ?
                            CurrentTickets.map((item, i) => {
                                return <div className="col-md-12" key={i}>
                                    <div className="row" style={{ fontSize: "13px" }}>
                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Serial Number : </div>
                                            <div className="col fw-600">{item.serial_number}</div>
                                        </div>
                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Parent Invoice Number : </div>
                                            <div className="col fw-600">{item.parentInvoiceNumber || '-'}</div>
                                        </div>
                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Ticket Number : </div>
                                            <div className="col fw-600">{item.ticket_num || '-'}</div>
                                        </div>
                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Parent Invoice Status : </div>
                                            <div className="col fw-600">{item.parentInvoiceStatus == 1 ?
                                                (
                                                    <FontAwesomeIcon icon={faStamp} title="Paid" className='' style={{ height: '14px', color: '#2cc3a9' }}
                                                    />) : (
                                                    <FontAwesomeIcon icon={faStamp} title="Not paid" className='' style={{ height: '14px', color: '#db3b3b' }}
                                                    />)}</div>
                                        </div>
                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Created at : </div>
                                            <div className="col fw-600">{formatDateToMMDDYYYY(item.created_at) || '-'}</div>
                                        </div>

                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Parent Invoice Amount : </div>
                                            <div className="col fw-600">{item.parentInvoiceAmount ? `$${item.parentInvoiceAmount}` : <>-</>}</div>
                                        </div>
                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Ticket Status : </div>
                                            <div className="col fw-600">{item.status || '-'}</div>
                                        </div>
                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Billable : </div>
                                            <div className="col fw-600">
                                                <FontAwesomeIcon
                                                    className={`${item.chargeability == 1 ? 'text-blue-billable' : 'text-gray-billable'}`}
                                                    icon={faDollarSign}
                                                    style={{ height: '13px' }}
                                                    title={item.chargeability == 1 ? 'Billable' : 'Non-billable'}
                                                />
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            })
                            :
                            <div className="col-12 text-center">
                                No Record Found
                            </div>
                        }
                        <br />
                        <h5 className='fw-600 mt-3'>Past Allocated Devices Tickets</h5>
                        {PastTickets.length != 0 ?
                            PastTickets.map((item, i) => {
                                return <div className="col-md-12" key={i}>
                                    <div className="row" style={{ fontSize: "13px" }}>
                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Serial Number : </div>
                                            <div className="col fw-600">{item.serial_number}</div>
                                        </div>
                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Parent Invoice Number : </div>
                                            <div className="col fw-600">{item.parentInvoiceNumber || '-'}</div>
                                        </div>
                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Ticket Number : </div>
                                            <div className="col fw-600">{item.ticket_num || '-'}</div>
                                        </div>
                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Parent Invoice Status : </div>
                                            <div className="col fw-600">{item.parentInvoiceStatus == 1 ?
                                                (
                                                    <FontAwesomeIcon icon={faStamp} title="Paid" className='' style={{ height: '14px', color: '#2cc3a9' }}
                                                    />) : (
                                                    <FontAwesomeIcon icon={faStamp} title="Not paid" className='' style={{ height: '14px', color: '#db3b3b' }}
                                                    />)}</div>
                                        </div>
                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Created at : </div>
                                            <div className="col fw-600">{formatDateToMMDDYYYY(item.created_at) || '-'}</div>
                                        </div>

                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Parent Invoice Amount : </div>
                                            <div className="col fw-600">{item.parentInvoiceAmount ? `$${item.parentInvoiceAmount}` : <>-</>}</div>
                                        </div>
                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Ticket Status : </div>
                                            <div className="col fw-600">{item.status || '-'}</div>
                                        </div>
                                        <div className="col-lg-6 row py-1 pe-0">
                                            <div className="col ">Billable : </div>
                                            <div className="col fw-600">
                                                <FontAwesomeIcon
                                                    className={`${item.chargeability == 1 ? 'text-blue-billable' : 'text-gray-billable'}`}
                                                    icon={faDollarSign}
                                                    style={{ height: '13px' }}
                                                    title={item.chargeability == 1 ? 'Billable' : 'Non-billable'}
                                                />
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                            })
                            :
                            <div className="col-12 text-center">
                                No Record Found
                            </div>
                        }
                    </div>
                </div>

            )}
        </>
    )
    return (
        <>
            {loading ? <ShowLoder /> : <HideLoder />}
            <Popup isshow={props.isShow} size={"lg"} title={"User Details"}
                closePopup={props.ClosePopup} modalBody={modalbody} visibleSaveBtn={false} />
        </>

    )
}
