import React, { useEffect, useState } from 'react'
import { ApiGetCall } from '../JS/Connector';
import { HideLoder, ShowLoder, formatDateToMMDDYYYY } from '../JS/Common';
import { useNavigate } from 'react-router-dom';
import Tab from './Tab';
import Popup from './Popup';
function TicketDetailsPopup(props) {
    const [TicketTab, setTicketTab] = useState({
        TicketHistoryTab: "",
        PartHistoryTab: "",
        TicketDetailsTab: 'active'
    });
    const [TicketHistoryDetails, setTicketHistoryDetails] = useState([]);
    const [ticketDetails, setticketDetails] = useState('')
    const [AttachPartHistory, setAttachPartHistory] = useState([])
    const [loading, setLoading] = useState(false);
    const [batchNames, setBatchNames] = useState('')
    const [RepairLog, setRepairLog] = useState([])
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    const navigate = useNavigate()
    useEffect(() => {
        ApiGetCall("/getTicketDataById/" + props.ticketid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result)
                setLoading(false);
                var sugData = responseRs.ticket;
                if (responseRs.response == "success") {
                    setticketDetails(sugData);
                    setTicketHistoryDetails(sugData.ticket_history);
                    setAttachPartHistory(sugData.ticket_attachments);
                    setBatchNames(sugData?.IncomingBatchName?.split(', '));
                    setRepairLog(sugData?.ticket_repair_log[0]?.repaired_item);
                }
            }
        });
    }, []);
    function handleTabClick(tabType) {
        if (tabType == "ticketdetails") {
            setTicketTab({ ...TicketTab, TicketDetailsTab: "active", TicketHistoryTab: "", PartHistoryTab: "" });
        } else if (tabType == "tickethistory") {
            setTicketTab({ ...TicketTab, TicketDetailsTab: "", TicketHistoryTab: "active", PartHistoryTab: "" });
        } else if (tabType == "parthistory") {
            setTicketTab({ ...TicketTab, TicketDetailsTab: "", TicketHistoryTab: "", PartHistoryTab: "active" });
        }
    }
    const modalbody = (
        <>
            <ul className="nav nav-tabs">
                <Tab isActive={TicketTab.TicketDetailsTab == 'active'}
                    label="Ticket Details" onClick={() => handleTabClick('ticketdetails')} col={'col-md-3'} />
                <Tab isActive={TicketTab.TicketHistoryTab == 'active'}
                    label="Ticket History" onClick={() => handleTabClick('tickethistory')} col={'col-md-3'} />
                <Tab isActive={TicketTab.PartHistoryTab == 'active'}
                    label="Parts History" onClick={() => handleTabClick('parthistory')} col={'col-md-3'} />
            </ul>
            {TicketTab.TicketDetailsTab == 'active' && (
                <div className="mb-3 mt-3">
                    <div className="row px-4">
                        {ticketDetails && (
                            <>
                                {props.BatchNmFlag == 1 && ticketDetails.BillingBatchFlag == true && (
                                    <div className=" p-1 pe-0 row">
                                        <div className="col fw-600">Outgoing Batch Name : </div>
                                        <div className="col"> {ticketDetails.BillingBatchName}</div>
                                    </div>
                                )}
                                {props.BatchNmFlag == 1 && ticketDetails.IncomingBatchFlag == true && (
                                    <div className=" p-1 pe-0 row">
                                        <div className="col fw-600">Incoming Batch Name : </div>
                                        <div className="col"> <u>
                                            {batchNames.map((item, index) => (
                                                <span key={index} className='ps-1 cursor-pointer' onClick={(e) => navigate('/incoming-batches/' + item)}>
                                                    {item}
                                                    {index !== batchNames.length - 1 ? ', ' : ''}
                                                </span>
                                            ))}
                                        </u></div>
                                    </div>
                                )}
                                <div className=" p-1 pe-0 row">
                                    <div className="col fw-600">Serial # : </div>
                                    <div className="col wordwrap"> {ticketDetails.inventory_management.serial_number}</div>
                                </div>
                                <div className=" p-1 pe-0 row">
                                    <div className="col fw-600">Ticket # : </div>
                                    <div className="col"> {ticketDetails.ticket_num}</div>
                                </div>
                                <div className=" p-1 pe-0 row">
                                    <div className="col fw-600">User : </div>
                                    <div className="col"> {(ticketDetails?.Student?.device_user_first_name || '-') + ' ' + (ticketDetails?.Student?.device_user_middle_name || '') + ' ' + (ticketDetails?.Student?.device_user_last_name || '')}</div>
                                </div>
                                <div className=" p-1 pe-0 row">
                                    <div className="col fw-600">Created Date : </div>
                                    <div className="col"> {formatDateToMMDDYYYY(ticketDetails.created_at)}</div>
                                </div>
                                <div className=" p-1 pe-0 row">
                                    <div className="col fw-600">Created By : </div>
                                    <div className="col"> {ticketDetails.ticketCreatedBy}</div>
                                </div>
                                <div className=" p-1 pe-0 row">
                                    <div className="col fw-600">Building : </div>
                                    <div className="col"> {ticketDetails?.inventory_management?.building_name?.name || '-'}</div>
                                </div>
                                <div className=" p-1 pe-0 row">
                                    <div className="col fw-600">Return Location : </div>
                                    <div className="col"> {ticketDetails.returnLocationName || '-'}</div>
                                </div>
                                <div className=" p-1 pe-0 row">
                                    <div className="col fw-600">Issue : </div>
                                    <div className="col" style={{ display: "inline" }}>
                                        {ticketDetails.ticket_issues.map((item) => item.issue_name).join(", ")}
                                    </div>
                                </div>
                                <div className=" p-1 pe-0 row">

                                    <div className="col fw-600">Notes : </div>
                                    <div className="col" style={{ display: "inline" }}>

                                        {ticketDetails.notes}
                                    </div>
                                </div>
                                <div className=" p-1 pe-0 row">
                                    <div className="col fw-600">Repaired Notes : </div>
                                    <div className="col" style={{ display: "inline" }}>

                                        {RepairLog || '-'}
                                    </div>
                                </div>
                                <div className=" p-1 pe-0 row">
                                    <div className="col fw-600">Images : </div>
                                    <div className="col" style={{ display: "inline" }}>

                                        {ticketDetails.ticket_img.length != 0 ?
                                            <>
                                                {ticketDetails.ticket_img.map((picture, picIndex) => (
                                                    <img key={picIndex} className="px-2" src={`${BucketS3Link}${picture.img}`} alt={`Image ${picIndex}`} style={{ height: '50px' }} />
                                                ))}
                                            </>
                                            : <>-</>
                                        }
                                    </div>
                                </div>
                                <div className=" p-1 pe-0 row">

                                    <div className="col fw-600">Status : </div>
                                    <div className="col" style={{ color: "#3CBBA5" }}>

                                        {ticketDetails.Status}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
            {TicketTab.TicketHistoryTab == 'active' && (
                <div className=" mt-3">
                    <div className="row px-4 scrollbar-css">
                        {TicketHistoryDetails.length != 0 ?
                            <>
                                {TicketHistoryDetails.map((item, i) => {
                                    var returData = <div className="col-12 py-1" key={i}>
                                        On <b>{formatDateToMMDDYYYY(item.date)}</b>,{" "}
                                        {item.updateby} has changed the
                                        ticket status from{" "}
                                        {item.to} to{" "}
                                        {item.from}
                                    </div>
                                    return returData;
                                })}
                            </>
                            :
                            <div className="col-12 text-center">
                                <label>No Record Found</label>
                            </div>
                        }
                    </div>
                </div>
            )}
            {TicketTab.PartHistoryTab == 'active' && (
                <>
                    {AttachPartHistory.length != 0 ?
                        <>
                            {
                                AttachPartHistory.map((item, i) => {
                                    var returData;
                                    returData = (
                                        <div className="row px-4 mt-2" key={i}>
                                            <div className="col-12 p-1 pe-0 row mt-1">
                                                <div className="col-md-3 fw-600">Part Name : </div>
                                                <div className={`${item.part_covered_by == 1 ? 'highlight-part col-md-9' : 'col-md-9'}`}> {item.part_name + ' ' + '(' + (item.part_flag == 1 ? 'Master Part' : 'School Part') + ')'}</div>
                                            </div>
                                            <div className="col-12 p-1 pe-0 row mt-1">
                                                <div className="col-md-3 fw-600">Building : </div>
                                                <div className="col-md-9">  {item.building ? item.building?.name : '-'}</div>
                                            </div>
                                            <div className="col-12 p-1 pe-0 row mt-1">
                                                <div className="col-md-3 fw-600">Created By : </div>
                                                <div className="col-md-9"> {item.user_name}</div>
                                            </div>
                                            <div className="col-12 p-1 pe-0 row mt-1">
                                                <div className="col-md-3 fw-600"> Price : </div>
                                                <div className="col-md-9">${item.part_price.toFixed(2) || '0'}</div>
                                            </div>
                                            <div className="col-12 p-1 pe-0 row mt-1">
                                                <div className="col-md-3 fw-600">Notes : </div>
                                                <div className="col-md-9"> {item.part_note || '-'}</div>
                                            </div>
                                            <img src="/images/HorizontalLine.svg" className="img-fluid w-100 mt-2 ps-0" />
                                        </div>
                                    );
                                    return returData;
                                })
                            }
                        </>
                        :
                        <div className="col-12 pt-3 text-center">
                            <label>No Record Found</label>
                        </div>
                    }
                </>
            )}
        </>
    )
    return (
        <>
            {loading ? <ShowLoder /> : <HideLoder />}
            <Popup isshow={props.isShow} size={"lg"} title={"Ticket Details"}
                closePopup={props.ClosePopup}
                modalBody={modalbody} visibleSaveBtn={false} />
        </>
    )
}

export default TicketDetailsPopup
