import { useState, createContext, useEffect, useRef, useContext } from "react"
import { UserDetails } from "../../Components/UsersDetails";
import { useNavigate } from "react-router-dom";
import { HideLoder, LogInUserID, LogInSchoolID, ShowLoder, YYYYMMDD, isValidEmail, typewatch, getUrlParameter, handleCustomSubmit, getAllBuildings } from "../../JS/Common";
import { ApiGetCall, ApiGetCallAuthKey, ApiPostCall } from "../../JS/Connector";
import AlertsComp from "../../Components/AlertsComp";
import { CheckValidation } from "../../Components/Validations";
import CustomFields from "../CustomField/CustomFields";
import Cookies from "js-cookie";
import { UserContext } from "../../App";
export const userdetailscontent = createContext();
export function AddUpdate() {
    const suggestionBoxRef = useRef(null);
    const [UpdateFlag, setUpdateFlag] = useState(1);
    const [SuggestionBoxArray, setSuggestionBoxArray] = useState([]);
    const navigate = useNavigate();
    const [Alerts, setAlerts] = useState("");
    const [SearchString, setSearchString] = useState('');
    const [FormShowHide, setFormShowHide] = useState({
        AssignDeviceDiv: "",
        UserDiv: "d-none",
        UpdateDiv: "d-none",
        SuggestionBoxShow: "d-none",
        UserDetailDiv: "d-none",
        loanerdisable: false
    });
    const [ShowSuggestionDevice, setShowSuggestionDevice] = useState('d-none');
    const [allData, setAllData] = useState({
        SerialNumber: "",
        DeviceModel: "",
        DeviceManufacturer: "",
        Devicetype: 1,
        DeviceMPN: "",
        AssetTag: "",
        RepairCap: "",
        DeviceOS: "",
        PurchaseDate: "",
        ExpectedRetirement: "",
        ManufacturerWarrentyUntil: "",
        ManufacturerADPUntil: "",
        ThirdPartyWarrantyUntil: "",
        ThirdPartyADPUntil: "",
        ParentEmail: "",
        StudentIDD: null,
        ParentName: "",
        FirstName: "",
        MiddleName: '',
        LastName: "",
        Grade: "",
        Department: "",
        IsStudent: "",
        gradeerror: "",
        Building: "",
        StudentNumber: "",
        ParentPhoneNumber: "",
        ParentCheck: 0,
        emailError: "",
        CheckLoanerDevice: 0,
        CheckAssignDevice: 0,
        Buildingname: "",
        addupdateNotes: '',
        SelectedRooms: '',
        departmenterror: "",
        UserEmail: ""
    });
    const [ExistingNew, setExistingNew] = useState(0)
    const [hideshowflag, sethideshowflag] = useState(0)
    const [Exisitingerror, setExisitingerror] = useState("")
    const [loading, setLoading] = useState(false);
    const [Buildings, setBuildings] = useState([]);
    const [showbuildingdiv, setshowbuildingdiv] = useState("d-none");
    const inputRef = useRef(null);
    const adminschoolid = Cookies.get('AdminSchoolId');
    const { UserData } = useContext(UserContext);
    const FlagForMenuAccess = UserData.MenuAccessFlag;
    const [NewUserFlag, setNewUserFlag] = useState('')
    const [customFields, setCustomFields] = useState([])
    const [OriginalCustomFields, setOriginalCustomFields] = useState([])
    const [errors, setErrors] = useState({});
    const [RoomArray, setRoomArray] = useState([])
    const [radiorequired, setradiorequired] = useState('')
    const [checkboxrequired, setcheckboxrequired] = useState('')
    useEffect(() => {
        GetDeviceById();
        GetCustomFieldsData();
        var schoolid = FlagForMenuAccess == 5 || FlagForMenuAccess == 6 ? adminschoolid : LogInSchoolID
        getAllBuildings(setBuildings, schoolid);
        const handleClickOutside = (event) => {
            if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
                setShowSuggestionDevice('d-none')
            }
        };
        const handleWindowClick = (event) => {
            handleClickOutside(event);
        };
        window.addEventListener('click', handleWindowClick);
        return () => {
            window.removeEventListener('click', handleWindowClick);
        };
    }, []);
    useEffect(() => {
        if (allData.Building === "0") {
            inputRef.current && inputRef.current.focus();
        }
    }, [allData.Building]);
    async function GetCustomFieldsData(id) {
        var Deviceid = getUrlParameter("id")
        Deviceid = Deviceid ? Deviceid : null
        await ApiGetCall("/getStoredCustomFieldsValueForParentByID/" + LogInSchoolID + '&' + 1 + '&' + Deviceid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setCustomFields(responseRs)
                setOriginalCustomFields(responseRs)
                setLoading(false);
            }
        });
    }
    async function GetDeviceById() {
        var Deviceid = getUrlParameter("id");
        if (Deviceid != false) {
            setUpdateFlag(2);
            await ApiGetCall("/getInventoryDataById/" + Deviceid).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    var sugData = responseRs.msg;
                    var existingnew = sugData.student != null ? 1 : 0;
                    setNewUserFlag(sugData.student != null ? sugData.student.id : null)
                    setExistingNew(existingnew)
                    var isstudent = 0;
                    if (sugData.student != null) {
                        if (sugData.student?.grade != "" || sugData.student?.grade != null) {
                            isstudent = 1;
                        }
                    }

                    setAllData({
                        ...allData, SerialNumber: sugData.serial_number,
                        DeviceModel: sugData.device_model,
                        DeviceManufacturer: sugData.device_manufacturer,
                        DeviceMPN: sugData.device_mpn,
                        AssetTag: sugData.asset_tag,
                        RepairCap: sugData.repair_cap,
                        DeviceOS: sugData.device_os,
                        PurchaseDate: sugData.purchase_date && YYYYMMDD(sugData.purchase_date),
                        ExpectedRetirement: sugData.expected_retirement && YYYYMMDD(sugData.expected_retirement),
                        ManufacturerWarrentyUntil: sugData.manufacturer_warranty_until && YYYYMMDD(sugData.manufacturer_warranty_until),
                        ManufacturerADPUntil: sugData.manufacturer_adp_until && YYYYMMDD(sugData.manufacturer_adp_until),
                        ThirdPartyWarrantyUntil: sugData.third_party_extended_warranty_until && YYYYMMDD(sugData.third_party_extended_warranty_until),
                        ThirdPartyADPUntil: sugData.third_party_adp_until && YYYYMMDD(sugData.third_party_adp_until),
                        ParentEmail: sugData.student && (sugData.student.parent_guardian_email),
                        StudentIDD: sugData.student && (sugData.student.id),
                        ParentName: sugData.student && (sugData.student.parent_guardian_name),
                        FirstName: sugData.student && (sugData.student.device_user_first_name),
                        MiddleName: sugData.student && (sugData.student.device_user_middle_name),
                        LastName: sugData.student && (sugData.student.device_user_last_name),
                        Grade: sugData.student && (sugData.student.grade),
                        Department: sugData.student && (sugData.student.department),
                        IsStudent: sugData.student ? (sugData.student.is_student) : 0,
                        Building: sugData.building_id,
                        StudentNumber: sugData.student && (sugData.student.student_num),
                        ParentPhoneNumber: sugData.student && (sugData.student.parent_phone_number),
                        CheckLoanerDevice: sugData.loaner_device,
                        ParentCheck: sugData.student && (sugData.student.parental_coverage),
                        CheckAssignDevice: sugData.student != null ? 1 : 0,
                        emailError: "",
                        addupdateNotes: sugData.notes,
                        SelectedRooms: sugData?.room_details?.id,
                        UserEmail: sugData.student && (sugData.student.student_email)

                    })
                    if (sugData.loaner_device == "1") {
                        setFormShowHide({ ...FormShowHide, AssignDeviceDiv: "d-none", UserDiv: "d-none", SuggestionBoxShow: "d-none", UpdateDiv: "d-none", UserDetailDiv: "d-none" })
                    } else {
                        if (sugData.student != null) {
                            setFormShowHide({ ...FormShowHide, UserDiv: "", UserDetailDiv: "d-none", SuggestionBoxShow: "", AssignDeviceDiv: "", UpdateDiv: "", loanerdisable: true });
                        } else {
                            setFormShowHide({ ...FormShowHide, UserDiv: "d-none", UserDetailDiv: "d-none", SuggestionBoxShow: "d-none", AssignDeviceDiv: "", UpdateDiv: "d-none", });

                        }
                    }
                    if (sugData?.building_id != null) {
                        EditBuildingGetData(sugData?.building_id)
                    }
                }
            });
        } else {
            setUpdateFlag(1);
        }
    }
    async function AddUpdateDevice(flag) {
        var UserFlag = "no";
        var LoanerFlag = "no";
        var existnewflag = "new";
        var deviceid = 0;
        if (getUrlParameter("id") != false) {
            deviceid = getUrlParameter("id");
        }
        var isFormValid = CheckValidation({ formID: 'AddInventoryForm' });
        if (allData.CheckAssignDevice == 0 && isFormValid == true) {
            isFormValid = true;
        }

        if (allData.CheckLoanerDevice == 1) {
            LoanerFlag = "yes";
        }

        if (allData.CheckAssignDevice == 1) {
            if (allData.StudentIDD == null && allData.StudentNumber != '') {
                UserFlag = 'yes'
            } else if (NewUserFlag == allData.StudentIDD) {
                UserFlag = "onlydevicechange";
            } else {
                UserFlag = "yes";
            }
        }
        let errors = {};
        if (ExistingNew == 1 && hideshowflag != 1) {
            existnewflag = "existing";
            if (allData.FirstName == "") {
                setExisitingerror("First you need to add user.")
                isFormValid = false;
            }
        }
        if (allData.ParentEmail == null) {
            allData.ParentEmail = "";
        }
        if (allData.ParentEmail !== "") {
            if (!isValidEmail(allData.ParentEmail)) {
                errors.emailError = "*Invalid Email";
                isFormValid = false;
            } else {
                errors.emailError = "";
            }
        }
        setAllData({ ...allData, ...errors });
        let customFieldValues = handleCustomSubmit(customFields, OriginalCustomFields, flag);
        if (customFields.length > 0) {
            customFields.map((item, index) => {
                if (item.value_type == 6 && item.is_required == 1) {
                    if (item.value == null) {
                        setradiorequired("*required");
                        isFormValid = false;
                    } else {
                        setradiorequired("");

                    }
                }
                if (item.value_type == 7 && item.is_required == 1) {
                    if (item.value.length == 0) {
                        setcheckboxrequired("*required");
                        isFormValid = false;
                    } else {
                        setcheckboxrequired("");

                    }
                }
            })
        }
        if (!isFormValid) return;
        setLoading(true);
        var raw = JSON.stringify({
            ID: deviceid,
            schoolid: FlagForMenuAccess == 5 || FlagForMenuAccess == 6 ? adminschoolid : LogInSchoolID,
            userid: LogInUserID,
            Manufacturerwarrantyuntil: allData.ManufacturerWarrentyUntil,
            ManufacturerADPuntil: allData.ManufacturerADPUntil,
            Thirdpartyextendedwarrantyuntil: allData.ThirdPartyWarrantyUntil,
            ThirdpartyADPuntil: allData.ThirdPartyADPUntil,
            Expectedretirement: allData.ExpectedRetirement,
            PurchaseDate: allData.PurchaseDate,
            Devicemanufacturer: allData.DeviceManufacturer,
            Deviceuserfirstname: allData.FirstName,
            Deviceusermiddlename: allData.MiddleName,
            Devicemodel: allData.DeviceModel,
            Serialnumber: allData.SerialNumber,
            Building: allData.Building == '' || allData.Building == null ? "null" : allData.Building,
            Parentname: allData.ParentName,
            Parentphonenumber: allData.ParentPhoneNumber,
            Deviceos: allData.DeviceOS,
            Deviceuserlastname: allData.LastName,
            Grade: allData.Grade,
            DeviceMPN: allData.DeviceMPN,
            Assettag: allData.AssetTag,
            ParentEmail: allData.ParentEmail,
            Repaircap: allData.RepairCap,
            Loanerdevice: allData.CheckLoanerDevice,
            Parentalcoverage: allData.ParentCheck,
            UserFlag: UserFlag,
            LoanerFlag: LoanerFlag,
            ExistingNewFlag: existnewflag,
            Student_Id: allData.StudentIDD,
            Studentnum: allData.StudentNumber,
            AddUpdateFlag: UpdateFlag,
            BuildingName: allData.Buildingname,
            Notes: allData.addupdateNotes,
            parentObjectType: 1,
            parentObjectID: 0,
            customFieldValues: customFieldValues,
            Department: allData.Department,
            Room: allData.SelectedRooms ? allData.SelectedRooms : null,
            IsStudent: allData.IsStudent,
            Studentemail: allData.UserEmail
        });
        await ApiPostCall("/addUpdateInventory", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                if (responseRs.response == "success") {
                    if (UpdateFlag == 1) {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"Device Added Successfully."} />);
                    } else {
                        setAlerts(<AlertsComp show={true} variant="success" msg={"Device Updated Successfully."} />);
                    }
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                        if (FlagForMenuAccess == 5 || FlagForMenuAccess == 6) {
                            navigate('/school-tickets');
                        } else {
                            if (LoanerFlag == "yes") {
                                navigate('/manage-device', { state: { LoanerFlag } });
                            } else {
                                navigate('/manage-device', { state: null });
                            }
                        }
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                }
            }
        });
    }
    function handelLonerChange(flag) {
        if (flag == "yes") {
            setAllData({ ...allData, CheckLoanerDevice: 1, CheckAssignDevice: 0 });
            setExistingNew(0);
            setFormShowHide({ ...FormShowHide, AssignDeviceDiv: "d-none", UserDiv: "d-none", SuggestionBoxShow: "d-none", UpdateDiv: "d-none", UserDetailDiv: "d-none" })
        } else {
            setFormShowHide({ ...FormShowHide, AssignDeviceDiv: "", UserDiv: "d-none", UserDetailDiv: "d-none" })
            setAllData({ ...allData, CheckLoanerDevice: 0, CheckAssignDevice: 0 });
            setExistingNew(0);
        }
    }
    function handelUserChange(flag) {
        if (flag == "yes") {
            setFormShowHide({ ...FormShowHide, UserDiv: "", UserDetailDiv: "", SuggestionBoxShow: "d-none" });
            setAllData({ ...allData, CheckAssignDevice: 1, ParentCheck: 0 });
            setExistingNew(0)
        } else {
            setFormShowHide({ ...FormShowHide, UserDiv: "d-none", UserDetailDiv: "d-none", SuggestionBoxShow: "", UpdateDiv: "d-none" });
            setAllData({ ...allData, CheckAssignDevice: 0, FirstName: "", MiddleName: "", LastName: "", StudentNumber: "", StudentIDD: null, ParentEmail: "", ParentName: "", Grade: "", IsStudent: 0, Department: "", ParentPhoneNumber: "", ParentCheck: 0 });
            setExistingNew(1)
            sethideshowflag(1)
        }
    }
    function handleExistingnewuser(flag) {
        if (flag == "existing") {
            if (UpdateFlag == 1) {
                setExistingNew(1)
                setFormShowHide({ ...FormShowHide, SuggestionBoxShow: "", UserDetailDiv: "d-none", UpdateDiv: "d-none" });
            } else {
                if (allData.FirstName != null && allData.FirstName != "") {
                    GetDeviceById();
                }
                setFormShowHide({ ...FormShowHide, SuggestionBoxShow: "", UserDetailDiv: "d-none", UpdateDiv: "" });
                setAllData({ ...allData, FirstName: "", MiddleName: "", LastName: "", StudentNumber: "", StudentIDD: null, ParentEmail: "", ParentName: "", Grade: "", IsStudent: 0, Department: "", ParentPhoneNumber: "", ParentCheck: 0 });
                setExistingNew(1)
            }
        } else {
            setExistingNew(0)
            setAllData({ ...allData, FirstName: "", MiddleName: "", LastName: "", StudentNumber: "", StudentIDD: null, ParentEmail: "", ParentName: "", Grade: "", IsStudent: 0, Department: "", ParentPhoneNumber: "", ParentCheck: 0 });
            setFormShowHide({ ...FormShowHide, SuggestionBoxShow: "d-none", UserDetailDiv: "", UpdateDiv: "d-none" });
        }
    }
    async function SearchUsers(SearchString) {
        setSuggestionBoxArray([]);
        if (SearchString == "") {
            SearchString = null;
            setShowSuggestionDevice('d-none')
        }
        typewatch(async function () {
            await ApiGetCall("/SearchUtilizer/" + LogInSchoolID + "&" + SearchString).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    var sugData = responseRs;
                    var vArray = [];
                    if (sugData.length != 0) {
                        setShowSuggestionDevice("");
                        for (var i = 0; i < sugData.length; i++) {
                            var html = <div className="row brdr-Btm font-14" key={i} style={{ padding: "8px 15px" }} onClick={(e) => ShowUserDetails(e)} utilizerid={sugData[i].id}>
                                <div className='col-9'>User : <b>{sugData[i].device_user_first_name} {sugData[i].device_user_middle_name == null ? '' : sugData[i].device_user_middle_name} {sugData[i].device_user_last_name}</b></div>
                                <div className="col-3 text-end">{sugData[i].student_num}</div>
                                <div className="col-12">{sugData[i].parent_guardian_email}</div>
                            </div>
                            vArray.push(html);
                        }
                    } else {
                        var html = <div className="brdr-Btm font-14 text-center" style={{ padding: "8px 15px" }}>
                            No Record Found
                        </div>
                        vArray.push(html)
                    }
                    setSuggestionBoxArray(vArray);
                    setLoading(false);
                }
            });
        }, 500);

    }
    async function ShowUserDetails(e) {
        var utilizerid = e.currentTarget.attributes[1].value;
        await ApiGetCall("/utilizerdatabyID/" + utilizerid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                var sugData = responseRs.msg;
                if (responseRs.status == "success") {
                    setFormShowHide({ ...FormShowHide, UpdateDiv: "" });
                    setShowSuggestionDevice("d-none");
                    setAllData({
                        ...allData, FirstName: sugData.device_user_first_name, MiddleName: sugData.device_user_middle_name, LastName: sugData.device_user_last_name,
                        StudentNumber: sugData.student_num, ParentName: sugData.parent_guardian_name,
                        ParentEmail: sugData.parent_guardian_email, ParentPhoneNumber: sugData.parent_phone_number, ParentCheck: sugData.parental_coverage,
                        StudentIDD: sugData.id, Grade: sugData.grade, Department: sugData.department, IsStudent: sugData.is_student
                    })
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                }
            }
        });
    }

    const handleFieldChange = (index, newValue) => {
        const newArray = [...customFields];
        newArray[index] = { ...newArray[index], value: newValue };
        setCustomFields(newArray);
    };

    async function EditBuildingGetData(id) {
        await ApiGetCallAuthKey("/getBuildingDataById/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs?.msg?.room.length != 0) {
                    setRoomArray(responseRs?.msg?.room)
                } else {
                    setRoomArray([])
                }
            }
        });
    }
    return (
        <>
            <userdetailscontent.Provider value={{ allData, setAllData }}>
                {Alerts}
                {loading ? <ShowLoder /> : <HideLoder />}
                <div>
                    <div className="GridBox mt-2 p-3">
                        <div className='greyBox'>
                            <div className='Header'>
                                {UpdateFlag == 1 ?
                                    <b className='font-16'>Add New Device</b>
                                    :
                                    <b className='font-16'>Edit Device</b>
                                }
                                <br />
                                <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
                            </div>
                            <div id='AddInventoryForm' className="parent">
                                {/* device details div */}
                                <div className='row px-2 justify-content-between'>
                                    <div className='col-md-6 row align-items-center'>
                                        <div className='col-md-7 FormLabel'>Serial # <label className='redText'>*</label></div>
                                        <div className='col-md-5'>
                                            <input type="text" autoComplete='off' name='serialnumber' className="form-control" required value={allData.SerialNumber}
                                                onChange={(e) => setAllData({ ...allData, SerialNumber: e.target.value.toUpperCase() })} />
                                            <span className="form-text invalid-feedback">
                                                *required
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-md-6 row pt-2'>
                                        <div className='col-md-7 FormLabel'>Building</div>
                                        <div className='col-md-5'>
                                            <div className='col-12'>
                                                <select defaultValue={allData.Building} onChange={(e) => {
                                                    setAllData({ ...allData, Building: e.target.value, SelectedRooms: '' });
                                                    if (e.target.value == "0") {
                                                        setshowbuildingdiv("");
                                                        setRoomArray([]);
                                                    } else if (e.target.value == "") {
                                                        setRoomArray([]);
                                                        setshowbuildingdiv("d-none");
                                                    }
                                                    else {
                                                        setshowbuildingdiv("d-none");
                                                        EditBuildingGetData(e.target.value)
                                                    }
                                                }}>
                                                    <option value="">Select Building</option>
                                                    <option value="0">Other</option>
                                                    {Buildings?.map((item1, j) => (
                                                        <option value={item1.id} key={j} selected={allData.Building == item1.id}>
                                                            {item1.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className={`col-12 py-3 parent ${showbuildingdiv}`}>
                                                <input type="text" ref={inputRef} placeholder="Enter Building" autoComplete='off' name='building' className="form-control" value={allData.Buildingname} onChange={(e) => setAllData({ ...allData, Buildingname: e.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                    {RoomArray?.length != 0 && (
                                        <div className='col-md-6 row align-items-center'>
                                            <div className='col-md-7 FormLabel'>Room</div>
                                            <div className='col-md-5'>
                                                <div className='col-12'>
                                                    <select defaultValue={allData.SelectedRooms} required onChange={(e) => {
                                                        setAllData({ ...allData, SelectedRooms: e.target.value });
                                                    }}>
                                                        <option value="">Select Room</option>
                                                        {RoomArray?.map((item1, j) => (
                                                            <option value={item1.id} key={j} selected={allData.SelectedRooms == item1.id}>
                                                                {item1.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="form-text invalid-feedback">
                                                        *required
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className='col-md-6 row align-items-center pt-2'>
                                        <div className='col-md-7 FormLabel'>Device Model</div>
                                        <div className='col-md-5'>
                                            <input type="text" autoComplete='off' name='devicemodel' className="form-control" value={allData.DeviceModel}
                                                onChange={(e) => setAllData({ ...allData, DeviceModel: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className='col-md-6 row align-items-center pt-2'>
                                        <div className='col-md-7 FormLabel'>Device Manufacturer</div>
                                        <div className='col-md-5'>
                                            <input type="text" autoComplete='off' name='devicemanufacturer' className="form-control" value={allData.DeviceManufacturer}
                                                onChange={(e) => setAllData({ ...allData, DeviceManufacturer: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className='col-md-6 row align-items-center pt-2'>
                                        <div className='col-md-7 FormLabel'>Device MPN</div>
                                        <div className='col-md-5'>
                                            <input type="text" autoComplete='off' name='devicempn' className="form-control" value={allData.DeviceMPN}
                                                onChange={(e) => setAllData({ ...allData, DeviceMPN: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className='col-md-6 row align-items-center pt-2'>
                                        <div className='col-md-7 FormLabel'>Asset Tag</div>
                                        <div className='col-md-5'>
                                            <input type="text" autoComplete='off' name='assettag' className="form-control" value={allData.AssetTag}
                                                onChange={(e) => setAllData({ ...allData, AssetTag: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className='col-md-6 row align-items-center pt-2 '>
                                        <div className='col-md-7 FormLabel'>Repair Cap</div>
                                        <div className='col-md-5'>
                                            <div className="input-group ">
                                                <span className="input-group-text">$</span>
                                                <input type="number" autoComplete='off' className="form-control" name='repaircap' min={0} placeholder='00' value={allData.RepairCap}
                                                    onChange={(e) => setAllData({ ...allData, RepairCap: e.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 row align-items-center pt-2'>
                                        <div className='col-md-7 FormLabel'>Device OS</div>
                                        <div className='col-md-5'>
                                            <input type="text" autoComplete='off' name='deviceos' className="form-control" value={allData.DeviceOS}
                                                onChange={(e) => setAllData({ ...allData, DeviceOS: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-12 pt-2">
                                        <div className='col-md-12 FormLabel'>Notes</div>
                                        <div className='col-md-6 pt-1'>
                                            <textarea type="text" autoComplete='off' name='deviceos' className="form-control" rows={2} value={allData.addupdateNotes}
                                                onChange={(e) => setAllData({ ...allData, addupdateNotes: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className='col-12 text-center pt-2'>
                                        <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
                                    </div>
                                </div>

                                {/* date fields */}
                                <div className='row px-2 justify-content-between'>
                                    <div className='col-md-6 row align-items-center '>
                                        <div className='col-md-7 FormLabel'>Purchase Date</div>
                                        <div className='col-md-5'>
                                            <input type="date" autoComplete='off' name='PurchaseDate' className="form-control" value={allData.PurchaseDate}
                                                onChange={(e) => setAllData({ ...allData, PurchaseDate: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className='col-md-6 row align-items-center '>
                                        <div className='col-md-7 FormLabel'>Expected Retirement</div>
                                        <div className='col-md-5'>
                                            <input type="date" autoComplete='off' name='expectedretirement' className="form-control" value={allData.ExpectedRetirement}
                                                onChange={(e) => setAllData({ ...allData, ExpectedRetirement: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className='col-md-6 row align-items-center pt-2'>
                                        <div className='col-md-7 FormLabel'>Manufacturer Warranty Until</div>
                                        <div className='col-md-5'>
                                            <input type="date" autoComplete='off' name='ManufacturerWarrentyUntil' className="form-control" value={allData.ManufacturerWarrentyUntil}
                                                onChange={(e) => setAllData({ ...allData, ManufacturerWarrentyUntil: e.target.value })} />
                                        </div>
                                    </div>
                                    <div className='col-md-6 row align-items-center pt-2'>
                                        <div className='col-md-7 FormLabel'>Manufacturer ADP Until</div>
                                        <div className='col-md-5'>
                                            <input type="date" autoComplete='off' name='ManufacturerADPUntil' className="form-control" value={allData.ManufacturerADPUntil}
                                                onChange={(e) => setAllData({ ...allData, ManufacturerADPUntil: e.target.value })} />

                                        </div>
                                    </div>
                                    <div className='col-md-6 row align-items-center pt-2'>
                                        <div className='col-md-7 FormLabel'>Third Party Extended Warranty Until</div>
                                        <div className='col-md-5'>
                                            <input type="date" autoComplete='off' name='ThirdPartywarrantyuntil' className="form-control" value={allData.ThirdPartyWarrantyUntil}
                                                onChange={(e) => setAllData({ ...allData, ThirdPartyWarrantyUntil: e.target.value })} />

                                        </div>
                                    </div>
                                    <div className='col-md-6 row align-items-center pt-2'>
                                        <div className='col-md-7 FormLabel'>Third Party ADP Until</div>
                                        <div className='col-md-5'>
                                            <input type="date" autoComplete='off' name='thirdpartyadpuntil' className="form-control" value={allData.ThirdPartyADPUntil}
                                                onChange={(e) => setAllData({ ...allData, ThirdPartyADPUntil: e.target.value })} />

                                        </div>
                                    </div>
                                    <div className='col-12 text-center pt-2'>
                                        <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />
                                    </div>
                                </div>

                                {/* custom field */}
                                <div className="p-2">
                                    <div className='row px-2 justify-content-between my2'>
                                        {customFields.map((field, index) => {
                                            return (
                                                <>
                                                    <div className='col-md-6 row align-items-center py-1'>
                                                        <div className='col-md-7 FormLabel'>{field.name.charAt(0).toUpperCase() + field.name.slice(1)} {field.is_required == 1 && <span className="text-danger">*</span>}</div>
                                                        <div className='col-md-5'>
                                                            <CustomFields
                                                                key={field.id}
                                                                type={parseInt(field.value_type)}
                                                                value={field?.value}
                                                                options={field?.customefieldstructures}
                                                                isrequired={field.is_required}
                                                                radiorequired={radiorequired}
                                                                checkboxrequired={checkboxrequired}
                                                                onChange={(value) => handleFieldChange(index, value)}
                                                            />
                                                            {errors[field.id] && <span className="text-danger">{errors[field.id]}</span>}
                                                        </div>
                                                    </div>
                                                </>

                                            )
                                        })}
                                    </div>

                                    {customFields.length != 0 && <img src='/images/HorizontalLine.svg' className='img-fluid w-100' />}
                                </div>


                                {/* radio button div */}
                                <div className='row p-2 justify-content-between'>
                                    <div className='col-12 row align-items-center pt-2'>
                                        <div className='col-md-5 FormLabel'>Is this device a loaner device?</div>
                                        {allData.CheckLoanerDevice == 1 ?
                                            <div className='col-md-7 d-flex' >
                                                <div className="form-check" >
                                                    <input className="form-check-input" disabled={FormShowHide.loanerdisable} type="radio" name='LoanerDevice' defaultChecked onClick={(e) => { setFormShowHide({ ...FormShowHide, AssignDeviceDiv: 'd-none' }); handelLonerChange("yes") }} />
                                                    <label className="form-check-label">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check ms-5">
                                                    <input className="form-check-input" disabled={FormShowHide.loanerdisable} type="radio" name='LoanerDevice' onClick={(e) => { setFormShowHide({ ...FormShowHide, AssignDeviceDiv: '' }); handelLonerChange("no") }} />
                                                    <label className="form-check-label">
                                                        No
                                                    </label>
                                                </div>
                                                <span className="form-text invalid-feedback">
                                                    *required
                                                </span>
                                            </div>
                                            :
                                            <div className='col-md-7 d-flex' >
                                                <div className="form-check" >
                                                    <input className="form-check-input" disabled={FormShowHide.loanerdisable} type="radio" name='LoanerDevice' onClick={(e) => { setFormShowHide({ ...FormShowHide, AssignDeviceDiv: 'd-none' }); handelLonerChange("yes") }} />
                                                    <label className="form-check-label">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check ms-5">
                                                    <input className="form-check-input" disabled={FormShowHide.loanerdisable} type="radio" name='LoanerDevice' defaultChecked onClick={(e) => { setFormShowHide({ ...FormShowHide, AssignDeviceDiv: '' }); handelLonerChange("no") }} />
                                                    <label className="form-check-label">
                                                        No
                                                    </label>
                                                </div>
                                                <span className="form-text invalid-feedback">
                                                    *required
                                                </span>
                                            </div>
                                        }

                                    </div>
                                    <div className={`col-12 row align-items-center pt-3 ${FormShowHide.AssignDeviceDiv}`}>
                                        <div className='col-md-5 FormLabel'>Do you want to assign this device to user?</div>
                                        {allData.CheckAssignDevice == 1 ?
                                            <div className='col-md-7 d-flex'>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name='AssignDevice' checked={allData.CheckAssignDevice === 1} onClick={(e) => { setFormShowHide({ ...FormShowHide, UserDiv: '' }); setAllData({ ...allData, CheckAssignDevice: 1 }); setExistingNew(1) }} onChange={(e) => handelUserChange("yes")} />
                                                    <label className="form-check-label">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check ms-5">
                                                    <input className="form-check-input" type="radio" name='AssignDevice' checked={allData.CheckAssignDevice === 0} onClick={(e) => { setFormShowHide({ ...FormShowHide, UserDiv: 'd-none' }); setAllData({ ...allData, CheckAssignDevice: 0 }); setExistingNew(0) }} onChange={(e) => handelUserChange("no")} />
                                                    <label className="form-check-label">
                                                        No
                                                    </label>
                                                </div>
                                            </div> :
                                            <div className='col-md-7 d-flex'>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name='AssignDevice' checked={allData.CheckAssignDevice === 1} onClick={(e) => { setFormShowHide({ ...FormShowHide, UserDiv: '' }); setAllData({ ...allData, CheckAssignDevice: 1 }); setExistingNew(1) }} onChange={(e) => handelUserChange("yes")} />
                                                    <label className="form-check-label">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check ms-5">
                                                    <input className="form-check-input" type="radio" name='AssignDevice' checked={allData.CheckAssignDevice === 0} onClick={(e) => { setFormShowHide({ ...FormShowHide, UserDiv: 'd-none' }); setAllData({ ...allData, CheckAssignDevice: 0 }); setExistingNew(0) }} onChange={(e) => handelUserChange("no")} />
                                                    <label className="form-check-label">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>

                                {/* user details div */}
                                <div className={`row parent ${FormShowHide.UserDiv}`}>
                                    <img src='/images/HorizontalLine.svg' className='img-fluid w-100 mt-2 px-3' />
                                    <div className='col-md-5 pt-3'>
                                        <h3>User Details</h3>
                                    </div>
                                    <div className={`col-md-7 row align-items-center text-start`}>
                                        <div className='d-flex'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name='existingnew' checked={ExistingNew === 1} onChange={(e) => handleExistingnewuser("existing")} />
                                                <label className="form-check-label">
                                                    Existing
                                                </label>
                                            </div>
                                            <div className="form-check ms-3">
                                                <input className="form-check-input" type="radio" name='existingnew' checked={ExistingNew === 0} onChange={(e) => handleExistingnewuser("new")} />
                                                <label className="form-check-label">
                                                    New
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-md-6 mt-2 ${FormShowHide.SuggestionBoxShow}`}>
                                        <form className="gridsearchbar" onSubmit={(e) => e.preventDefault()}>
                                            <div className="position-absolute top-50 translate-middle-y search-icon ms-3 searchIcon"><i className="bi bi-search"></i></div>
                                            <input className="form-control" autoComplete="off" type="text" placeholder="Search User" onKeyUp={(e) => SearchUsers(e.target.value)} value={SearchString} onChange={(e) => setSearchString(e.target.value)} />
                                            <div className={`SuggestionBoxDiv ${ShowSuggestionDevice}`} ref={suggestionBoxRef}>
                                                {SuggestionBoxArray}
                                            </div>
                                        </form>
                                    </div>
                                    <div className={`row ps-4 ${FormShowHide.UpdateDiv}`}>
                                        <div className="col-md-6 row py-2">
                                            <div className="col-md-6 col-6">First Name:</div>
                                            <div className="col-md-6 col-6"><b>{allData.FirstName || '-'}</b></div>
                                        </div>
                                        <div className="col-md-6 row py-2">
                                            <div className="col-md-6 col-6">Middle Name:</div>
                                            <div className="col-md-6 col-6"><b>{allData.MiddleName || '-'}</b></div>
                                        </div>
                                        <div className="col-md-6 row py-2">
                                            <div className="col-md-6 col-6">Last Name:</div>
                                            <div className="col-md-6 col-6"><b>{allData.LastName || '-'}</b></div>
                                        </div>
                                        <div className="col-md-6 row py-2">
                                            <div className="col-md-6 col-6">Student Number:</div>
                                            <div className="col-md-6 col-6"><b>{allData.StudentNumber || '-'}</b></div>
                                        </div>
                                        <div className="col-md-6 row py-2">
                                            <div className="col-md-6 col-6">User Email:</div>
                                            <div className="col-md-6 col-6"><b>{allData.UserEmail || '-'}</b></div>
                                        </div>
                                        <div className="col-md-6 row py-2">
                                            <div className="col-md-6 col-6">Parent Email:</div>
                                            <div className="col-md-6 col-6"><b>{allData.ParentEmail ? allData.ParentEmail : <>-</>}</b></div>
                                        </div>
                                        <div className="col-md-6 row py-2">
                                            <div className="col-md-6 col-6">Parent Name:</div>
                                            <div className="col-md-6 col-6"><b>{allData.ParentName ? allData.ParentName : <>-</>}</b></div>
                                        </div>
                                        <div className="col-md-6 row py-2">
                                            <div className="col-md-6 col-6">Is the user a student?:</div>
                                            <div className="col-md-6 col-6"><b>{allData.IsStudent == 1 ? 'Yes' : 'No'}</b></div>
                                        </div>
                                        <div className="col-md-6 row py-2">
                                            <div className="col-md-6 col-6">Grade:</div>
                                            <div className="col-md-6 col-6"><b>{allData.Grade || '-'}</b></div>
                                        </div>
                                        <div className="col-md-6 row py-2">
                                            <div className="col-md-6 col-6">Department:</div>
                                            <div className="col-md-6 col-6"><b>{allData.Department || '-'}</b></div>
                                        </div>
                                        <div className="col-md-6 row py-2">
                                            <div className="col-md-6 col-6">Parent Phone Number:</div>
                                            <div className="col-md-6 col-6"><b>{allData.ParentPhoneNumber || '-'}</b></div>
                                        </div>
                                        <div className="col-md-6 row py-2">
                                            <div className="col-md-6 col-6">Parental Coverage:</div>
                                            <div className="col-md-6 col-6"><b>{allData.ParentCheck == 1 ? "Yes" : "No"}</b></div>
                                        </div>
                                    </div>
                                    <h6 className="redText">{Exisitingerror}</h6>
                                    <div className={`${FormShowHide.UserDetailDiv} parent`}>
                                        <UserDetails />
                                    </div>
                                </div>

                                {/* save cancel button div */}
                                <div className='col-12 text-center pt-4'>
                                    {UpdateFlag == 1 ?
                                        <button className='SaveBtn' onClick={(e) => AddUpdateDevice(1)}>Save Device</button>
                                        :
                                        <button className='SaveBtn' onClick={(e) => AddUpdateDevice(2)}>Update Device</button>
                                    }
                                    <label className="ms-2 cursor-pointer" onClick={(e) => { FlagForMenuAccess == 5 || FlagForMenuAccess == 6 ? navigate("/school-tickets") : navigate("/manage-device") }}><u>Cancel</u></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </userdetailscontent.Provider >
        </>
    )
}
