import React, { useContext, useEffect, useRef, useState } from 'react'
import { ApiGetCall, ApiPostCall } from '../../JS/Connector';
import { CheckValidation } from '../../Components/Validations';
import { getpostalcodes, HideLoder, LogInUserID, ShowLoder } from '../../JS/Common';
import AlertsComp from '../../Components/AlertsComp';
import { UserContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from "react-signature-canvas";
import Popup from '../../Components/Popup';
function AdminCreateBatch(props) {
    const { UserData } = useContext(UserContext);
    const accountnumber = process.env.REACT_APP_Fedex_Accountnumber;
    const navigate = useNavigate();
    const todayDate = new Date().toISOString().substring(0, 10)
    const [createBatchFormData, setCreateBatchFormData] = useState({
        batchName: '',
        Notes: '',
        fileName: '',
        ExtraDocument: ''
    })
    const [isChecked, setIsChecked] = useState(false);
    const [BatchData, setBatchData] = useState({
        createbatchflag: 1,
        Phoneno: '',
        Street1: '',
        Street2: '',
        city: '',
        state: 0,
        postalcode: "",
        labelsize: 0,
        Weight: '',
        boxsize: '',
        width: "",
        height: "",
        depth: "",
    })
    const [AllState, setallstate] = useState([]);
    const [deliveryaddress, setdeliveryaddress] = useState({
        streetline1: "",
        streetline2: "",
        city: "",
        stateOrProvinceCode: "",
        postalCode: "",
        phone_no: ""
    });
    const [Recipients, setRecipients] = useState({
        Name: '',
        Contact: '',
        SchoolName: ''
    })
    const [schoolDetail, setSchoolDetail] = useState('')
    const [showpackingtypelist, setshowpackingtypelist] = useState("d-none");
    const [showcustominputs, setshowcustominputs] = useState("d-none");
    const [WeightError, setWeightError] = useState("");
    const [shipmentButton, setshipmentButton] = useState(true);
    const [ValidateError, setValidateError] = useState('');
    const [ValidateColor, setValidateColor] = useState('');
    const [buildingData, setbuildingData] = useState([]);
    const [ReturnAddress, setReturnAddress] = useState({
        street_line_1: '',
        street_line_2: '',
        city: '',
        state: '',
        postal_code: ''
    });
    const [ReturnAddressId, setReturnAddressId] = useState(null);
    const [NewAlerts, setNewAlerts] = useState("");
    const [loading, setLoading] = useState(false);
    const [SignatureDataForPackedBy, setSignatureDataForPackedBy] = useState('')
    const sigCanvasForPackedBy = useRef({});
    useEffect(() => {
        GetAddress();
        GetReturnLocations();
        setshowpackingtypelist('d-none')
        setshowcustominputs('d-none')
        getpostalcodes(setallstate);
        setCreateBatchFormData({ ...createBatchFormData, batchName: '' })
        const now = new Date();
        const today = todayDate;
        const time = now.toLocaleTimeString("en-US", { hour12: false }).replace(/:/g, "");
        const batchName = `batch_${today}_${time}`;
        setCreateBatchFormData({ ...createBatchFormData, batchName: batchName })

    }, [])

    async function GetReturnLocations() {
        var vArray = [];
        {
            props.selectedItems.map((item) => {
                vArray.push(item.ID)
            })
        }
        var raw = JSON.stringify({
            schoolId: props.schoolId,
            ticketIds: vArray
        });
        await ApiPostCall("/getReturnLocations", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    setbuildingData(responseRs.msg)
                }
            }
        });
    }

    async function GetAddress() {
        const now = new Date();
        const today = new Date().toISOString().substring(0, 10);
        const time = now.toLocaleTimeString("en-US", { hour12: false }).replace(/:/g, "");
        const batchName = `batch_${today}_${time}`;
        await ApiGetCall("/getSchoolAddress/" + props.schoolId).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                var delieveryaddress = responseRs.adminAddress;
                var shippingaddress = responseRs.schoolAddress;
                setSchoolDetail(responseRs?.schoolDetail?.shipping_type)
                setRecipients({ ...Recipients, SchoolName: shippingaddress?.fedex_company_name || responseRs?.schoolDetail?.name, Contact: shippingaddress?.phone_num, Name: responseRs?.userDetails?.first_name + ' ' + responseRs?.userDetails?.last_name })
                setdeliveryaddress({
                    ...deliveryaddress, streetline1: shippingaddress.street_line, streetline2: shippingaddress.street_line2, city: shippingaddress.city, stateOrProvinceCode: shippingaddress.state_or_province_code,
                    postalCode: shippingaddress.postal_code, phone_no: shippingaddress.phone_num
                })
                if (delieveryaddress == null) {
                    setBatchData({ ...BatchData, batchname: batchName, notes: "", createbatchflag: 1, Phoneno: "", Street1: "", Street2: '', city: "", state: 0, postalcode: "", labelsize: 0, Weight: "" })
                } else {
                    setBatchData({ ...BatchData, batchname: batchName, notes: "", createbatchflag: 1, Phoneno: delieveryaddress.contact_number, Street1: delieveryaddress.street_line_1, Street2: delieveryaddress.street_line_2, city: delieveryaddress.city, state: delieveryaddress.state_or_province_code, postalcode: delieveryaddress.postal_code })
                }
                setReturnAddress({
                    ...ReturnAddress,
                    street_line_1: shippingaddress.street_line || '',
                    street_line_2: shippingaddress.street_line2 || '',
                    city: shippingaddress.city || '',
                    state: shippingaddress.state_or_province_code || '',
                    postal_code: shippingaddress.postal_code || '',
                });
            }
        });
    }

    async function ChangeDeviceType(devicetypevalue) {
        if (devicetypevalue != 4 && devicetypevalue != 0) {
            var raw = JSON.stringify({
                schoolId: props.schoolId,
                DeviceType: devicetypevalue,
                TicketArray: props.selectedItems
            });
            await ApiPostCall("/calculateTheBatchWeight", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setshowpackingtypelist("");
                    setBatchData({ ...BatchData, Weight: responseRs });
                }
            });
        } else {
            setshowpackingtypelist("");
            setBatchData({ ...BatchData, Weight: "" });
        }
    }

    function addcustominputs(selectedid) {
        var vVal = selectedid.split('-');
        if (selectedid == "custom") {
            setBatchData({ ...BatchData, depth: "", width: "", height: "" });
            setshowcustominputs("");
        } else {
            setBatchData({ ...BatchData, depth: vVal[0], width: vVal[1], height: vVal[2] });
            setshowcustominputs("d-none");
        }
    }

    async function handlePdfFileUpload(e) {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result.split(",")[1];
            setCreateBatchFormData({ ...createBatchFormData, fileName: file.name, ExtraDocument: base64String });
        };
        reader.readAsDataURL(file);
    }

    function handlecheckboxchange(e) {
        if (e.target.checked == true) {
            setIsChecked(true)
            setBatchData({ ...BatchData, createbatchflag: 2 })
            setshipmentButton(false)
        } else {
            setIsChecked(false)
            setBatchData({ ...BatchData, createbatchflag: 1 })
            setshipmentButton(true)
        }
    }

    async function createBatchSubmit(flag) {
        var isFormValid = CheckValidation({ formID: 'batchNameRequired' });
        if (BatchData.createbatchflag != 1) {
            if (BatchData.Weight > 55) {
                setWeightError("Max Value");
                isFormValid = false;
            } else {
                setWeightError("");
            }
        }
        if (!isFormValid) return;
        var signatureBase64 = null;
        signatureBase64 = sigCanvasForPackedBy.current.isEmpty() ? null : sigCanvasForPackedBy.current.getTrimmedCanvas().toDataURL("image/png");
        setLoading(true);

        if (BatchData.createbatchflag == 1) {
            shippingrequest = {
                "BatchName": createBatchFormData.batchName,
                "Notes": createBatchFormData.Notes,
                "SchoolId": props.schoolId,
                "TicketArray": props.selectedItems,
                "BatchFlag": BatchData.createbatchflag,
                "TotalTicketAmount": props.IsModal.subtotal,
                "CreatedBatchDate": props.todayDate,
                "File": createBatchFormData.ExtraDocument,
                "ShippingMethod": 0,
                "userId": LogInUserID,
                "BatchDeliveredSignature": signatureBase64,
                "returnLocation": ReturnAddressId == "0" ? null : ReturnAddressId
            }
        } else {
            var shippingrequest = {
                "labelResponseOptions": "URL_ONLY",
                "requestedShipment": {
                    "shipper": {
                        "contact": {
                            "personName": UserData.UserName,
                            "phoneNumber": parseInt(BatchData.Phoneno),
                            "companyName": "K-12 Repair Tech"
                        },
                        "address": {
                            "streetLines": [
                                ...(BatchData.Street1 + (BatchData.Street2 ? ', ' + BatchData.Street2 : '')).split(', ')
                            ],
                            "city": BatchData.city,
                            "stateOrProvinceCode": BatchData.state,
                            "postalCode": BatchData.postalcode,
                            "countryCode": "US"
                        }
                    },
                    "recipients": [
                        {
                            "contact": {
                                "personName": Recipients?.Name,
                                "phoneNumber": Recipients?.Contact,
                                "companyName": Recipients?.SchoolName
                            },
                            "address": {
                                "streetLines": [
                                    ...(ReturnAddress.street_line_1 + (ReturnAddress.street_line_2 ? ', ' + ReturnAddress.street_line_2 : '')).split(', ')
                                ],
                                "city": ReturnAddress.city,
                                "stateOrProvinceCode": ReturnAddress.state,
                                "postalCode": ReturnAddress.postal_code,
                                "countryCode": "US"
                            }
                        }
                    ],
                    "serviceType": "FEDEX_GROUND",
                    "packagingType": "YOUR_PACKAGING",
                    "pickupType": "USE_SCHEDULED_PICKUP",
                    "blockInsightVisibility": false,
                    "shippingChargesPayment": {
                        "paymentType": "SENDER"
                    },
                    "labelSpecification": {
                        "imageType": "PNG",
                        "labelStockType": BatchData.labelsize,
                        "labelRotation": "NONE"
                    },
                    "requestedPackageLineItems": [
                        {
                            "weight": {
                                "units": "LB",
                                "value": parseInt(BatchData.Weight)
                            },
                            "dimensions": {
                                "length": parseInt(BatchData.depth),
                                "width": parseInt(BatchData.width),
                                "height": parseInt(BatchData.height),
                                "units": "IN"
                            }
                        }
                    ]
                },
                "accountNumber": {
                    "value": accountnumber
                },
                "BatchName": createBatchFormData.batchName,
                "Notes": createBatchFormData.Notes,
                "SchoolId": props.schoolId,
                "TicketArray": props.selectedItems,
                "BatchFlag": BatchData.createbatchflag,
                "ShippingMethod": 1,
                "File": createBatchFormData.ExtraDocument,
                "CreatedBatchDate": props.todayDate,
                "Weight": BatchData.Weight,
                "userId": LogInUserID,
                "BatchDeliveredSignature": signatureBase64,
                "returnLocation": ReturnAddressId == "0" ? null : ReturnAddressId
            }
        }
        var raw = JSON.stringify(shippingrequest);
        if (flag == 2) {
            await ApiPostCall("/createInvoiceBatchwithFedex", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    if (responseRs.status == "success") {
                        setNewAlerts(<AlertsComp show={true} variant="success" msg={"Batch Created Successfully."} />);
                        setTimeout(() => {
                            props.setselectedbatchid(0)
                            setNewAlerts(<AlertsComp show={false} />)
                            props.setIsModal({ ...props.IsModal, CreateBatchmodal: false, CreateBatchbuttonVisible: false })
                            setLoading(false);
                            if (BatchData.createbatchflag == 2) {
                                window.open(responseRs.Msg.url, '_blank');
                            }
                            navigate("/admin-invoice")
                        }, 2500);
                    }
                    else {
                        setNewAlerts(<AlertsComp show={true} variant="danger" msg={"Please ensure the accuracy of your data for FedEx delivery."} />);
                        setLoading(false);
                        props.GetGridData(null, props.tabflag, props.batchid, null)
                        setTimeout(() => {
                            props.setSelectedIdsOpen([])
                            props.setSelectedIdsPedning([])
                            props.setSelectedIdsSentOut([])
                            setNewAlerts(<AlertsComp show={false} />)
                        }, 2500);
                    }
                }
            });
        } else {
            await ApiPostCall("/validateShipment", raw).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    if (!deliveryaddress.streetline1) {
                        setValidateError('First Need to Set Delivery Address')
                        setValidateColor('redText')
                    } else {
                        if (responseRs.status == "success") {
                            setshipmentButton(true)
                            setValidateError('Your Shipping details are valid. You can go ahead and click on Create Batch.')
                            setValidateColor('greenText')
                        }
                        else {
                            setValidateError(responseRs.msg);
                            setshipmentButton(false)
                            setValidateColor('redText')
                        }
                    }

                }
            });
        }
    }
    const clearSignatureForPackedBy = () => {
        if (SignatureDataForPackedBy != null) {
            setSignatureDataForPackedBy(null)
        } else {
            sigCanvasForPackedBy.current.clear();
        }
    };
    const modalbody = (
        <div id="batchNameRequired" className="parent">
            <div className="row align-items-center py-2">
                <div className='col-md-6'>
                    <div className='col-12 row'>
                        <div className='col-lg-4 text-start FormLabel'>Batch Name<label className='redText'>*</label></div>
                        <div className="col-lg-8 ps-0 ">
                            <input type="text" autoComplete='off' name='partName' className="form-control partNamee" required value={createBatchFormData.batchName} onChange={(e) => setCreateBatchFormData({ ...createBatchFormData, batchName: e.target.value })} />
                            <span className="form-text invalid-feedback" >
                                *required
                            </span>
                        </div>
                    </div>
                    <div className="col-12 row align-items-center py-2">
                        <div className='col-lg-4 text-start FormLabel'>Notes<label className='redText'>*</label></div>
                        <div className="col-lg-8 ps-0 ">
                            <textarea className='form-control notesdata' name='Notes' rows={1} value={createBatchFormData.Notes} required onChange={(e) => setCreateBatchFormData({ ...createBatchFormData, Notes: e.target.value })}></textarea>
                            <span className="form-text invalid-feedback" >
                                *required
                            </span>
                        </div>
                    </div>
                    <div className="col-12 row py-2">
                        <div className='col-lg-4 text-start py-12'>Upload File</div>
                        <div className="col-lg-8 ps-0 ">
                            <input type="file" label="Pdf" name="myFile" accept=".pdf" id="UploadFileId" onChange={(e) => handlePdfFileUpload(e)} />
                            <label className="ImportInventoryBtn col-md-8" htmlFor="UploadFileId">Choose File</label>
                            <label className="ImportInventoryBtn1 col-md-4 text-center" htmlFor="UploadFileId" style={{ backgroundColor: "#3CBBA5", color: "white", height: "40px", }}>
                                Browse
                            </label>
                            {createBatchFormData.fileName && <label id="ImportInventoryStudentText" className="ps-0 mt-1" style={{ fontWeight: "600", color: '#3CBBA5', wordBreak: 'break-all' }}>{createBatchFormData.fileName}</label>}

                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="greyBox col-12">
                        <div className='fw-600 font-15 text-center'>Packed By Signature:</div>
                        <div className='mt-2 text-center'>
                            {SignatureDataForPackedBy ? (
                                <img src={SignatureDataForPackedBy} alt="Packed By Signature" />
                            ) : (
                                <SignatureCanvas
                                    ref={sigCanvasForPackedBy}
                                    canvasProps={{ width: 350, height: 100, className: "signatureCanvas" }}
                                />
                            )}
                        </div>
                        <div className="text-end"> <label className="cursor-pointer" onClick={clearSignatureForPackedBy}><u>Clear</u></label></div>
                    </div>
                </div>
            </div>

            <div>
                {schoolDetail == 2 && (
                    <div className='align-items-center pb-4 d-flex px-0'>
                        <input className='form-check-input mt-0 me-2 ms-0 cursor-pointer' type="checkbox" checked={isChecked} onChange={(e) => handlecheckboxchange(e)} />
                        Ship Devices
                    </div>
                )}
            </div>

            {BatchData.createbatchflag == 2 && (
                <>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <div className="greyBox">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div style={{ color: '#030E20', size: '18px', fontWeight: 600 }}>Shipping Method</div>
                                    <div><img src='/images/FedexIcon.svg' className='img-fluid w-100 ' /></div>
                                </div>
                                <hr />
                                <div className="pb-3">
                                    <div className="shipdevice-font-style">What device type are you shipping?</div>
                                    <div className="pt-1">
                                        <select required onChange={(e) => ChangeDeviceType(e.target.value)}>
                                            <option value="0">Select</option>
                                            <option value="3">iPad</option>
                                            <option value="1">Chromebook/Laptop</option>
                                            <option value="4">Other</option>
                                        </select>
                                        <span className="form-text invalid-feedback" >
                                            *required
                                        </span>
                                    </div>
                                </div>
                                <div className={` ${showpackingtypelist}`}>
                                    <div>
                                        <div className="shipdevice-font-style">Weight(Min:1, Max:55)*</div>
                                        <div className="input-group pt-1">
                                            <input type="number" autoComplete='off' name='weight' min={1} max={55} className="form-control CommonClass" required value={BatchData.Weight} onChange={(e) => { setBatchData({ ...BatchData, Weight: e.target.value }); }} />
                                            <span className="input-group-text">lbs</span>
                                            <span className="form-text invalid-feedback" >
                                                *required
                                            </span>
                                        </div>
                                        <span className="Emailinvalid">
                                            {WeightError}
                                        </span>
                                    </div>
                                    <div>
                                        <div className="shipdevice-font-style pt-3">Box Size(Length * Width * Height)*</div>
                                        <div className="pt-1">
                                            <select required onChange={(e) => { setBatchData({ ...BatchData, boxsize: e.target.value }); addcustominputs(e.target.value) }}>
                                                <option value="0">Select</option>
                                                {BatchData.Weight >= 1 && BatchData.Weight <= 4 ?
                                                    <>
                                                        <option value="12-8-4">12 * 8 * 4</option>
                                                        <option value="custom">Custom</option>
                                                    </>
                                                    : BatchData.Weight >= 5 && BatchData.Weight <= 10 ?
                                                        <>
                                                            <option value="12-8-4">12 * 8 * 4</option>
                                                            <option value="13-10-7">13 * 10 * 7</option>
                                                            <option value="custom">Custom</option>
                                                        </>
                                                        : BatchData.Weight >= 11 && BatchData.Weight <= 21 ?
                                                            <>
                                                                <option value="12-8-4">12 * 8 * 4</option>
                                                                <option value="13-10-7">13 * 10 * 7</option>
                                                                <option value="14-14-10">14 * 14 * 10</option>
                                                                <option value="custom">Custom</option>
                                                            </>
                                                            : BatchData.Weight >= 22 && BatchData.Weight <= 36 ?
                                                                <>
                                                                    <option value="12-8-4">12 * 8 * 4</option>
                                                                    <option value="13-10-7">13 * 10 * 7</option>
                                                                    <option value="14-14-10">14 * 14 * 10</option>
                                                                    <option value="14-12-14">14 * 12 * 14</option>
                                                                    <option value="custom">Custom</option>
                                                                </>
                                                                : <option value="custom">Custom</option>
                                                }
                                            </select>
                                            <span className="form-text invalid-feedback" >
                                                *required
                                            </span>
                                        </div>
                                    </div>
                                    <div className={`${showcustominputs} row parent`}>
                                        <div className="col-12 pt-2">
                                            <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Width*</div>
                                            <input type="number" autoComplete='off' name='width' className="form-control" required value={BatchData.width} onChange={(e) => setBatchData({ ...BatchData, width: e.target.value })} />
                                            <span className="form-text invalid-feedback" >
                                                *required
                                            </span>
                                        </div>
                                        <div className="col-12 pt-2">
                                            <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Height*</div>
                                            <input type="number" autoComplete='off' name='height' className="form-control" required value={BatchData.height} onChange={(e) => setBatchData({ ...BatchData, height: e.target.value })} />
                                            <span className="form-text invalid-feedback" >
                                                *required
                                            </span>
                                        </div>
                                        <div className="col-12 pt-2">
                                            <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Depth*</div>
                                            <input type="number" autoComplete='off' name='depth' className="form-control" required value={BatchData.depth} onChange={(e) => setBatchData({ ...BatchData, depth: e.target.value })} />
                                            <span className="form-text invalid-feedback" >
                                                *required
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="shipdevice-font-style pt-3">Label Size *</div>
                                    <div className="pt-1">
                                        <select required onChange={(e) => setBatchData({ ...BatchData, labelsize: e.target.value })}>
                                            <option value="0">Select</option>
                                            <option value="PAPER_85X11_TOP_HALF_LABEL">PAPER_85X11_TOP_HALF_LABEL</option>
                                            <option value="PAPER_4X6">PAPER_4X6</option>
                                            <option value="PAPER_4X8">PAPER_4X8</option>
                                        </select>
                                        <span className="form-text invalid-feedback" >
                                            *required
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 col-12 pt-3">
                                    <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Phone No.*</div>
                                    <input type="text" required autoComplete='off' name='phoneno' className="form-control" value={BatchData.Phoneno} onChange={(e) => setBatchData({ ...BatchData, Phoneno: e.target.value })} />
                                    <span className="form-text invalid-feedback" >
                                        *required
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-12 pt-2"><b>Recipients Name  : </b> {Recipients?.Name || '-'}</div>
                            <div className="col-12 pt-1"><b>Recipients Contact  : </b>  {Recipients?.Contact || '-'}</div>
                            <div className="col-12 pt-1"><b>Company Name  : </b>  {Recipients?.SchoolName || '-'}</div>
                            <div className='col-12 pt-3'>
                                <div className="shipdevice-font-style">Set Return Address</div>
                                <div className="pt-1">
                                    <select onChange={(e) => {
                                        const selectedBuilding = buildingData.find(
                                            (item) => item.building_id === Number(e.target.value)
                                        );

                                        setReturnAddressId(e.target.value);

                                        if (selectedBuilding && selectedBuilding?.building?.street_line_1) {
                                            setReturnAddress({
                                                ...ReturnAddress,
                                                street_line_1: selectedBuilding.building.street_line_1 || '',
                                                street_line_2: selectedBuilding.building.street_line_2 || '',
                                                city: selectedBuilding.building.city || '',
                                                state: selectedBuilding.building.state || '',
                                                postal_code: selectedBuilding.building.postal_code || '',
                                            });
                                        } else {
                                            setReturnAddress({
                                                ...ReturnAddress,
                                                street_line_1: deliveryaddress.streetline1 || '',
                                                street_line_2: deliveryaddress.streetline2 || '',
                                                city: deliveryaddress.city || '',
                                                state: deliveryaddress.stateOrProvinceCode || '',
                                                postal_code: deliveryaddress.postalCode || '',
                                            });
                                        }
                                    }}>
                                        <option value="0">Default School Address</option>
                                        {buildingData?.map((item, index) => {
                                            return <option key={index} value={item.building_id}>
                                                {item.building_name}
                                            </option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            {ReturnAddressId != 0 && (
                                <div className='col-12 pt-4 row'>
                                    <div className="font-13">Delivery Address</div>
                                    <div className="col-xl-6 col-12 pt-3">
                                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Street Line 1</div>
                                        <input type="text" autoComplete='off' name='Street1' className="form-control"
                                            value={ReturnAddress.street_line_1} onChange={(e) => setReturnAddress({ ...ReturnAddress, street_line_1: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-xl-6 col-12 pt-3">
                                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Street Line 2</div>
                                        <input type="text" autoComplete='off' name='Street2' className="form-control"
                                            value={ReturnAddress.street_line_2} onChange={(e) => setReturnAddress({ ...ReturnAddress, street_line_2: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-xl-6 col-12 pt-3">
                                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>City</div>
                                        <input type="text" autoComplete='off' name='city' className="form-control"
                                            value={ReturnAddress.city} onChange={(e) => setReturnAddress({ ...ReturnAddress, city: e.target.value })}
                                        />
                                    </div>
                                    <div className="col-xl-6 col-12 pt-3">
                                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>State</div>
                                        <select name='state' className="form-control" onChange={(e) => setReturnAddress({ ...ReturnAddress, state: e.target.value })}>
                                            <option value="0">Select</option>
                                            {AllState.map((item, i) => {
                                                return <option key={i} value={item.state_code} selected={ReturnAddress.state == item.state_code}>{item.state_code}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-xl-6 col-12 pt-3">
                                        <div className='col-12 text-start px-1 pb-1 shipdevice-font-style'>Postal Code</div>
                                        <input type="number" autoComplete='off' name='postalcode' className="form-control"
                                            value={ReturnAddress.postal_code} onChange={(e) => setReturnAddress({ ...ReturnAddress, postal_code: e.target.value })}
                                        />
                                    </div>

                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-12 pt-3 text-center">
                        <button className={`SaveBtn text-center`} onClick={(e) => createBatchSubmit(1)}> Preview Shipping</button>
                        <div className={`${ValidateColor}`}>{ValidateError}</div>
                    </div>
                </>
            )}
        </div>
    )
    return (
        <div>
            {NewAlerts}
            {loading ? <ShowLoder /> : <HideLoder />}
            <Popup isshow={props.isShow} size={"lg"} title={"Create Batch"}
                closePopup={(e) => { props.setIsModal({ ...props.IsModal, CreateBatchmodal: false }); setCreateBatchFormData({ ...createBatchFormData, batchName: '' }); setshipmentButton(true) }}
                modalBody={modalbody} handleSave={(e) => createBatchSubmit(2)} visibleSaveBtn={shipmentButton} btnText={"Create Batch"} />
        </div>
    )
}

export default AdminCreateBatch
