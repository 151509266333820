import React, { useEffect, useState } from 'react'
import Popup from '../../Components/Popup';
import { getAllBuildings, getAllUsers, isValidEmail, LogInSchoolID } from '../../JS/Common';
import { ApiGetCall, ApiGetCallAuthKey, ApiPostCall } from '../../JS/Connector';
import VideoUpload from './VideoUpload';
import { CheckValidation } from '../../Components/Validations';
import AlertsComp from '../../Components/AlertsComp';
import Cookies from 'js-cookie';

export default function QuickAddTickets(props) {
    const [MainCategories, setMainCategories] = useState([]);
    const [SubCategories, setSubCategories] = useState([]);
    const [IssueTypes, setIssueTypes] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [selectedIssue, setSelectedIssue] = useState(null);

    const [Alldata, setAlldata] = useState({
        Flag: 2,
        Buildingid: "",
        Buildingname: "",
        Roomno: "",
        RoomName: "",
        Title: "",
        Name: "",
        Email: "",
        StudentNumber: "",
        Description: "",
        Filename: "",
        Document: "",
        AssigneeId: "",
        assign_flag: 0,
    });
    const [Video, setVideo] = useState(null);
    const [Buildings, setBuildings] = useState([]);
    const [RoomData, setRoomData] = useState([]);
    const [EmailValidation, setEmailValidation] = useState("");
    const [AssigneeId, setAssigneeId] = useState([])
    const [AllGroups, setAllGroups] = useState([])
    const [isCheckedForOther, setIsCheckedForOther] = useState(false);
    const [showTicketForm, setshowTicketForm] = useState(false);
    const [BuildingValidation, setBuildingValidation] = useState("");
    const BaseUrl = process.env.REACT_APP_Base_URL;
    var ciphertext = Cookies.get('ciphertext');
    var myHeaders = new Headers();
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Authorization", ciphertext);
    useEffect(() => {
        async function fetchData() {
            try {
                const result = await ApiGetCall(`/GetHdTypes/${LogInSchoolID}&${props.type}`);
                if (!result) {
                    alert("Something went wrong");
                    return;
                }
                const response = JSON.parse(result);
                if (response[0].categories) {
                    setMainCategories(response[0]);
                }
            } catch (e) {
                console.log(e);
            }
        }
        fetchData();
        getAllBuildings(setBuildings, LogInSchoolID);
        getAllUsers(setAssigneeId);
        GetUsersandGroups();
    }, []);

    const handleCategoryChange = (event) => {
        const categoryId = parseInt(event.target.value);
        const selected = MainCategories.categories?.find(cat => cat.id === categoryId);
        setSelectedCategory(selected);
        setSubCategories(selected ? selected.subcategories : []);
        if (selected.subcategories.length == 0) {
            setshowTicketForm(true);
        }
        setIssueTypes([]); // Reset issues when category changes
        setSelectedSubCategory(null);
    };
    const handleSubCategoryChange = (event) => {
        const subCategoryId = parseInt(event.target.value);
        const selected = SubCategories.find(sub => sub.id === subCategoryId);
        setSelectedSubCategory(selected);
        setIssueTypes(selected ? selected.subcategories || [] : []);
        if (selected.subcategories.length == 0) {
            setshowTicketForm(true);
        }
    };
    const handleIssueTypes = (event) => {
        const issueId = parseInt(event.target.value);
        const selected = IssueTypes.find(issue => issue.id === issueId);
        setSelectedIssue(selected);
        setshowTicketForm(true)
    };
    async function GetUsersandGroups() {
        await ApiGetCall("/GetGroups/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.length != 0) {
                    setAllGroups(responseRs);
                }
            }
        });
    }
    function getRooms(buildingid) {
        ApiGetCallAuthKey("/getBuildingDataById/" + buildingid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setRoomData(responseRs.msg?.room)
            }
        });
    }
    function handlePdfFileUpload(e) {
        const file = e.target.files[0];
        if (file.size > 2 * 1024 * 1024) {
            alert("File size exceeds 2MB limit.");
            return;
        }
        const reader = new FileReader();
        reader.onload = function (event) {
            setAlldata({ ...Alldata, Document: file, Filename: file.name });
        };
        reader.readAsDataURL(file);
    };
    const modalBody = (
        <div className='row parent' id="quickaddform">
            <div className='col-md-4 py-2'>
                <label className='font-13 pb-2'>Choose Category : </label>
                <select className="mb-1 px-0" onChange={handleCategoryChange} disabled={showTicketForm}>
                    <option value="0">Choose Category</option>
                    {MainCategories?.categories?.map(category => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                </select>
            </div>
            {selectedCategory &&
                SubCategories?.length > 0 &&
                <div className='col-md-4 py-2'>
                    <label className='font-13 pb-2'>Choose Subcategory : </label>
                    <select className="mb-1 px-0" onChange={handleSubCategoryChange} disabled={showTicketForm}>
                        <option value="0">Choose Subcategory</option>
                        {SubCategories.map(subcategory => (
                            <option key={subcategory.id} value={subcategory.id}>{subcategory.name}</option>
                        ))}
                    </select>
                </div>
            }
            {selectedSubCategory &&
                IssueTypes?.length > 0 &&
                <div className='col-md-4 py-2'>
                    <label className='font-13 pb-2'>Choose the type of issue : </label>
                    <select className="mb-1 px-0" onChange={handleIssueTypes} disabled={showTicketForm}>
                        <option value="0">Choose issue</option>
                        {IssueTypes.map(issue => (
                            <option key={issue.id} value={issue.id}>{issue.name}</option>
                        ))}
                    </select>
                </div>
            }
            {selectedIssue && <div className='col-12 py-2'>
                <label className='font-13 pb-2'>Description :  </label>
                <span dangerouslySetInnerHTML={{ __html: selectedIssue.description || '-' }} />
            </div>
            }

            {showTicketForm &&
                <div className='row py-2'>
                    <div className='col-12 text-center'>
                        <u> <b className=''>Create your ticket below:</b></u>
                    </div>
                    <div className="col-12 text-center font-15 row pt-3">
                        <div className='d-flex align-items-center font-13'>
                            <b className='pe-5 font-12' style={{ color: "#495057" }}>Role:</b>
                            <div className="form-check mb-0">
                                <input className="form-check-input cursor-pointer" type="radio"
                                    name='role' defaultChecked={Alldata.Flag == 2}
                                    onChange={(e) => { setAlldata({ ...Alldata, Flag: 2 }) }} />
                                <label className="form-check-label"> Teacher</label>
                            </div>
                            <div className="form-check ms-3 mb-0">
                                <input className="form-check-input cursor-pointer" type="radio"
                                    name='role' defaultChecked={Alldata.Flag == 1}
                                    onChange={(e) => { setAlldata({ ...Alldata, Flag: 1 }) }} />
                                <label className="form-check-label"> Student / Parent </label>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 pb-2'>
                        <div className="align-items-center row py-2">
                            <b className="font-12" style={{ color: "#495057" }}>
                                Is there a specific building for this issue?</b>
                        </div>
                        <div className="row">
                            {Buildings.map((item, i) => {
                                return <div className="col-md-3 py-1 font-13">
                                    <input type="radio" className="form-check-input me-2"
                                        onChange={(e) => {
                                            setAlldata({
                                                ...Alldata, Buildingid: item.id, Buildingname: '',
                                                Roomno: "", RoomName: ""
                                            });
                                            setIsCheckedForOther(false);
                                            getRooms(item.id)
                                        }}
                                        name={"building"} bid={item.id}
                                    />
                                    {item.name}
                                </div>
                            })}
                            <div className="col-md-3 py-1 font-13">
                                <input type="radio" className="form-check-input me-2" name={"building"}
                                    checked={isCheckedForOther} onChange={(e) => {
                                        setAlldata({
                                            ...Alldata, Buildingid: 0, Buildingname: '',
                                            Roomno: "", RoomName: ""
                                        });
                                        setRoomData([]);
                                        setIsCheckedForOther(e.target.checked)
                                    }}
                                />
                                Other
                            </div>
                            <span style={{ fontSize: '.875em', color: '#dc3545' }}>{BuildingValidation}</span>
                        </div>
                    </div>
                    {isCheckedForOther == true && (
                        <div className='col-4 py-3'>
                            <input type="text" placeholder="Enter Building*" required autoComplete='off' name='building' className="form-control" value={Alldata.Buildingname}
                                onChange={(e) => setAlldata({ ...Alldata, Buildingname: e.target.value })} />
                            <span className="form-text invalid-feedback">
                                *required
                            </span>
                        </div>
                    )}
                    <div className='col-md-4 py-3'>
                        <div className='col-12'>
                            <input type="text" placeholder="Subject*" autoComplete='off' name='Title' className="form-control" required value={Alldata.Title} onChange={(e) => setAlldata({ ...Alldata, Title: e.target.value })} />
                            <span className="form-text invalid-feedback">
                                *required
                            </span>
                        </div>
                    </div>
                    <div className='col-md-4 py-3'>
                        <div className='col-12'>
                            <input type="text" placeholder="Name*" autoComplete='off' name='name' className="form-control" required value={Alldata.Name} onChange={(e) => setAlldata({ ...Alldata, Name: e.target.value })} />
                            <span className="form-text invalid-feedback">
                                *required
                            </span>
                        </div>
                    </div>
                    <div className='col-md-4 py-3'>
                        <div className='col-12'>
                            <input type="email" placeholder="Email*" autoComplete='off' name='email' className="form-control" value={Alldata.Email} onChange={(e) => setAlldata({ ...Alldata, Email: e.target.value })} />
                            <span style={{ fontSize: '.875em', color: '#dc3545' }}>{EmailValidation}</span>
                        </div>
                    </div>
                    {Alldata.Flag != 1 && (
                        <div className='col-md-4 py-3'>
                            <div className='col-12'>
                                <select value={Alldata.Roomno} onChange={(e) => {
                                    setAlldata({ ...Alldata, Roomno: e.target.value, RoomName: "" });
                                }}>
                                    <option value="0">Select Room</option>
                                    {RoomData?.length &&
                                        RoomData?.map((item, i) => {
                                            return <option key={i} value={item.id}>{item.name}</option>
                                        })
                                    }
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                    )}
                    {Alldata.Roomno == "other" && (
                        <div className='col-4 py-3'>
                            <input type="text" placeholder="Enter Room*" required autoComplete='off' name='building' className="form-control" value={Alldata.RoomName} onChange={(e) => setAlldata({ ...Alldata, RoomName: e.target.value })} />
                            <span className="form-text invalid-feedback">
                                *required
                            </span>
                        </div>
                    )}
                    <div className='col-md-4 py-3'>
                        <div className='col-12'>
                            {Alldata.Flag != 1 ?
                                <input type="text" placeholder="Student No." autoComplete='off' name='studentno' className="form-control" value={Alldata.StudentNumber} onChange={(e) => setAlldata({ ...Alldata, StudentNumber: e.target.value.toUpperCase() })} />
                                :
                                <>
                                    <input type="text" placeholder="Student No.*" required autoComplete='off' name='studentno' className="form-control" value={Alldata.StudentNumber} onChange={(e) => setAlldata({ ...Alldata, StudentNumber: e.target.value.toUpperCase() })} />
                                    <span className="form-text invalid-feedback">
                                        *required
                                    </span>
                                </>
                            }
                        </div>
                    </div>
                    <div className='col-md-4 py-3'>
                        <input type="file" label="img" id="UploadFileId" name="myFile" accept=".jpg,.png,.svg,.jpeg" onChange={(e) => handlePdfFileUpload(e)} />
                        <label className="supportTicketbtn col-md-12" htmlFor="UploadFileId" style={{ color: "#909091" }}>
                            Choose File (Attach Image)
                        </label>
                        {Alldata.Filename && <label className="ps-2 mt-1" style={{ fontWeight: "600", color: '#3CBBA5', wordBreak: 'break-all' }}>{Alldata.Filename} </label>}
                    </div>
                    <div className='col-md-4 py-3'>
                        <select className="mb-1 px-0" onChange={(e) => {
                            const selectedValue = e.target.value;
                            const selectedOption = e.target.options[e.target.selectedIndex];
                            const flag = selectedOption.dataset.type == 'AssigneeId' ? 2 : 1;
                            setAlldata({ ...Alldata, AssigneeId: selectedValue, assign_flag: flag });
                        }}>
                            <option value="0">Select Assigned To</option>
                            {AssigneeId.map((item1, j) => (
                                <option value={item1.id} key={j} data-type="AssigneeId">
                                    {item1.name}
                                </option>
                            ))}
                            {AllGroups.map((item2, k) => (
                                <option style={{ color: "blue" }} value={item2.id} key={k} data-type="AllGroups" >
                                    {item2.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <VideoUpload selectedFile={(e) => setVideo(e)} />
                    <div className="col-md-12 pb-3">
                        <textarea placeholder="Ticket Description" type="textarea" rows={3} autoComplete="off" name="RepairedNm" className="form-control" value={Alldata.Description} onChange={(e) => setAlldata({ ...Alldata, Description: e.target.value })} />
                    </div>
                </div>
            }
        </div>
    )
    const SaveComment = async () => {
        var isFormValid = CheckValidation({ formID: 'quickaddform' });
        var emailvalid = isValidEmail(Alldata.Email);
        if (emailvalid == false) {
            setEmailValidation("Invalid Email")
            isFormValid = false;
        } else {
            setEmailValidation("");
        }
        if (Alldata.Buildingid === '') {
            setBuildingValidation("*required");
            isFormValid = false;
        } else {
            setBuildingValidation("");
        }
        if (!isFormValid) return;
        props.setLoading(true);
        const formdata = new FormData();
        formdata.append('schoolid', LogInSchoolID);
        formdata.append('typeid', props.type);
        formdata.append('hdTypeId', MainCategories?.id);
        formdata.append('categoryid', selectedCategory?.id);
        formdata.append('subcategoryid', selectedSubCategory ? selectedSubCategory?.id : "");
        formdata.append('issueid', selectedIssue ? selectedIssue?.id : "");
        formdata.append('requester_type', Alldata.Flag);
        formdata.append('name', Alldata.Name);
        formdata.append('email', Alldata.Email);
        formdata.append('roomno', Alldata.Roomno == "other" ? 0 : Alldata.Roomno);
        formdata.append('roomname', Alldata.RoomName);
        formdata.append('studentno', Alldata.StudentNumber);
        formdata.append('document', Alldata.Document);
        formdata.append('video', Video);
        formdata.append('description', Alldata.Description);
        formdata.append('title', Alldata.Title);
        formdata.append('building', Alldata.Buildingid);
        formdata.append('buildingname', Alldata.Buildingname);
        formdata.append('assignedTo', Alldata.AssigneeId);
        formdata.append('assignedFlag', Alldata.assign_flag);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };
        fetch(`${BaseUrl}/QuickSupportTicketAdd`, requestOptions).then(response => response.text())
            .then(result => {
                props.setLoading(false);
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    props.closepopup();
                    props.setAlerts(<AlertsComp show={true} variant="success" msg="Ticket added successfully" />);
                    props.getcall();
                    setTimeout(() => {
                        props.setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                } else {
                    props.setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.msg} />);
                    setTimeout(() => {
                        props.setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                }
                props.setLoading(false);
            })
            .catch(error => console.log('error', error));
    }
    return (
        <Popup isshow={props.isShow} size={"lg"} title={props.type == 1 ? "Add Technology Helpdesk Ticket" : "Add Maintenance Helpdesk Ticket"}
            closePopup={(e) => { props.closepopup(); }}
            modalBody={modalBody} handleSave={SaveComment} visibleSaveBtn={true} btnText={"Add"} />
    )
}
