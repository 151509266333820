import React, { useContext, useEffect, useState } from "react"
import { Modal } from "react-bootstrap";
import { CheckValidation } from "../../Components/Validations";
import { CamelCaseWithSpaces, LogInSchoolID, LogInUserID, MMDDYYYY, getAllUsers, typewatch } from "../../JS/Common";
import AlertsComp from "../../Components/AlertsComp";
import { ApiGetCall, ApiGetCallAuthKey, ApiGetHeaders, ApiPostCall } from "../../JS/Connector";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faVideo } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import Pagination from "react-js-pagination";
import CustomizationPopup from "../../Components/CustomizationPopup";
import HDrequestpopup from "../../Components/HDrequestpopup";
import { useNavigate } from "react-router-dom";
import CommentPopup from "./CommentPopup";
import VideoPopup from "./VideoPopup";
import Imagepopup from "./Imagepopup";
import HelpdeskCommentPopup from "../Helpdesk/HelpdeskCommentPopup";
import { UserContext } from "../../App";
import PageHead from "../../Components/PageHead";
import Tab from "../../Components/Tab";
import AlertPopup from "../../Components/AlertPopup";
import QuickAddTickets from "./QuickAddTickets";

export function TechnologySupportTicket() {
    const navigate = useNavigate();
    const [Alerts, setAlerts] = useState("");
    const [SupportTickets, setSupportTickets] = useState([]);
    const [deletePopup, setdeletePopup] = useState(false);
    const [IsComment, setIsComment] = useState(false);
    const [IsCommentForHelpdesk, setIsCommentForHelpdesk] = useState(false);
    const [CommentData, setCommentData] = useState('');
    const [allData, setAllData] = useState({
        UpdateFlag: 1,
        Title: "",
        Description: "",
        Id: 0,
        fileName: "",
        OriginalImage: "",
        ShowUploadDiv: "",
        ShowOriginalImage: "d-none",
        Flag: 0,
        TicketId: "",
        TicketNum: '',
        Comment: "",
        AssigneeId: '',
        Documentt: null,
        Filename: "",
        StatusFlag: '',
        SortbyFlag: 'asc'
    });
    const [SupportTicketTabs, setSupportTicketTabs] = useState({
        OpenTicketTabs: "active",
        CloseTicketTabs: "",
        searchForOpen: '',
        searchForClose: '',
        PastTicketTabs: '',
        searchForPast: '',
    });
    const [AssigneeId, setAssigneeId] = useState([])
    const [AllGroups, setAllGroups] = useState([])
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [PreviewImg, SetPreviewImg] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const showPagination = SupportTickets.length > 0;
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNames, setColumnNames] = useState([]);
    const [PreviewVideo, SetPreviewVideo] = useState('')
    const [videoShow, setVideoShow] = useState(false);
    const [SortbyKey, setSortbyKey] = useState(null);
    const [PopupData, setPopupData] = useState('');
    const [isHDPopup, setisHDPopup] = useState(false);
    const [isQuickAddPopup, setisQuickAddPopup] = useState(false);
    const [ResolutionStatement, setResolutionStatement] = useState('');
    const [noteVisibility, setNoteVisibility] = useState({});
    const [TrackedTime, setTrackedTime] = useState('');
    const [helpdeskId, setHelpdeskId] = useState('');
    const [filterassignedid, setFilterassignedid] = useState(null);
    const { UserData } = useContext(UserContext);
    useEffect(() => {
        ApiGetHeaders(18, setColumnNames)
    }, []);
    useEffect(() => {
        if (SupportTicketTabs.OpenTicketTabs == 'active') {
            GetAllSupportTickets(1, SupportTicketTabs.searchForOpen ? SupportTicketTabs.searchForOpen : null, SortbyKey ? SortbyKey : null);
        } else if (SupportTicketTabs.CloseTicketTabs == 'active') {
            GetAllSupportTickets(2, SupportTicketTabs.searchForClose ? SupportTicketTabs.searchForClose : null, SortbyKey ? SortbyKey : null);
        } else {
            GetAllSupportTickets(3, SupportTicketTabs.searchForPast ? SupportTicketTabs.searchForPast : null, SortbyKey ? SortbyKey : null);
        }
        getAllUsers(setAssigneeId);
        GetUsersandGroups()
    }, [currentPage, LimitForPagination, filterassignedid])


    async function GetUsersandGroups() {
        await ApiGetCall("/GetGroups/" + LogInSchoolID).then((result) => {
            setLoading(true);
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                if (responseRs.length != 0) {
                    setAllGroups(responseRs);
                }
            }
        });
    }
    async function GetAllSupportTickets(flag, searchstring, sortkey) {
        setNoteVisibility({});
        if (!searchstring) searchstring = null;
        if (filterassignedid == 0) setFilterassignedid(null);
        if (sortkey) allData.SortbyFlag = allData.SortbyFlag == "asc" ? "desc" : "asc";
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/getAllSupportTickets/" + searchstring + "&" + LogInSchoolID + "&" + LogInUserID + "&" + flag + "&" + sortkey + "&" + allData.SortbyFlag + '&' + 1 + '&' + currentPage + '&' + LimitForPagination + '&' + filterassignedid).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setLoading(false);
                    if (responseRs.status == "success") {
                        setpageRangeCount(responseRs?.TicketData?.total)
                        setSupportTickets(responseRs?.TicketData?.data)
                    } else {
                        setSupportTickets([])
                    }
                }
            });
        }, searchstring != null ? 500 : 0);
    }
    async function ChangeStatus() {
        if (allData.StatusFlag == 1) {
            var isFormValid = CheckValidation({ formID: 'resolutionstatementdiv' });
            if (!isFormValid) return;
        }
        setLoading(true)
        var href = null;
        if (UserData.helpdeskUpdatedFlag != null) {
            href = '/changeHelpdeskTicketStatus'
            var raw = JSON.stringify({
                HdRequestId: helpdeskId,
                Flag: allData.StatusFlag,
                Resolution: ResolutionStatement,
                TrackedTime: TrackedTime
            });
        } else {
            href = '/changeSupportTicketStatus'
            var raw = JSON.stringify({
                TicketId: allData.TicketId,
                Flag: allData.StatusFlag,
                Resolution: ResolutionStatement,
                TrackedTime: TrackedTime
            });
        }
        await ApiPostCall(href, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    const alertMessage = allData.StatusFlag == 1 ? "Ticket Close Successfully." : "Ticket Open Successfully.";
                    setAlerts(<AlertsComp show={true} variant="success" msg={alertMessage} />);

                    setTimeout(() => {
                        setdeletePopup(false);
                        const tabStatus = SupportTicketTabs.OpenTicketTabs == 'active' ? 1 :
                            SupportTicketTabs.CloseTicketTabs == 'active' ? 2 : 3;
                        GetAllSupportTickets(tabStatus, null, null);
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }

                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    async function GetComment(ticketNum, ticketid) {
        setAllData({ ...allData, TicketId: ticketid, TicketNum: ticketNum, Filename: '', Description: '', Comment: '', Documentt: '' })
        setIsComment(true);
        await ApiGetCallAuthKey("/getAllCommentsById/" + ticketNum).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setLoading(false);
                if (responseRs.status == "success") {
                    setCommentData(responseRs.SupportTicketDetails);
                }
            }
        });
    }

    function GenerateUrl(TicketNum, hdId) {
        var schoolnumber = Cookies.get("Schoolnumber");
        var path
        if (hdId != null) {
            path = `/helpdesk-ticket/${schoolnumber}/${hdId}`;
        } else {
            path = `/ticket/${schoolnumber}/${TicketNum}`;
        }
        var baseurl = window.location.origin;
        var url = `${baseurl}${path}`;
        const newTab = window.open('', '_blank');
        newTab.location.href = url;
        newTab.focus();
    }
    async function UpdateLocation(supportTicketID, Assignedid, flag, hdrequestid) {
        setLoading(true);
        var href = null;
        if (UserData.helpdeskUpdatedFlag != null) {
            href = '/assignHelpdeskTicket'
            var raw = JSON.stringify({
                SchoolID: LogInSchoolID,
                StaffID: Assignedid,
                Flag: flag, //1 group 2 user
                HdRequestId: hdrequestid
            });
        } else {
            href = '/assignSupportTicketTOStaffmember'
            var raw = JSON.stringify({
                SchoolID: LogInSchoolID,
                SupportTicketID: supportTicketID,
                StaffID: Assignedid,
            });
        }
        await ApiPostCall(href, raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Assigned Successfully"} />);
                    const tabStatus = SupportTicketTabs.OpenTicketTabs == 'active' ? 1 :
                        SupportTicketTabs.CloseTicketTabs == 'active' ? 2 : 3;
                    GetAllSupportTickets(tabStatus, null, null);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 2000);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 2000);
                }
            }
        });
    }
    const handleSortClick = (item) => {
        if (item == 'StudentNum') {
            item = 'student_num'
        } else if (item == 'Subject') {
            item = 'title'
        } else {
            item = item.toLowerCase().split(' ').join('_');
        }
        if (item !== 0) {
            if (SupportTicketTabs.OpenTicketTabs == 'active') {
                GetAllSupportTickets(1, SupportTicketTabs.searchForOpen, item)
            } else if (SupportTicketTabs.CloseTicketTabs == 'active') {
                GetAllSupportTickets(2, SupportTicketTabs.searchForClose, item)
            } else {
                GetAllSupportTickets(3, SupportTicketTabs.searchForPast, item)
            }
            setSortbyKey(item)
        }
    };
    function renderContentForColumn(columnName, item) {
        const contentMap = {
            "Img": item.img != null ? (
                <img src={`${BucketS3Link}${item.img}`} ticketid={item.id} className="cursor-pointer" height='25px' onClick={(e) => { setLgShow(true); SetPreviewImg(item.img) }} />
            ) : '-',
            "Name": <div className={`${item.assign_flag == 1 && 'helpdesk-highlight-part'}`}>{item.name || '-'}</div>,
            "Email": <div className={`${item.assign_flag == 1 && 'helpdesk-highlight-part'}`}>{item.email || '-'}</div>,
            "Subject": <div className={`note-wrap ${item.assign_flag == 1 && 'helpdesk-highlight-part'}`}>{item.title || '-'}</div>,
            "building": <div className={`${item.assign_flag == 1 && 'helpdesk-highlight-part'}`}>{item.other_building_name ? item.other_building_name : item?.building?.name}</div>,
            "Description": <div className={`note-wrap ${item.assign_flag == 1 && 'helpdesk-highlight-part'}`}>{item.description || '-'}</div>,
            "videoPath": item.video_path != null ? (
                <FontAwesomeIcon icon={faVideo} style={{ height: '18px' }} title="Video Attachment" className="cursor-pointer" onClick={(e) => { setVideoShow(true); SetPreviewVideo(item.video_path) }} />
            ) : '-',
            "Room": <div className={`${item.assign_flag == 1 && 'helpdesk-highlight-part'}`}>{item.room_name ? item.room_name : item.room ? item?.room?.name : '-'}</div>,
            "Role": <div className={`${item.assign_flag == 1 && 'helpdesk-highlight-part'}`}>{item.role == 1 ? 'Student / Parent' : 'Teacher'}</div>,
            "StudentNum": <div className={`${item.assign_flag == 1 && 'helpdesk-highlight-part'}`}>{item.student_num == 'null' || item.student_num == null || item.student_num == '' ? '-' : item.student_num}</div>,
            "created_at": <div className={`${item.assign_flag == 1 && 'helpdesk-highlight-part'}`}>{MMDDYYYY(item.created_at) || '-'}</div>
        };

        return contentMap[columnName] || null;
    }

    async function GetHelpdeskDataById(reuqestid) {
        await ApiGetCallAuthKey("/GetHelpdeskById/" + LogInSchoolID + "&" + reuqestid).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                setPopupData(responseRs);
                setisHDPopup(true);
            }
        });
    }
    function handleArrowClick(ticketId) {
        setNoteVisibility((prevState) => ({
            ...prevState,
            [ticketId]: !prevState[ticketId],
        }));
    }
    function handleSearchChange(e) {
        const { value } = e.target;
        const tabStatus = SupportTicketTabs.OpenTicketTabs == 'active' ? 1 :
            SupportTicketTabs.CloseTicketTabs == 'active' ? 2 : 3;

        setCurrentPage(1);
        GetAllSupportTickets(tabStatus, value, null);

        const tabKey = SupportTicketTabs.OpenTicketTabs == 'active' ? 'searchForOpen' :
            SupportTicketTabs.CloseTicketTabs == 'active' ? 'searchForClose' : 'searchForPast';

        setSupportTicketTabs({ ...SupportTicketTabs, [tabKey]: value });
    }
    const handleTabClick = (tabType) => {
        let tabStatus = 1;
        let newTabsState = { OpenTicketTabs: "", CloseTicketTabs: "", PastTicketTabs: "" };

        if (tabType == 'open') {
            tabStatus = 1;
            newTabsState.OpenTicketTabs = "active";
        } else if (tabType == 'close') {
            tabStatus = 2;
            newTabsState.CloseTicketTabs = "active";
        } else if (tabType == 'past') {
            tabStatus = 3;
            newTabsState.PastTicketTabs = "active";
        }

        GetAllSupportTickets(tabStatus, null, null);
        setSupportTicketTabs({ ...SupportTicketTabs, ...newTabsState, searchForOpen: '', searchForClose: '', searchForPast: '' });
        setCurrentPage(1);
    };
    return (
        <React.Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={setLimitForPagination}
                setCurrentPage={setCurrentPage} heading={UserData.helpdeskUpdatedFlag != null ? 'Technology Helpdesk Tickets' : 'Technology Support Tickets'} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <div className='row my-2 d-flex justify-content-between pe-0 align-items-center'>
                <div className='col-md-12 ps-0 text-center d-md-flex justify-content-end align-items-center'>
                    {UserData.helpdeskUpdatedFlag != null ?
                        <div className="pe-3">{SupportTicketTabs.OpenTicketTabs == 'active' || SupportTicketTabs.CloseTicketTabs == 'active' ? '*Helpdesk Tickets' : '*Support Tickets'}</div>
                        :
                        <div className="pe-3">*Support Tickets</div>
                    }
                    <div className="font-14 pe-3" style={{ color: 'black', cursor: 'pointer' }} >
                        {UserData.MenuAccessFlag == 1 && (
                            UserData.helpdeskUpdatedFlag != null ?
                                <label>{SupportTicketTabs.OpenTicketTabs == 'active' || SupportTicketTabs.CloseTicketTabs == 'active' ? <u onClick={(e) => navigate("/helpdesk-ticket-assignments")}> Ticket Assignments</u> : <u onClick={(e) => navigate("/ticket-assignments")}> Ticket Assignments</u>}</label>
                                :
                                <u onClick={(e) => navigate("/ticket-assignments")}> Ticket Assignments</u>

                        )}
                    </div>
                    {UserData.helpdeskUpdatedFlag != null && (
                        <label className='BorderBtn text-center' title="Quick Add" onClick={(e) => setisQuickAddPopup(true)}> Quick Add
                            <img src='/images/AddInventory.svg' className='img-fluid ps-2' />
                        </label>
                    )}
                </div>
            </div>
            <div className="GridBox mt-2 p-3">
                <div className="row align-items-center mb-3">
                    <div className="col-md-9 px-0">
                        <ul className="nav nav-tabs px-3">
                            <Tab isActive={SupportTicketTabs.OpenTicketTabs == 'active'}
                                label="Open Tickets" onClick={() => handleTabClick('open')} col={'col-md-3'} />
                            <Tab isActive={SupportTicketTabs.CloseTicketTabs == 'active'}
                                label="Closed Tickets" onClick={() => handleTabClick('close')} col={'col-md-3'} />
                            {UserData.helpdeskUpdatedFlag != null &&
                                <Tab isActive={SupportTicketTabs.PastTicketTabs == 'active'}
                                    label="Old Tickets" onClick={() => handleTabClick('past')} col={'col-md-3'} />}
                        </ul>
                    </div>
                    <div className="col-md-3 text-end px-0">
                        <select className="mb-1 px-0" onChange={(e) => {
                            setFilterassignedid(e.target.value);
                        }}>
                            <option value="0">Filter by Assigned To</option>
                            {AssigneeId.map((item1, j) => (
                                <option value={item1.id} key={j} data-type="AssigneeId">
                                    {item1.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="greyBox helpdeskticket p-3 data-table-container">
                    <table className="table data-table mb-0">
                        <thead className='GridHeader'>
                            <tr>
                                {columnNames?.map((item) => {
                                    let columnName = item.replace(/_/g, ' ');
                                    return (
                                        item != "videoPath" && item != 'Img' && item != "Room" ?
                                            <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => handleSortClick(item)}>
                                                {CamelCaseWithSpaces(columnName)}
                                                <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                            </th>
                                            :
                                            <th key={item} scope="col" className='fw-600'>
                                                {CamelCaseWithSpaces(columnName)}
                                            </th>
                                    );
                                })}
                                {SupportTicketTabs.OpenTicketTabs == 'active' && (
                                    <th scope="col" className='cursor-pointer fw-600' title="Sort by">Assigned To </th>)}
                                {SupportTicketTabs.PastTicketTabs == 'active' && (
                                    <>
                                        <th scope="col" className='cursor-pointer fw-600' title="Sort by">Status </th>
                                        <th scope="col" className='cursor-pointer fw-600' title="Sort by">Assigned To</th>
                                    </>
                                )}
                                <th scope="col" className='cursor-pointer fw-600 '>Action<img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {SupportTickets.length != 0 ? (
                                SupportTickets.map((item, i) => {
                                    const isNoteVisible = noteVisibility[item.id];
                                    var returData = (
                                        <tr key={i} className={`OddEvenClass tableBorderHide`}>
                                            {columnNames?.map(header => (
                                                <td className={`py-12`} key={header}>  {renderContentForColumn(header, item)} </td>
                                            ))}
                                            {SupportTicketTabs.OpenTicketTabs == 'active' && (
                                                item.hd_request_id ?
                                                    <td className='col-md-3 hoverable-text' onMouseEnter={(e) => { setIsDropdownOpen(i); setAllData({ ...allData, AssigneeId: item.assigned_to }) }} onMouseLeave={() => setIsDropdownOpen(null)}>
                                                        {item.user != null ?
                                                            <>
                                                                {isDropdownOpen === i ? (
                                                                    <select className="mb-1 px-0" defaultValue={item.assigned_to} onChange={(e) => {
                                                                        const selectedValue = e.target.value;
                                                                        const selectedOption = e.target.options[e.target.selectedIndex];
                                                                        const flag = selectedOption.dataset.type == 'AssigneeId' ? 2 : 1;
                                                                        setAllData({ ...allData, AssigneeId: selectedValue });
                                                                        UpdateLocation(item.id, selectedValue, flag, item.hd_request_id);
                                                                    }}>
                                                                        <option value="0">Select</option>
                                                                        {AssigneeId.map((item1, j) => (
                                                                            <option value={item1.id} key={j} data-type="AssigneeId" defaultValue={item1.id == item.assigned_to} >
                                                                                {item1.name}
                                                                            </option>
                                                                        ))}
                                                                        {AllGroups.map((item2, k) => (
                                                                            <option style={{ color: "blue" }} value={item2.id} key={k} data-type="AllGroups" >
                                                                                {item2.name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                ) : (
                                                                    <div className={`py-2 ${item.assign_flag == 1 && 'helpdesk-highlight-part'}`}>{item?.user?.first_name + ' ' + item?.user?.last_name}</div>
                                                                )}

                                                            </>
                                                            : item.user_group != null ?
                                                                <>
                                                                    {isDropdownOpen === i ? (
                                                                        <select className="mb-1 px-0" defaultValue={item.assigned_to} onChange={(e) => {
                                                                            const selectedValue = e.target.value;
                                                                            const selectedOption = e.target.options[e.target.selectedIndex];
                                                                            const flag = selectedOption.dataset.type == 'AssigneeId' ? 2 : 1;
                                                                            setAllData({ ...allData, AssigneeId: selectedValue });
                                                                            UpdateLocation(item.id, selectedValue, flag, item.hd_request_id);
                                                                        }}>
                                                                            <option value="0">Select</option>
                                                                            {AssigneeId.map((item1, j) => (
                                                                                <option value={item1.id} key={j} data-type="AssigneeId">
                                                                                    {item1.name}
                                                                                </option>
                                                                            ))}
                                                                            {AllGroups.map((item2, k) => (
                                                                                <option style={{ color: "blue" }} value={item2.id} key={k} data-type="AllGroups" defaultValue={item2.id == item.assigned_to}>
                                                                                    {item2.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    ) : (
                                                                        <div className={`py-2 ${item.assign_flag == 1 && 'helpdesk-highlight-part'}`}>{item?.user_group?.name}</div>
                                                                    )}

                                                                </>
                                                                :
                                                                <>
                                                                    {isDropdownOpen === i ? (
                                                                        <select className="mb-1 px-0" onChange={(e) => {
                                                                            setAllData({ ...allData, AssigneeId: e.target.value });
                                                                            const selectedOption = e.target.options[e.target.selectedIndex];
                                                                            const source = selectedOption.dataset.type == 'AssigneeId' ? 2 : 1;
                                                                            UpdateLocation(item.id, e.target.value, source, item.hd_request_id);
                                                                        }}>
                                                                            <option value="0">Select</option>
                                                                            {AssigneeId.map((item1, j) => (
                                                                                <option value={item1.id} key={j} data-type="AssigneeId">
                                                                                    {item1.name}
                                                                                </option>
                                                                            ))}
                                                                            {AllGroups.map((item2, k) => (
                                                                                <option style={{ color: "blue" }} value={item2.id} key={k} data-type="AllGroups">
                                                                                    {item2.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    ) : (
                                                                        <div className="py-2">-</div>
                                                                    )}

                                                                </>
                                                        }
                                                    </td>
                                                    :
                                                    <td className=' col-md-3 hoverable-text' onMouseEnter={() => { setIsDropdownOpen(i); setAllData({ ...allData, AssigneeId: item.assigned_to }) }} onMouseLeave={() => setIsDropdownOpen(null)}>
                                                        {isDropdownOpen === i ?
                                                            <select className="mb-1 px-0" defaultValue={allData.AssigneeId} onChange={(e) => { setAllData({ ...allData, AssigneeId: e.target.value }); UpdateLocation(item.id, e.target.value, 2, item.hd_request_id); }}  >
                                                                <option value="0">Select</option>
                                                                {AssigneeId.map((item1, j) => (
                                                                    <option value={item1.id} key={j} defaultValue={item1.id === item.name} >
                                                                        {item1.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            : item.user == null ?
                                                                (<>-</>)
                                                                :
                                                                (<div className="py-2">{item.user.first_name}&nbsp;{item.user.last_name}</div>)
                                                        }
                                                    </td>
                                            )
                                            }
                                            {SupportTicketTabs.PastTicketTabs == 'active' && (
                                                <>
                                                    <td className="py-12" > <label style={{ color: item.status == 1 ? 'green' : 'red' }}> {item.status == 1 ? 'Open' : 'Closed'} </label></td>
                                                    <td className="py-12" > {item.user ? (item.user.first_name + ' ' + item.user.last_name) : ('-')} </td>
                                                </>
                                            )}
                                            <td className="d-flex">
                                                <img src="/images/previewpdficon.svg" className="cursor-pointer px-1" title={item.hd_request_id != null ? 'Preview Helpdesk Tickets' : 'Preview Support Tickets'} titleid={item.id} onClick={(e) => GenerateUrl(item.support_ticket_num, item.hd_request_id)} height='18px' />
                                                {item.hd_request_id && (
                                                    <img src="/images/InfoIcon.svg" className="cursor-pointer px-1" title="Preview Helpdesk Request" titleid={item.id} onClick={(e) => GetHelpdeskDataById(item.hd_request_id)} height='18px' />
                                                )}
                                                {item.assign_flag == 1 || UserData.MenuAccessFlag == 1 ?
                                                    SupportTicketTabs.OpenTicketTabs == 'active' ?
                                                        item.hd_request_id ?
                                                            <img src="/images/TransactionIcon.svg" className="px-1 cursor-pointer" title="Mark as closed" titleid={item.id} onClick={(e) => { setdeletePopup(true); setAllData({ ...allData, TicketId: item.id, StatusFlag: 1 }); setHelpdeskId(item.hd_request_id) }} height='18px' />
                                                            :
                                                            <img src="/images/TransactionIcon.svg" className="px-1 cursor-pointer" title="Mark as closed" titleid={item.id} onClick={(e) => { setdeletePopup(true); setAllData({ ...allData, TicketId: item.id, StatusFlag: 1 }) }} height='18px' />
                                                        : SupportTicketTabs.CloseTicketTabs == 'active' ?
                                                            item.hd_request_id ?
                                                                <img src="/images/TransactionIcon.svg" className="px-1 cursor-pointer" title="Mark as Open" titleid={item.id} onClick={(e) => { setdeletePopup(true); setAllData({ ...allData, TicketId: item.id, StatusFlag: 2 }); setHelpdeskId(item.hd_request_id) }} height='18px' />
                                                                :
                                                                <img src="/images/TransactionIcon.svg" className="px-1 cursor-pointer" title="Mark as Open" titleid={item.id} onClick={(e) => { setdeletePopup(true); setAllData({ ...allData, TicketId: item.id, StatusFlag: 2 }) }} height='18px' />
                                                            :
                                                            item.status == 1 ?
                                                                <img src="/images/TransactionIcon.svg" className="px-1 cursor-pointer" title="Mark as closed" titleid={item.id} onClick={(e) => { setdeletePopup(true); setAllData({ ...allData, TicketId: item.id, StatusFlag: 1 }); setHelpdeskId('') }} height='18px' />
                                                                :
                                                                <img src="/images/TransactionIcon.svg" className="px-1 cursor-pointer" title="Mark as Open" titleid={item.id} onClick={(e) => { setdeletePopup(true); setAllData({ ...allData, TicketId: item.id, StatusFlag: 2 }); setHelpdeskId('') }} height='18px' />
                                                    : <></>
                                                }
                                                {item.assign_flag == 1 || UserData.MenuAccessFlag == 1 ?
                                                    SupportTicketTabs.OpenTicketTabs == 'active' ? (
                                                        item.hd_request_id ? (
                                                            <FontAwesomeIcon icon={faComment} style={{ color: "black", height: "18px" }} className='cursor-pointer px-1' onClick={(e) => { setHelpdeskId(item.hd_request_id); setIsCommentForHelpdesk(true); }} title="Comment" />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faComment} style={{ color: "black", height: "18px" }} className='cursor-pointer px-1' onClick={(e) => { GetComment(item.support_ticket_num, item.id); }} title="Comment" />
                                                        )
                                                    ) : SupportTicketTabs.PastTicketTabs == 'active' ? (
                                                        item.status == 1 ?
                                                            <FontAwesomeIcon icon={faComment} style={{ color: "black", height: "18px" }} className='cursor-pointer px-1' onClick={(e) => { GetComment(item.support_ticket_num, item.id); }} title="Comment" />
                                                            : <></>
                                                    ) : (<></>)
                                                    :
                                                    <></>
                                                }
                                                {item.status == 2 && (
                                                    <>
                                                        <img src="/images/DownRoundArrow.svg" title="Show Resolution Statement" className={`cursor-pointer px-1 img-fluid ${isNoteVisible ? "d-none" : ""}`} onClick={() => handleArrowClick(item.id)} height='18px' />
                                                        <img src="/images/UpRoundArrow.svg" title="Hide Resolution Statement" className={`img-fluid px-1 cursor-pointer ${isNoteVisible ? "" : "d-none"}`} onClick={() => handleArrowClick(item.id)} height='18px' />
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                    if (isNoteVisible) {
                                        returData = (
                                            <>
                                                {returData}
                                                <tr className={`col-12  px-5`}>
                                                    <td colSpan={18}>
                                                        <b className="font-13 pe-3"> Resolution Statement:</b>
                                                        <div style={{ display: "inline" }}> {item.resolution || '-'} </div>
                                                    </td>
                                                </tr>
                                                <tr className={`col-12  px-5`}>
                                                    <td colSpan={18}>
                                                        <b className="font-13 pe-3"> Open Date:</b>
                                                        <div style={{ display: "inline" }}> {item.created_at ? MMDDYYYY(item.created_at) : '-'} </div>
                                                    </td>
                                                </tr>
                                                <tr className={`col-12  px-5`}>
                                                    <td colSpan={18}>
                                                        <b className="font-13 pe-3"> Completion Time:</b>
                                                        <div style={{ display: "inline" }}> {item.completion_time ? item.completion_time + ` hours` : '-'} </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }
                                    return returData;
                                }))
                                :
                                <tr className="text-center">
                                    <td colSpan={15}>No Record Found</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {showPagination && (
                    <Pagination
                        activePage={currentPage}
                        totalItemsCount={pageRangeCount}
                        pageRangeDisplayed={15}
                        itemsCountPerPage={LimitForPagination}
                        onChange={(e) => setCurrentPage(e)}
                        color="primary"
                        lastPageText=""
                        firstPageText=""
                        itemClassFirst="MasterClass"
                        itemClassPrev="MasterClass"
                        itemClassNext="MasterClass"
                        itemClassLast="MasterClass"
                    />
                )}
            </div >
            {IsComment &&
                <CommentPopup CommentData={CommentData} IsComment={IsComment} setIsComment={(e) => setIsComment(false)}
                    setAllData={setAllData} allData={allData} setLoading={setLoading}
                    GetAllSupportTickets={(e) => GetAllSupportTickets(SupportTicketTabs.OpenTicketTabs == 'active' ? 1 : SupportTicketTabs.CloseTicketTabs == 'active' ? 2 : 3, null, null)} setAlerts={setAlerts} />}

            {IsCommentForHelpdesk &&
                <HelpdeskCommentPopup IsComment={IsCommentForHelpdesk} setIsComment={(e) => setIsCommentForHelpdesk(false)}
                    setAllData={setAllData} allData={allData} setLoading={setLoading}
                    GetAllSupportTickets={(e) => GetAllSupportTickets(SupportTicketTabs.OpenTicketTabs == 'active' ? 1 : SupportTicketTabs.CloseTicketTabs == 'active' ? 2 : 3, null, null)} setAlerts={setAlerts} helpdeskId={helpdeskId} />}

            <AlertPopup isshow={deletePopup} img={allData.StatusFlag == 1 ? '/images/redInfo.svg' : '/images/greenInfo.svg'}
                headingtext={allData.StatusFlag == 1 ? 'Are you sure want to close ticket?' : 'Are you sure want to Open ticket?'}
                text={allData.StatusFlag == 1 && (
                    <div className="col-12 parent pt-2 px-4" id="resolutionstatementdiv">
                        <div className="col-12">
                            <textarea type="textarea" rows={2} autoComplete="off" required name="resolution" className="form-control" onChange={(e) => setResolutionStatement(e.target.value)} />
                            <div className='FormLabel'>What did you do to resolve the issue?<label className='redText'>*</label></div>
                            <span className="form-text invalid-feedback">
                                *required
                            </span>
                        </div>
                        <div className="col-12 pt-4">
                            <input type="number" className="form-control" autoComplete='off'
                                name="timetrack" min={0} max={999}
                                onChange={(e) => {
                                    let value = parseInt(e.target.value, 10);
                                    if (isNaN(value)) value = 0;
                                    value = Math.max(0, Math.min(999, value));
                                    setTrackedTime(value);
                                }}
                                required />
                            <div className='FormLabel'>How many hours did you spend to resolve this ticket?<label className='redText'>*</label></div>
                            <span className="form-text invalid-feedback">
                                *required
                            </span>
                        </div>
                    </div>
                )}
                cancelcall={(e) => { setResolutionStatement(""); setdeletePopup(false); setAllData({ ...allData, TicketId: "" }) }}
                canceltext={"Cancel"} successtext={"Yes"} successcall={(e) => ChangeStatus(e)} btntype={allData.StatusFlag == 1 ? 'deleteBtn' : 'SaveBtn'} />
            {lgShow && <Imagepopup lgShow={lgShow} setLgShow={setLgShow} PreviewImg={PreviewImg} />}
            {videoShow && <VideoPopup videoShow={videoShow} setVideoShow={setVideoShow} PreviewVideo={PreviewVideo} />}
            {CustomizationModal && <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Maintenance Support Tickets'} GetCall={() => ApiGetHeaders(18, setColumnNames)} />}
            {isHDPopup && <HDrequestpopup isShow={isHDPopup} closepopup={(e) => setisHDPopup(false)} PopupData={PopupData} />}
            {isQuickAddPopup && <QuickAddTickets isShow={isQuickAddPopup} type={1}
                closepopup={(e) => setisQuickAddPopup(false)} setLoading={setLoading}
                setAlerts={setAlerts} getcall={(e) => GetAllSupportTickets(1, null, null)} />}
        </React.Fragment>
    )
}
